<h2>{{ 'remaining-surf' | translate }}</h2>
<div class="printing-info">
  @if (allowedPrintedWindows) {
    <div class="max-printing" [matTooltip]="allowedPrintedWindows.toString()">
      @if ((currentLang$ | async) === 'ar') {
        <span
          ><strong>{{ allowedPrintedWindows | round: 2 }} m<sup>2</sup></strong> :{{ 'window' | translate }}</span
        >
      } @else {
        {{ 'window' | translate }}: <strong>{{ allowedPrintedWindows | round: 2 }} m<sup>2</sup></strong>
      }
    </div>
  }
  @if (allowedPrintedPPF) {
    <span [matTooltip]="allowedPrintedPPF.toString()"
      >{{ 'PPF' | translate }}: <strong>{{ allowedPrintedPPF | round: 2 }} m<sup>2</sup></strong></span
    >
  }
</div>
@if (currentForm) {
  <div class="btn-wrapper">
    <button color="primary" mat-raised-button type="submit" (click)="startCutting()" [disabled]="loadedPieces?.length <= 0 || printInProgress">
      {{ 'carve' | translate }}
    </button>
    @if (printInProgress) {
      <div class="spinner-wrapper">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      </div>
    }
  </div>
}
