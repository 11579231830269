<form (ngSubmit)="submitForm()" [formGroup]="userForm">
  <mat-form-field>
    <mat-label translate="full-name"></mat-label>
    <input matInput type="texte" formControlName="fullName" />
  </mat-form-field>

  <address-form [address]="{ country: user.country, address: user.address }" (addressValue)="updateAddressValue($event)"></address-form>

  <mat-form-field>
    <mat-label translate="email"></mat-label>
    <input matInput type="email" formControlName="email" />
  </mat-form-field>
  <mat-form-field>
    <mat-label translate="phone"></mat-label>
    <input matInput type="texte" formControlName="phone" updateOnBlur />
    @if (userForm.controls['phone'].hasError('required')) {
      <mat-error>
        {{ 'required' | translate }}
      </mat-error>
    }
    @if (userForm.controls['phone'].hasError('phone')) {
      <mat-error>
        {{ 'phone-validation' | translate }}
      </mat-error>
    }
  </mat-form-field>

  <mat-form-field>
    <mat-label translate="language"></mat-label>
    <mat-select formControlName="language">
      @for (language of availableLanguages; track language) {
        <mat-option [value]="language.code">
          {{ language.label }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label translate="allowedWindows"></mat-label>
    <input matInput type="texte" formControlName="allowedWindows" />
  </mat-form-field>

  <mat-form-field>
    <mat-label translate="allowedPPF"></mat-label>
    <input matInput type="texte" formControlName="allowedPPF" />
  </mat-form-field>

  <div class="btn-wrapper">
    <button color="warn" mat-raised-button type="submit" [disabled]="userForm.invalid || userForm.pristine || loading">{{ 'save' | translate }}</button>
    @if (loading) {
      <div class="spinner-wrapper">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      </div>
    }
  </div>
</form>
