import { Component, OnInit } from '@angular/core';
import { LoggingService } from 'app/app-services/logging.service';
import { LoggingList } from 'app/models/logging.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'credit-log',
  templateUrl: './credit-log.component.html',
})
export class CreditLogComponent implements OnInit {
  loggingsList$: Observable<LoggingList>;
  constructor(private readonly logginService: LoggingService) {}

  ngOnInit() {
    this.loggingsList$ = this.logginService.getLoggingList();
  }

  filterLogging(filterdInfo: { startDate?: Date; endDate?: Date }) {
    const query = {};
    Object.keys(filterdInfo)
      .filter(f => filterdInfo[f])
      .map(k => (query[k] = filterdInfo[k]));
    this.loggingsList$ = this.logginService.getLoggingList(query);
  }
}
