import { AvailableLanguagesEnum } from 'app/constants/available-languages.enum';
import { PrintingMode } from './printing.model';

export interface User {
  id?: number;
  fullName: string;
  phone: string;
  email: string;
  address: string;
  country: string;
  language: AvailableLanguagesEnum;
  serialNumber: string;
  isActive: boolean;
  allowedPPF: number;
  allowedWindows: number;
}

export interface Address {
  address: string;
  country: string;
}
export type UserInfo = Partial<Pick<User, 'address' | 'phone' | 'language' | 'id'>>;

export interface Credentials {
  payload: {
    role: string;
    id: number;
  };
  email: string;
  token: string;
}

export interface UserConfiguration {
  printer?: string;
  plotter?: string;
  filmWidth?: number;
  daemonHost?: string;
  printingMode?: PrintingMode;
}

export interface LoginData {
  email: string;
  password: string;
  rememberMe: boolean;
}

export const defaultUserConfiguration: UserConfiguration = {
  printer: null,
  plotter: 'PN',
  filmWidth: 300,
  daemonHost: 'localhost:3000',
  printingMode: PrintingMode.WEBUSB,
};

export interface UserPasswordChange {
  currentPassword: string;
  newPassword: string;
}

export interface Banner {
  id: number;
  adminId: number;
  picture: string;
}

export type SessionBanner = Pick<Banner, 'picture'>;
