<form [formGroup]="addressForm">
  <mat-form-field>
    <mat-label translate="country" />
    <mat-select formControlName="country" (selectionChange)="countrySelected($event.value)">
      @for (country of countries; track country) {
        <mat-option [value]="country"> {{ country.flag }}&nbsp;&nbsp;{{ country.name }} </mat-option>
      }
    </mat-select>
    @if (addressForm.controls['country'].hasError('required')) {
      <mat-error> required </mat-error>
    }
  </mat-form-field>

  <mat-form-field>
    <mat-label translate="region" />
    <mat-select formControlName="state" (selectionChange)="stateSelected($event.value)">
      @for (state of states; track state) {
        <mat-option [value]="state">
          {{ state.name }}
        </mat-option>
      }
    </mat-select>
    @if (addressForm.controls['state'].hasError('required')) {
      <mat-error> required </mat-error>
    }
  </mat-form-field>

  <mat-form-field>
    <mat-label translate="city" />
    <mat-select formControlName="city">
      @for (city of cities; track city) {
        <mat-option [value]="city"> {{ city.name }}</mat-option>
      }
    </mat-select>
    @if (addressForm.controls['city'].hasError('required')) {
      <mat-error> required </mat-error>
    }
  </mat-form-field>

  <mat-form-field>
    <mat-label translate="address" />
    <input matInput type="text" formControlName="address" updateOnBlur />
    @if (addressForm.controls['address'].hasError('required')) {
      <mat-error> required </mat-error>
    }
  </mat-form-field>
</form>
