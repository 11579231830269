@if (logginList) {
  <div class="table-wrapper">
    <ngx-datatable
      class="material"
      [rows]="logginList.listing"
      [footerHeight]="30"
      [columnMode]="'force'"
      [rowHeight]="'auto'"
      (page)="handlePages($event)"
      [limit]="4"
      [count]="logginList.total"
      [offset]="offset"
    >
      <ngx-datatable-column [name]="'credit-type' | translate" [sortable]="false" [draggable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row?.extra.creditType | translate }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [name]="'value' | translate" [sortable]="false" [draggable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row?.extra.value }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [name]="'event-type' | translate" [sortable]="false" [draggable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row?.eventType }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [name]="'date' | translate" [sortable]="false" [draggable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span [matTooltip]="row?.createdAt | date: 'dd/MM/yyyy, HH:mm'">{{ row?.createdAt | date: 'dd/MM, HH:mm' }}</span>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
}
@if (!logginList) {
  <div class="no-table-content">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
}
