import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { selectIsUserAuthenticated } from 'app/store/selector/session.selectors';
@Injectable()
export class AntiGuard {
  constructor(
    protected router: Router,
    private readonly store: Store,
  ) {}

  canActivate(): Observable<true | UrlTree> {
    return this.store
      .select(selectIsUserAuthenticated)
      .pipe(map((isUserAuthenticated: boolean) => (!isUserAuthenticated ? true : this.router.createUrlTree(['/']))));
  }
}
