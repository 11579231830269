import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Dictionary } from 'lodash';

import { PrintableData } from 'app/models/printable-data.model';

import * as PrintableDataActions from '../actions/printable-datal.actions';
import { GroupedEntity } from 'app/models/entities.model';

export const printableDataFeatureKey = 'printableData';

export interface State extends EntityState<PrintableData> {
  // additional entities state properties
  groupedPrintableData?: GroupedEntity<Dictionary<number[]>>;
}

export const adapter: EntityAdapter<PrintableData> = createEntityAdapter<PrintableData>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(PrintableDataActions.loadPrintableDataCompleted, (state, action): State => adapter.setAll(action.printableData, state)),
  on(PrintableDataActions.loadGroupedPrintableDataCompleted, (state, action): State => ({ ...state, groupedPrintableData: action.groupedPrintableData })),
  on(PrintableDataActions.clearPrintableData, (state): State => adapter.removeAll(state)),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
