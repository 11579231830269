/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { RulerConfig } from 'app/models/fabric.model';
import * as niceticks from 'nice-ticks';

@Component({
  selector: 'ruler-y',
  templateUrl: './ruler-y.component.html',
  styleUrls: ['./ruler-y.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RulerYComponent {
  @ViewChild('ruler_y', { static: true })
  ruler_y!: ElementRef;
  height: number;
  @Input() set config(config: RulerConfig) {
    this.drawRulerY(this.ruler_y.nativeElement, config);
  }

  drawRulerY(ruler_y: HTMLCanvasElement, config: RulerConfig) {
    if (!this.height) {
      // resizing causes the component to get bigger by 2 pixels and stacking
      // saving the height for single size
      this.height = ruler_y.parentElement.offsetHeight;
    }
    ruler_y.width = ruler_y.parentElement.offsetWidth;
    ruler_y.height = this.height;
    // models are 72dpi. meaning 72 points = 1 inch
    // 1 inch = 2.54 CM
    // multiply by this ratio to get the CM
    const vectorToCmRatio = 2.54 / 72;
    const context = ruler_y.getContext('2d');
    context!.font = '8px Arial';
    context.lineWidth = 1;
    context!.clearRect(0, 0, ruler_y.width, ruler_y.height);
    context!.beginPath();
    let ticks = [];
    const minScalar = config.viewport.minY;
    const maxScalar = config.viewport.maxY;
    const minCm = minScalar * vectorToCmRatio;
    const maxCm = maxScalar * vectorToCmRatio;
    // we need to find the corresponding pixel positions based on the viewport
    // calculating the difference allows us to use a scaling based on proportion
    // where the full width gives the max scalar position and from there calculate any position
    const deltaCm = maxCm - minCm;
    // the new ruler tryies to find corrrect nice ticks based on the magnitude of the current range
    // they are displayed with labels
    ticks = niceticks.default(minCm, maxCm, 15);
    const bigTicks = ticks.map(tick => {
      const position = ((tick - minCm) * ruler_y.height) / deltaCm;

      return { label: tick, position };
    });
    bigTicks.map(tick => this.drawTicks(context, tick.label, tick.position, config.yWidth));
    const smallticks = [];
    // then each interval between big ticks is split into 10 little ticks for better readability
    // this allows a nice ruler instead of looking for price intervals that are round.
    // zooming more should proc the big ticks again to find new values
    for (let i = 0; i < ticks.length - 1; i++) {
      smallticks.push(...niceticks.default(ticks[i], ticks[i + 1], 10));
    }
    smallticks.map(tick => this.drawTicks(context, null, ((tick - minCm) * ruler_y.height) / deltaCm, config.yWidth));
  }

  drawTicks(context: CanvasRenderingContext2D, label: string = null, positionpixel, width) {
    const isLabel = label !== null;
    context!.beginPath();
    context!.moveTo(width, positionpixel);
    context!.lineTo(isLabel ? 0 : width - width / 4, positionpixel);
    if (isLabel) {
      context!.fillText(label, 0, positionpixel + 9.5);
    }
    context!.stroke();
  }
}
