import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { PieceTypeWithId } from 'app/models/entities.model';
import { PieceType, PrintableData } from 'app/models/printable-data.model';
import { isEmpty } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Brand } from '../models/brand.model';
import { FilterFormFields } from '../models/filter-form';
import { Model } from '../models/model.model';
import { Serie } from '../models/serie.model';

@Component({
  selector: 'filter-menu-dumb',
  templateUrl: './filter-menu-dumb.component.html',
  styleUrls: ['./filter-menu-dumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterMenuDumbComponent implements OnChanges, OnInit, OnDestroy {
  @Input() pieceTypeList: PieceType[];
  @Input() brandList: Brand[];
  @Input() serieList: Serie[];
  @Input() selectedPrintableData: PrintableData;
  @Input() modelList: Model[];
  @Input() allowedPrintedWindows: number;
  @Input() allowedPrintedPPF: number;
  @Input() currentForm: FilterFormFields;

  @Output() readonly typeSelected = new EventEmitter<PieceType>();
  @Output() readonly brandSelected = new EventEmitter<PieceTypeWithId>();
  @Output() readonly serieSelected = new EventEmitter<PieceTypeWithId>();
  @Output() readonly modelSelected = new EventEmitter<PieceTypeWithId>();
  @Output() readonly filterFormSubmit = new EventEmitter<PrintableData>();
  @Output() readonly openAssistanceDialog = new EventEmitter<void>();
  @Output() readonly openModelPreviewDialog = new EventEmitter<Model>();

  brandListAutocomplete: Observable<Brand[]>;
  serieListAutocomplete: Observable<Serie[]>;
  modelListAutocomplete: Observable<Model[]>;
  pieceTypeListAutocomplete: Observable<PieceType[]>;

  filterForm: FormGroup;
  subscriptions: Subscription[] = [];
  disableBtn = true;
  pieceType: PieceType;

  ngOnChanges(changes: SimpleChanges) {
    if (!this.filterForm) {
      this.buildForm();
    }

    if (changes.currentForm) {
      isEmpty(changes.currentForm.currentValue)
        ? this.filterForm.get('pieceType').setValue('')
        : Object.keys(this.currentForm).map(m => {
            this.filterForm.get(m).setValue(this.currentForm[m]);
            this.filterForm.controls[m].enable();
          });
    }
    if (this.pieceTypeList) {
      this.pieceTypeListAutocomplete = this.filterForm.controls['pieceType'].valueChanges.pipe(
        startWith(''),
        map(pieceType => (typeof pieceType === 'string' ? this.filterPieceTypeData(pieceType) : this.pieceTypeList.slice())),
      );
    }

    if (this.brandList) {
      this.brandListAutocomplete = this.filterForm.controls['brand'].valueChanges.pipe(
        startWith(''),
        map(brand => (typeof brand === 'string' ? this.filterBrands(brand) : this.brandList.slice())),
      );
    }

    if (this.serieList) {
      this.serieListAutocomplete = this.filterForm.controls['serie'].valueChanges.pipe(
        startWith(''),
        map(serie => (typeof serie === 'string' ? this.filterSeries(serie) : this.serieList.slice())),
      );
    }

    if (this.modelList) {
      this.modelListAutocomplete = this.filterForm.controls['model'].valueChanges.pipe(
        startWith(''),
        map(model => (typeof model === 'string' ? this.filterModels(model) : this.modelList.slice())),
      );
    }

    if (changes.selectedPrintableData?.currentValue) {
      this.disableBtn = false;
      this.filterForm.get('printableData').setValue(changes.selectedPrintableData.currentValue);
    }
  }

  private buildForm() {
    this.filterForm = new FormGroup({
      pieceType: new FormControl(null, Validators.required),
      brand: new FormControl({ value: null, disabled: true }, Validators.required),
      serie: new FormControl({ value: null, disabled: true }, Validators.required),
      model: new FormControl({ value: null, disabled: true }, Validators.required),
      printableData: new FormControl({ value: null, disabled: true }, Validators.required),
    });
  }
  ngOnInit() {
    this.subscriptions.push(
      this.filterForm.controls['pieceType'].valueChanges.subscribe(pieceType => {
        if (pieceType === '') {
          this.changeFormControl('brand', false);
        }
      }),
    );

    this.subscriptions.push(
      this.filterForm.controls['brand'].valueChanges.subscribe(brand => {
        if (brand === '') {
          this.changeFormControl('serie', false);
        }
      }),
    );
    this.subscriptions.push(
      this.filterForm.controls['serie'].valueChanges.subscribe(serie => {
        if (serie === '') {
          this.changeFormControl('model', false);
        }
      }),
    );
    this.subscriptions.push(
      this.filterForm.controls['model'].valueChanges.subscribe(model => {
        if (model === '') {
          this.disableBtn = true;
        }
      }),
    );
  }

  displayFn<T extends Brand | Serie | Model>(entity: T): string {
    return entity?.name || '';
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  filterBrands(selectedBrand: string) {
    return this.brandList.filter(brand => brand.name.toLowerCase().indexOf(selectedBrand.toLowerCase()) !== -1);
  }

  filterSeries(selectedSerie: string) {
    return this.serieList.filter(serie => serie.name.toLowerCase().indexOf(selectedSerie.toLowerCase()) !== -1);
  }

  filterModels(selectedModel: string) {
    return this.modelList.filter(model => model.name.toLowerCase().indexOf(selectedModel.toLowerCase()) !== -1);
  }

  filterPieceTypeData(selectedPieceType: string) {
    return this.pieceTypeList.filter(pieceType => pieceType.toLowerCase().indexOf(selectedPieceType.toLowerCase()) !== -1);
  }

  onOpenModelPreviewDialog() {
    const model = this.modelList.find(f => f.name === this.filterForm.get('model').value?.name);
    this.openModelPreviewDialog.emit(model);
  }

  selectType(type: MatAutocompleteSelectedEvent) {
    this.changeFormControl('brand', true);
    this.pieceType = type.option.value;

    this.typeSelected.emit(type.option.value);
  }

  selectBrand(brand: MatAutocompleteSelectedEvent) {
    this.changeFormControl('serie', true);
    this.brandSelected.emit({
      id: brand.option.value.id,
      type: this.pieceType,
    });
  }

  selectSerie(serie: MatAutocompleteSelectedEvent) {
    this.changeFormControl('model', true);
    this.serieSelected.emit({
      id: serie.option.value.id,
      type: this.pieceType,
    });
  }

  selectModel(model: MatAutocompleteSelectedEvent) {
    this.modelSelected.emit({
      id: model.option.value.id,
      type: this.pieceType,
    });
  }

  changeFormControl(field: string, enable: boolean) {
    this.filterForm.controls[field].setValue('');
    if (enable) {
      this.filterForm.controls[field].enable();
      this.filterForm.controls[field].markAsUntouched();
    } else {
      this.filterForm.controls[field].disable();
    }

    if (field === 'model') {
      this.disableBtn = true;
    }
  }

  submitFilterForm() {
    this.filterFormSubmit.emit(this.filterForm.get('printableData').value);
  }

  openDialogAssistance() {
    this.openAssistanceDialog.emit();
  }
}
