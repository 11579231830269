<search-filter [type]="'serie'" (searchText)="filterSeries($event)"></search-filter>
@if (serieList) {
  <div class="table-wrapper">
    <ngx-datatable
      class="material"
      [rows]="serieList | filterBy: ['name'] : serieName"
      [footerHeight]="30"
      [headerHeight]="40"
      [columnMode]="'force'"
      [rowHeight]="'auto'"
      (page)="handlePages($event)"
      [limit]="5"
      [count]="serieList.length"
      [offset]="offset"
      [selectionType]="selectionType.single"
      [selected]="selected"
      (select)="selectRow($event)"
      [messages]="messages"
    >
      <ngx-datatable-column [name]="'series' | translate" [sortable]="false" [draggable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.name }}
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
}
