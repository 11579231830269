import { FormControl, ValidatorFn, Validators } from '@angular/forms';

function isPresent(obj: any) {
  return obj !== undefined && obj !== null;
}

export const phoneValidator: ValidatorFn = (c: FormControl): { [key: string]: boolean } => {
  if (isPresent(Validators.required(c))) {
    return null;
  }
  const phone = c;
  const phone_EXP = /((\(\+[0-9]+\))|(\+[0-9]+))?[0-9-]+[0-9]$/;

  return phone_EXP.test(phone.value) ? null : { phone: true };
};
