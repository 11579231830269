import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PwaService {
  private promptEvent: any;
  isIOS: boolean;
  canInstall$ = new BehaviorSubject<boolean>(false);

  constructor(private platform: Platform) {
    this.isIOS = this.platform.IOS;
  }

  initPwaPrompt() {
    if (!this.isIOS) {
      window.addEventListener('beforeinstallprompt', event => {
        event.preventDefault();
        this.promptEvent = event;
        this.canInstall$.next(true);
      });
    }
  }

  public installPwa() {
    if (this.promptEvent) {
      this.promptEvent.prompt();
      this.promptEvent.userChoice.then(result => {
        this.canInstall$.next(false);
        console.log(`Install prompt was: ${result.outcome}`);
        this.promptEvent = null;
      });
    }
  }
}
