import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { appConfig } from 'environments/environment';
import { ExtendedModel } from 'app/assistance-dialog/assistance-dialog.component';
import { PieceType, PrintableData } from 'app/models/printable-data.model';

@Component({
  selector: 'model-preview',
  templateUrl: './model-preview.component.html',
  styleUrls: ['./model-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelPreviewComponent implements OnChanges, OnInit {
  @Input() model: ExtendedModel;
  @Input() isAllowedWindow: boolean;
  @Input() isAllowedPPF: boolean;
  @Output() readonly typeChange = new EventEmitter<PrintableData>();

  loadingModel = false;
  loadingPPF = false;
  loadingWINDOW = false;
  pieceType = PieceType;

  url: string;

  printablePPFData: PrintableData;
  printableWindowData: PrintableData;

  ngOnChanges() {
    this.printablePPFData = this.model.printableData.find(f => f.type === PieceType.PPF);
    this.printableWindowData = this.model.printableData.find(f => f.type === PieceType.WINDOW);
    this.printablePPFData ? this.typeChange.emit(this.printablePPFData) : this.typeChange.emit(this.printableWindowData);
  }

  ngOnInit() {
    this.url = `${appConfig.apiHost}/uploads/`;
  }

  changeType(event: MatTabChangeEvent) {
    this.typeChange.emit(event.index === 0 ? this.printablePPFData : this.printableWindowData);
  }

  loading(event: Event, img: string) {
    if (event && event.type === 'load') {
      switch (img) {
        case 'model':
          this.loadingModel = true;
          break;
        case 'PPF':
          this.loadingPPF = true;
          break;
        case 'WINDOW':
          this.loadingWINDOW = true;
          break;
      }
    }
  }
}
