import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { fabricWithId } from 'app/models/fabric.model';
import { Piece } from 'app/models/printable-data.model';
import { appConfig } from 'environments/environment';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import * as fabric from 'fabric';
import { forkJoin, from, map } from 'rxjs';

@Component({
  selector: 'preview-editor',
  templateUrl: './preview-editor.component.html',
  styleUrls: ['./preview-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewEditorComponent implements OnChanges {
  @Input() loadPieces: Piece[] = [];
  @Input() initialPieces: Piece[] = [];
  @Output() readonly syncLoadedPieces = new EventEmitter<Piece[]>();
  @Output() readonly syncInitialPieces = new EventEmitter<Piece[]>();

  private canvas?: fabric.Canvas;

  constructor(private readonly el: ElementRef) {}

  ngOnChanges() {
    if (isNil(this.canvas)) {
      this.initCanvas();
    }
    this.addPieces(this.initialPieces);
  }

  private initCanvas(): void {
    this.canvas = new fabric.Canvas('fabricPreviewSurface', {
      backgroundColor: '#ebebef',
      selection: true,
      preserveObjectStacking: true,
      renderOnAddRemove: true,
      width: (<HTMLElement>this.el.nativeElement).offsetWidth,
      height: (<HTMLElement>this.el.nativeElement).offsetHeight,
    });
    this.registerEventEmitForSelection('selection:created');
    this.registerEventEmitForSelection('selection:updated');
    this.registerEventEmitForSelection('selection:cleared');
  }

  private select(labels: string[]) {
    this.canvas.discardActiveObject();

    if (isEmpty(labels)) {
      return;
    }

    const objects = this.getCanvasObject().filter(object => labels.includes(object.label));

    const selection = new fabric.ActiveSelection(objects, { canvas: this.canvas });
    this.canvas.setActiveObject(selection);

    this.canvas.requestRenderAll();
    this.SelectedObjectOpacity();
  }

  addPieces(pieces: Piece[]) {
    const url = `${appConfig.apiHost}/uploads/printable-data/`;
    forkJoin(
      pieces.map(piece => {
        return from(fabric.FabricImage.fromURL(url + piece.filepath)).pipe(
          map(image => {
            const fabricImg: fabricWithId = image.set({ left: piece.x, top: piece.y });
            fabricImg.scaleToWidth(piece.width);
            fabricImg.scaleToHeight(piece.height);
            fabricImg.label = piece.label;
            this.disableExtraControls(fabricImg);

            return fabricImg;
          }),
        );
      }),
    ).subscribe(fabricImages => {
      this.canvas.add(...fabricImages);
      this.fitCanvasToObjects();
      this.select(this.loadPieces.map(piece => piece.label));
      this.syncInitialPieces.emit(this.initialPieces);
      this.canvas.renderAll();
    });
  }

  private fitCanvasToObjects() {
    const objects = cloneDeep(this.canvas.getObjects());
    if (objects.length === 0) return;

    const group = new fabric.Group(objects);
    const groupWidth = group.width + group.left;
    const groupHeight = group.height + group.top;
    const canvasWidth = this.canvas.getWidth();
    const canvasHeight = this.canvas.getHeight();

    const scaleX = canvasWidth / groupWidth;
    const scaleY = canvasHeight / groupHeight;
    const scale = Math.min(scaleX, scaleY);

    this.canvas.setZoom(scale);
    this.canvas.setViewportTransform([scale, 0, 0, scale, 0, 0]);
  }

  private registerEventEmitForSelection(event: keyof fabric.CanvasEvents) {
    this.canvas?.on(event, () => {
      this.SelectedObjectOpacity();
      const parsedSelection = this.canvas.getActiveObject();

      if (parsedSelection) {
        this.disableExtraControls(parsedSelection);
      }

      this.syncLoadedPieces.emit(this.getSelectedPieces());
    });
  }
  SelectedObjectOpacity() {
    this.canvas.getObjects().forEach(object => object.set('opacity', 0.5));
    this.canvas.getActiveObjects().forEach(object => object.set('opacity', 1));
  }

  private getSelectedPieces(): Piece[] {
    const parsedSelection = this.canvas.getActiveObject() as fabricWithId;
    if (isNil(parsedSelection)) {
      return [];
    }

    return isEmpty(parsedSelection._objects)
      ? this.initialPieces.filter(piece => piece.label === parsedSelection.label)
      : parsedSelection._objects.map(object => this.initialPieces.find(piece => piece.label === object.label));
  }

  private getCanvasObject(): fabricWithId[] {
    return this.canvas.getObjects() as fabricWithId[];
  }
  private disableExtraControls(object: fabricWithId) {
    object.lockRotation = true;
    object.lockMovementX = true;
    object.lockMovementY = true;

    object.setControlsVisibility({ mtr: false, bl: false, br: false, tl: false, tr: false, mb: false, mr: false, ml: false, mt: false });
  }
}
