import { createSelector } from '@ngrx/store';
import { UserConfiguration } from 'app/models/user.model';

import * as fromSession from '../reducers/session.reducer';
import { selectSessionState, selectUserConfiguration } from './session.selectors';

export const selectUser = createSelector(selectSessionState, (state: fromSession.SessionState) => state.user);

export const selectAllowedPrintedWindows = createSelector(selectSessionState, (state: fromSession.SessionState) => state.user?.allowedWindows);

export const selectAllowedPrintedPPF = createSelector(selectSessionState, (state: fromSession.SessionState) => state.user?.allowedPPF);

export const selectIsAllowedPrintedPPF = createSelector(selectAllowedPrintedPPF, (allowedPPF: number) => allowedPPF > 0);

export const selectIsAllowedPrintedWindows = createSelector(selectAllowedPrintedWindows, (allowedWindows: number) => allowedWindows > 0);

export const selectFilmWidth = createSelector(selectUserConfiguration, (userConfiguration: UserConfiguration) => userConfiguration.filmWidth);

export const selectCurrentPlotter = createSelector(selectUserConfiguration, (userConfiguration: UserConfiguration) => userConfiguration.plotter);

export const selectCurrentPrinter = createSelector(selectUserConfiguration, (userConfiguration: UserConfiguration) => userConfiguration.printer);

export const selectDaemonHost = createSelector(selectUserConfiguration, (userConfiguration: UserConfiguration) => userConfiguration.daemonHost);

export const selectPrintingMode = createSelector(selectUserConfiguration, (userConfiguration: UserConfiguration) => userConfiguration.printingMode);
