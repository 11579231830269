import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, importProvidersFrom, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CeilPipe, NgPipesModule } from 'ngx-pipes';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { ServiceWorkerModule } from '@angular/service-worker';
import 'hammerjs';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';

import {
  AppInitializerService,
  AuthService,
  HandleErrorsService,
  InterceptorService,
  PrintableDataService,
  PrintingService,
  TouchTranslateService,
  UpdateSoftwareService,
  UserService,
} from './app-services';

import { AntiGuard, IsLoggedGuard } from './guards';

import { appRoutes } from './app.routes';

import { InputCeilDirective } from './app-directives/input-ceil.directive';
import { AppComponent } from './app.component';
import { AssistanceDialogComponent } from './assistance-dialog/assistance-dialog.component';
import { BrandSelectionComponent } from './brand-selection/brand-selection.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FilterMenuDumbComponent } from './filter-menu-dumb/filter-menu-dumb.component';
import { FilterMenuSmartComponent } from './filter-menu-smart/filter-menu-smart.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { LoginComponent } from './login/login.component';
import { MaxTryComponent } from './max-try/max-try.component';
import { ModelPreviewDialogComponent } from './model-preview-dialog/model-preview-dialog.component';
import { ModelPreviewComponent } from './model-preview/model-preview.component';
import { ModelSelectionComponent } from './model-selection/model-selection.component';
import { PiecesMenuComponent } from './pieces-menu/pieces-menu.component';
import { PlotterMenuComponent } from './plotter-menu/plotter-menu.component';
import { PrintableDataSelectionDialogComponent } from './printable-data-selection-dialog/printable-data-selection-dialog.component';
import { PrintableDataComponent } from './printable-data/printable-data.component';
import { PrintingLogFilterComponent } from './printing-log-filter/printing-log-filter.component';
import { PrintingLogListComponent } from './printing-log-list/printing-log-list.component';
import { PrintingLogComponent } from './printing-log/printing-log.component';
import { ProfilTabComponent } from './profil-tab/profil-tab.component';
import { RequestResetPasswordDialogComponent } from './request-reset-password-dialog/request-reset-password-dialog.component';
import { RequestResetPasswordComponent } from './request-reset-password/request-reset-password.component';
import { ResetPasswordDialogComponent } from './reset-password-dialog/reset-password-dialog.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RestartAppDialogComponent } from './restart-app-dialog/restart-app-dialog.component';
import { RulerXComponent } from './ruler-x/ruler-x.component';
import { RulerYComponent } from './ruler-y/ruler-y.component';
import { SerieSelectionComponent } from './serie-selection/serie-selection.component';
import { UserDetailsDialogComponent } from './user-details-dialog/user-details-dialog.component';
import { UserMenuDumbComponent } from './user-menu-dumb/user-menu-dumb.component';
import { UserMenuSmartComponent } from './user-menu-smart/user-menu-smart.component';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { appConfig } from 'environments/environment';

import { PlotterTabComponent } from './plotter-tab/plotter-tab.component';

import { reducers, metaReducers } from './store/reducers';
import { SessionEffects, UserEffects } from './store/effects';
import { EntityEffects } from './store/effects/entity.effects';
import { PrintingEffects } from './store/effects/printing.effects';
import { FabricCanvasComponent } from './fabric-canvas/fabric-canvas.component';
import { MatChipsModule } from '@angular/material/chips';
import { PreviewEditorComponent } from './preview-editor/preview-editor.component';
import { SwUpdaterService } from './app-services/sw-updater.service';

import { CreditLogComponent } from './credit-log/credit-log.component';
import { CreditLogFilterComponent } from './credit-log-filter/credit-log-filter.component';
import { CreditLogListComponent } from './credit-log-list/credit-log-list.component';
import { LoggingService } from './app-services/logging.service';
import { DaemonService } from './app-services/daemon.service';
import { AddressFormComponent } from './address-form/address-form.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PwaService } from './app-services/pwa.service';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginPageComponent,
    LoginFormComponent,
    UserMenuSmartComponent,
    UserMenuDumbComponent,
    PiecesMenuComponent,
    UserDetailsDialogComponent,
    ProfilTabComponent,
    PrintingLogComponent,
    PrintingLogListComponent,
    PrintingLogFilterComponent,
    ChangePasswordComponent,
    DashboardComponent,
    FilterMenuSmartComponent,
    FilterMenuDumbComponent,
    RequestResetPasswordDialogComponent,
    RequestResetPasswordComponent,
    AssistanceDialogComponent,
    BrandSelectionComponent,
    SerieSelectionComponent,
    ModelSelectionComponent,
    ModelPreviewComponent,
    PrintableDataSelectionDialogComponent,
    PlotterMenuComponent,
    PrintableDataComponent,
    MaxTryComponent,
    ResetPasswordDialogComponent,
    ResetPasswordComponent,
    RulerXComponent,
    RulerYComponent,
    RestartAppDialogComponent,
    InputCeilDirective,
    ModelPreviewDialogComponent,
    SearchFilterComponent,
    FabricCanvasComponent,
    PreviewEditorComponent,
    CreditLogComponent,
    CreditLogFilterComponent,
    CreditLogListComponent,
    AddressFormComponent,
    PlotterTabComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    HammerModule,
    BrowserModule,
    RouterModule.forRoot(appRoutes, {}),
    ReactiveFormsModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatIconModule,
    MatDialogModule,
    MatTabsModule,
    MatSelectModule,
    NgxDatatableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    NgPipesModule,
    MatAutocompleteModule,
    MatToolbarModule,
    MatMenuModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatSidenavModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictActionImmutability: true,
        strictActionSerializability: true,
        strictActionTypeUniqueness: true,
        strictActionWithinNgZone: true,
        strictStateImmutability: true,
        strictStateSerializability: true,
      },
    }),
    !appConfig.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
    EffectsModule.forRoot([SessionEffects, UserEffects, EntityEffects, PrintingEffects]),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: appConfig.production,
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (pwaService: PwaService) => () => pwaService.initPwaPrompt(),
      deps: [PwaService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitializerService: AppInitializerService) => async () => appInitializerService.load(),
      deps: [AppInitializerService],
      multi: true,
    },
    { provide: 'Window', useValue: window },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    CeilPipe,
    AuthService,
    IsLoggedGuard,
    AntiGuard,
    UserService,
    PrintingService,
    PrintableDataService,
    HandleErrorsService,
    TouchTranslateService,
    UpdateSoftwareService,
    SwUpdaterService,
    LoggingService,
    DaemonService,
    PwaService,
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(HammerModule),
  ],
})
export class AppModule {}
