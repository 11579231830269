import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import { Model } from '../models/model.model';

@Component({
  selector: 'model-selection',
  templateUrl: './model-selection.component.html',
  styleUrls: ['./model-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelSelectionComponent implements OnInit {
  @Input() modelList: Model[];
  @Input() loadingModelDetails: boolean;
  @Output() readonly modelSubmit = new EventEmitter<Model>();
  selectionType = SelectionType;

  offset = 0;
  modelName = '';
  selected = [];
  messages = {
    emptyMessage: '',
    totalMessage: '',
  };

  constructor(protected translate: TranslateService) {}

  ngOnInit() {
    this.translate.get('no-data').subscribe(res => (this.messages['emptyMessage'] = res));
    this.translate.get('total').subscribe(res => (this.messages['totalMessage'] = res));
  }

  handlePages(event) {
    this.offset = event.offset;
  }

  filterModels(modelName: string) {
    this.modelName = modelName;
  }

  selectRow(event: { selected: Model[] }) {
    if (!this.loadingModelDetails) {
      this.modelSubmit.emit(event.selected[0]);
      this.selected.length = 0;
    }
  }
}
