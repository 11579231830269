import { createAction, props } from '@ngrx/store';
import { EntitiesModel } from 'app/models/entities.model';
import { Banner, Credentials, LoginData, SessionBanner, User } from '../../models/user.model';

export const startAppInitialization = createAction('[Session init app] Start App Initialisation');
export const checkAuthentication = createAction('[Session init app] Check Authentication');
export const isDaemonUp = createAction('[Platform] Is Daemon Up', props<{ isDaemonUp: boolean }>());
export const setCredentials = createAction('[Session init app] Set Credentials', props<{ credentials: Credentials }>());
export const setCredentialsCompleted = createAction('[Session init app] Set Credentials Completed', props<{ user: User }>());
export const getBanner = createAction('[Session init app] Get Banner');
export const getBannerCompleted = createAction('[Session init app] Get Banner Completed', props<{ banner: Banner }>());
export const setBanner = createAction('[Session init app] Set Banner', props<{ banner: SessionBanner }>());
export const isUserAuthenticated = createAction('[Session] Is User Authenticated');
export const finishedAppInitialization = createAction('[Session init app] Finished App Initialization');

export const login = createAction('[Login page] Login', props<{ loginData: LoginData }>());
export const loginCompleted = createAction('[Login Api] Login Completed', props<{ credentials: Credentials }>());
export const loginError = createAction('[Login Api] Login Error', props<{ error }>());

export const logout = createAction('[Session] Logout');
export const logoutCompleted = createAction('[Session] Logout Completed');
export const logoutError = createAction('[Login Api] Logout Error', props<{ error }>());
export const resetState = createAction('[Session] Reset State');

export const loadEntities = createAction('[Session] Load Entities');
export const loadEntitiesCompleted = createAction('[Session] Load Entities Completed', props<{ entities: EntitiesModel }>());
export const loadEntitiesError = createAction('[Session] Load entities Error', props<{ error }>());

export const setIsTabletMode = createAction('[Session] Set Is Tablet Mode', props<{ isTabletMode: boolean }>());
export const setIsSidenavOpen = createAction('[Session] Set Is Sidenav Open', props<{ isSidenavOpen: boolean }>());
