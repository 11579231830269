<h2>{{ 'trac-list' | translate }}</h2>
<mat-list>
  @for (piece of stagedPieces; track piece) {
    <mat-list-item (click)="onLoadPrintableData(piece)">
      <button mat-icon-button>
        <mat-icon svgIcon="ic_chevron_left_24px"></mat-icon>
      </button>
      <div class="list-item-img">
        <img [src]="url + piece.filepath" />
      </div>
      {{ piece.label }}
    </mat-list-item>
  }
</mat-list>
<div class="btns">
  <button color="accent" [disabled]="!stagedPieces?.length" mat-raised-button (click)="onLoadPrintableData()">
    {{ 'load-all' | translate }}
  </button>
  <button color="primary" mat-raised-button [disabled]="!stagedPieces?.length" (click)="openPrintableDataDialog()">{{ 'overview' | translate }}</button>
</div>
