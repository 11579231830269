<h2 mat-dialog-title>{{ 'reset-password-title' | translate }}</h2>
<mat-dialog-content>
  <reset-password (formSubmit)="getFormInfo($event)" #resetForm></reset-password>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="accent" type="submit" [disabled]="resetForm.resetPasswordForm.invalid" (click)="resetPassword()">
    {{ 'reset' | translate }}
  </button>
  <button mat-raised-button color="warn" (click)="dialogRef.close()">{{ 'cancel' | translate }}</button>
</mat-dialog-actions>
