@if (currentPrintingMode === 'DAEMON') {
  <button mat-icon-button class="daemon-status-icon" [matTooltip]="isDaemonUp && currentPrinter ? currentPrinter : ('daemon-disconnected' | translate)">
    <mat-icon class="user-menu-icon" [svgIcon]="isDaemonUp && currentPrinter ? 'ic_signal_wifi_statusbar_4_24px' : 'ic_signal_wifi_off_24px'"></mat-icon>
  </button>
}
@if (currentPrintingMode === 'WEBUSB') {
  <button mat-icon-button class="daemon-status-icon" [matTooltip]="currentPrinter ? currentPrinter : ('daemon-disconnected' | translate)">
    <mat-icon class="user-menu-icon" [svgIcon]="currentPrinter ? 'ic_print_24px' : 'ic_signal_wifi_off_24px'"></mat-icon>
  </button>
}
<button mat-icon-button (click)="openProfileDialog()" [matTooltip]="'profile' | translate">
  <mat-icon svgIcon="ic_settings_24px"></mat-icon>
</button>

<button mat-icon-button (click)="navigate()" [matTooltip]="'tutoriel' | translate">
  <mat-icon svgIcon="ic_help_24px"></mat-icon>
</button>

@if (!loadingLogout) {
  <button mat-icon-button (click)="logout()" [matTooltip]="'logout' | translate">
    <mat-icon svgIcon="ic_power_settings_new_24px"></mat-icon>
  </button>
} @else {
  <button mat-icon-button>
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </button>
}
