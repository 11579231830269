import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { isNil } from 'lodash';
import { filter, map, switchMap } from 'rxjs/operators';

import { BrandApiActions } from '../actions/brand.actions';
import { ModelApiActions } from '../actions/model.actions';
import * as PrintableDataActions from '../actions/printable-datal.actions';

import { PrintableDataService } from 'app/app-services';
import { Piece, PrintableData } from 'app/models/printable-data.model';
import { SerieApiActions } from '../actions/serie.actions';
import { loadEntitiesCompleted } from '../actions/session.actions';

@Injectable()
export class EntityEffects {
  loadBrandEntitiesCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadEntitiesCompleted),
      map(({ entities }) => BrandApiActions.loadBrandsCompleted({ brands: entities.brands })),
    );
  });

  loadGroupedBrandEntitiesCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadEntitiesCompleted),
      map(({ entities }) =>
        BrandApiActions.loadGroupedBrandsCompleted({
          groupedBrands: entities.groupedBrands,
        }),
      ),
    );
  });

  loadSerieEntitiesCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadEntitiesCompleted),
      map(({ entities }) => SerieApiActions.loadSeriesCompleted({ series: entities.series })),
    );
  });

  loadGroupedSeriesCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadEntitiesCompleted),
      map(action => SerieApiActions.loadGroupedSeriesCompleted({ groupedSeries: action.entities.groupedSeries })),
    );
  });

  loadModelEntitiesCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadEntitiesCompleted),
      map(({ entities }) => ModelApiActions.loadModelsCompleted({ models: entities.models })),
    );
  });

  loadGroupdModelEntitiesCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadEntitiesCompleted),
      map(({ entities }) =>
        ModelApiActions.loadGroupedModelsCompleted({
          groupedModels: entities.groupedModels,
        }),
      ),
    );
  });

  loadPrintableDataEntitiesCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadEntitiesCompleted),
      map(({ entities }) =>
        PrintableDataActions.loadPrintableDataCompleted({
          printableData: entities.printableData,
        }),
      ),
    );
  });

  loadGroupedPrintableDataEntitiesCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadEntitiesCompleted),
      map(({ entities }) =>
        PrintableDataActions.loadGroupedPrintableDataCompleted({
          groupedPrintableData: entities.groupedPrintableData,
        }),
      ),
    );
  });

  setSelectedPrintableData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PrintableDataActions.setSelectedPrintableData),
      filter(action => !isNil(action.selectedPrintableDataId)),
      map(action => action.selectedPrintableDataId),
      switchMap(id =>
        this.pritableDataService.loadPrintableData(id).pipe(
          map((printableData: PrintableData) => printableData.pieces.filter((piece: Piece) => piece.label.indexOf('noname') === -1)),
          map((initialPieces: Piece[]) => PrintableDataActions.setInitialPieces({ initialPieces })),
        ),
      ),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly pritableDataService: PrintableDataService,
  ) {}
}
