import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Brand } from '../models/brand.model';

import { appConfig } from 'environments/environment';

@Component({
  selector: 'brand-selection',
  templateUrl: './brand-selection.component.html',
  styleUrls: ['./brand-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandSelectionComponent implements OnInit {
  @Input() brandList: Brand[];
  @Input() loadingSeries: boolean;
  @Output() readonly brandSubmit = new EventEmitter<number>();
  brandName = '';
  url: string;

  ngOnInit() {
    this.url = `${appConfig.apiHost}/uploads/`;
  }

  submitBrand(brand: Brand) {
    if (!this.loadingSeries) {
      this.brandSubmit.emit(brand.id);
    }
  }

  filterBrands(brandName: string) {
    this.brandName = brandName;
  }
}
