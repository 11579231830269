import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { MatSnackBar } from '@angular/material/snack-bar';

import { HandleErrorsService } from './handle-errors.service';

@Injectable()
export class UpdateSoftwareService {
  updateFinished = new Subject<string>();

  constructor(
    protected _http: HttpClient,
    protected handleErrorsService: HandleErrorsService,
    protected snackbar: MatSnackBar,
    protected translateService: TranslateService,
  ) {}

  getVersion(): Observable<{ statusCode: number; data: string }> {
    return this._http.get('/api/version', { observe: 'response' }).pipe(
      map((res: HttpResponse<{ statusCode: number; data: string }>) => {
        return res.body;
      }),
    );
  }

  getPackage(version: string) {
    const url = `/api/package/${version}`;

    return this._http.get(url, { observe: 'response', responseType: 'arraybuffer' }).pipe(
      map(r => r.body),
      tap(
        () => {
          this.updateFinished.next('finished');
          /*
					const buffer = Buffer.from(res.data);
					const newPath = path.dirname(process.execPath);

					const writer = fs.createWriteStream(newPath + '/package.nw');
					const decipher = crypto.createDecipher('aes-256-ctr', '2MVxz.xQ~d3Gg{C$');
					const dec = Buffer.concat([decipher.update(buffer), decipher.final()]);

					writer.end(dec);

					writer.on('error', e => {
						this.translateService.get('downloading-error').subscribe(m => {
							this.snackbar.open(m + e, 'OK', { duration: 7000 });
						});
					});

					writer.on('finish', () => this.updateFinished.next('finished'));
					*/
        },
        err => this.handleErrorsService.handleErrors(err),
      ),
    );
  }
}
