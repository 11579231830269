import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { AvailableLanguagesEnum } from '../constants/available-languages.enum';

@Injectable()
export class TouchTranslateService {
  constructor(private translate: TranslateService) {}

  initLanguage(): Observable<any> {
    const defaultLang = (this.translate.getBrowserLang() as AvailableLanguagesEnum) || AvailableLanguagesEnum.EN;
    this.translate.setDefaultLang(defaultLang);

    return this.changeUserLanguage(defaultLang);
  }

  updateUserLanguage(language: AvailableLanguagesEnum) {
    this.changeUserLanguage(language);
  }

  changeUserLanguage(localeId: AvailableLanguagesEnum) {
    return this.translate.use(localeId).pipe(
      switchMap(() => from(this.getLocale()[localeId]())),
      tap(module => {
        registerLocaleData(module.default);
      }),
    );
  }

  private getLocale() {
    return {
      [AvailableLanguagesEnum.FR]: () => import('@angular/common/locales/fr'),
      [AvailableLanguagesEnum.AR]: () => import('@angular/common/locales/ar'),
      [AvailableLanguagesEnum.ES]: () => import('@angular/common/locales/es'),
      [AvailableLanguagesEnum.EN]: () => import('@angular/common/locales/en'),
    };
  }
}
