import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService, HandleErrorsService } from '../app-services';

@Component({
  selector: 'request-reset-password-dialog',
  templateUrl: './request-reset-password-dialog.component.html',
  styleUrls: ['./request-reset-password-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestResetPasswordDialogComponent {
  constructor(
    private authService: AuthService,
    protected snackBar: MatSnackBar,
    protected dialog: MatDialog,
    public dialogRef: MatDialogRef<RequestResetPasswordDialogComponent>,
    protected handleErrorsService: HandleErrorsService,
  ) {}

  sendRequest(email: string) {
    const value = { email: email, role: 'User' };
    this.authService.requestPasswordReset(value).subscribe(
      () => {
        this.snackBar.open('Un e-mail vous est envoyé, veuillez le consulter', 'Ok', { duration: 7000 });
        this.dialogRef.close('ResetPassword');
      },
      err => this.handleErrorsService.handleErrors(err),
    );
  }
}
