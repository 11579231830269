import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { WindowRefService } from 'app/app-services/window-ref.service';
import { logout } from 'app/store/actions/session.actions';
import { SessionLoading } from 'app/store/reducers/session.reducer';
import { selectIsDaemonUp, selectLoading } from 'app/store/selector/session.selectors';
import { UserDetailsDialogComponent } from '../user-details-dialog/user-details-dialog.component';
import { PrintingMode } from 'app/models/printing.model';
import { selectCurrentPrinter, selectPrintingMode } from 'app/store/selector/user.selectors';

@Component({
  selector: 'user-menu-smart',
  templateUrl: './user-menu-smart.component.html',
  styleUrls: ['./user-menu-smart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuSmartComponent implements OnInit {
  loading$: Observable<boolean>;
  isDaemonUp$: Observable<boolean>;
  currentPrintingMode$: Observable<PrintingMode>;
  currentPrinter$: Observable<string>;

  constructor(
    private readonly dialog: MatDialog,
    private readonly store: Store,
    private readonly windowRefService: WindowRefService,
  ) {}

  ngOnInit() {
    this.loading$ = this.store.select(selectLoading({ loadingType: SessionLoading.logout }));
    this.isDaemonUp$ = this.store.select(selectIsDaemonUp);
    this.currentPrintingMode$ = this.store.select(selectPrintingMode);
    this.currentPrinter$ = this.store.select(selectCurrentPrinter);
  }

  logout() {
    this.store.dispatch(logout());
  }

  OpenProfileDialog() {
    const config: MatDialogConfig = { width: '65%', height: '575px' };
    this.dialog.open(UserDetailsDialogComponent, config);
  }

  onOpenTutorial() {
    this.windowRefService.openUrl('https://www.youtube.com/channel/UCjoy-WKTEN0lf6TmMVZzWoA/videos');
  }
}
