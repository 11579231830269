import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService, HandleErrorsService } from '../app-services';

@Component({
  selector: 'reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordDialogComponent {
  formInfo: { newPassword: string; hash: string } = null;

  constructor(
    protected snackBar: MatSnackBar,
    protected authService: AuthService,
    public dialogRef: MatDialogRef<ResetPasswordDialogComponent>,
    protected handleErrors: HandleErrorsService,
  ) {}

  getFormInfo(form: { newPassword: string; hash: string }) {
    this.formInfo = form;
  }

  resetPassword() {
    this.authService.resetPassword(this.formInfo).subscribe(
      () => {
        this.snackBar.open('Mot de passe réinitialisé aves succés', 'Ok', { duration: 7000 });
        this.dialogRef.close();
      },
      err => this.handleErrors.handleErrors(err),
    );
  }
}
