import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, of as observableOf, ReplaySubject, Subscription, of, switchMap, debounceTime, distinctUntilChanged } from 'rxjs';
import { Model } from '../models/model.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'printing-log-filter',
  templateUrl: './printing-log-filter.component.html',
  styleUrls: ['./printing-log-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintingLogFilterComponent implements OnChanges, OnInit, OnDestroy {
  @Input() models: Model[];
  @Output() readonly queryModels = new EventEmitter<{ modelName: string; exclude: number[] }>();
  @Output() readonly filterPrintings = new EventEmitter();
  printingFilterForm: FormGroup;

  modelListSubject = new ReplaySubject<Model[]>(1);
  eventListner: Subscription[] = [];

  maxFirstDate = new Date();
  minLastDate: Date = null;
  maxLastDate = new Date();

  filteredModels: Observable<Model[]>;

  @ViewChild('modelInput') modelInput: ElementRef<HTMLInputElement>;

  ngOnInit() {
    this.buildForm();

    this.eventListner.push(this.printingFilterForm.controls['startDate'].valueChanges.subscribe(fDate => (this.minLastDate = fDate)));
    this.eventListner.push(this.printingFilterForm.controls['endDate'].valueChanges.subscribe(lDate => (this.maxFirstDate = lDate)));
    this.filteredModels = this.printingFilterForm.get('modelSearch').valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(model => (model?.length > 2 ? this.requestModelAutoCompleteItems(model) : of([]))),
    );
  }
  private buildForm() {
    this.printingFilterForm = new FormGroup({
      startDate: new FormControl(null),
      endDate: new FormControl(null),
      models: new FormControl(null),
      modelSearch: new FormControl(null),
    });
  }

  ngOnChanges() {
    this.modelListSubject.next(this.models);
  }

  public requestModelAutoCompleteItems = (modelName: string): Observable<Model[]> => {
    this.getModels(modelName);

    return this.models ? this.modelListSubject : observableOf([]);
  };

  getModels(modelName: string) {
    const selectedModels: Model[] = this.printingFilterForm.get('models').value || [];

    this.queryModels.emit({
      modelName: modelName !== '' ? modelName : null,
      exclude: selectedModels.length ? selectedModels.map(m => m.id) : null,
    });
  }

  search() {
    const selectedModels: Model[] = this.printingFilterForm.controls['models'].value ?? [];
    const startDate = this.printingFilterForm.controls['startDate'].value;
    const endDate = this.printingFilterForm.controls['endDate'].value;

    this.filterPrintings.emit({
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null,
      modelIds: selectedModels.length ? selectedModels.map(model => model.id).join(',') : null,
    });
  }

  remove(model: Model): void {
    const models: Model[] = this.printingFilterForm.get('models').value;
    this.printingFilterForm.get('models').setValue(models.filter(item => item.id !== model.id));
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const value: Model[] = this.printingFilterForm.get('models').value ?? [];
    this.printingFilterForm.get('models').setValue([...value, event.option.value]);

    this.modelInput.nativeElement.value = '';
    this.printingFilterForm.get('modelSearch').setValue(null);
  }

  ngOnDestroy() {
    this.eventListner.forEach(res => res.unsubscribe());
  }
}
