import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { appConfig } from 'environments/environment';
import { Piece } from '../models/printable-data.model';

@Component({
  selector: 'printable-data',
  templateUrl: './printable-data.component.html',
  styleUrls: ['./printable-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintableDataComponent implements OnInit {
  @Input() stagedPieces: Piece[];
  @Output() readonly loadPrintableData = new EventEmitter<Piece[]>();
  @Output() readonly openPrintableDialog = new EventEmitter<void>();

  url: string;

  ngOnInit() {
    this.url = `${appConfig.apiHost}/uploads/printable-data/`;
  }

  onLoadPrintableData(piece?: Piece) {
    this.loadPrintableData.emit(piece ? [piece] : this.stagedPieces);
  }

  openPrintableDataDialog() {
    this.openPrintableDialog.emit();
  }
}
