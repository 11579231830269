import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PwaService } from 'app/app-services/pwa.service';
import { LoginData } from 'app/models/user.model';

@Component({
  selector: 'login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageComponent {
  @Output() readonly userDataSubmit = new EventEmitter<LoginData>();
  @Output() readonly RequestResetPasswordNavigation = new EventEmitter<void>();
  @Input() loading: boolean;
  @Input() version: string;
  @Input() loginDataFromStorage: LoginData;

  contactList = [{ logo: 'dz.png', country: 'algeria', phone: '+213 772 31 82 46', email: 'dz.sales@magic-cut.in' }];

  constructor(protected pwaService: PwaService) {}
  submitUserData(userData: LoginData) {
    this.userDataSubmit.emit(userData);
  }

  NavigateToRequestResetPassword() {
    this.RequestResetPasswordNavigation.emit();
  }

  installPwa() {
    this.pwaService.installPwa();
  }
}
