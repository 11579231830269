import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { FabricJSON } from 'app/models/fabric.model';
import { from, of } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { NotificationService, PrintingService } from '../../app-services';
import * as PrintingActions from '../actions/printing.actions';
import { selectFabricQuery, selectIsDaemonUp } from '../selector/session.selectors';
import { isRuler } from '../../utils/fabric.utils';
import { selectCurrentPrinter, selectDaemonHost, selectPrintingMode } from '../selector/user.selectors';
import { NotificationType } from 'app/models/notification-types.const';
import { refreshUserData, updateUserConfiguration } from '../actions/user.actions';
import { PrinterDetails, PrintFailQuery, PrintingMode } from 'app/models/printing.model';
import { loginCompleted, setCredentialsCompleted } from '../actions/session.actions';
import { isEmpty } from 'lodash';

@Injectable()
export class PrintingEffects {
  startPrinting$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PrintingActions.startPrinting),
      concatLatestFrom(() => [
        this.store.select(selectFabricQuery).pipe(
          map(fabricQuery => {
            const state: FabricJSON = JSON.parse(fabricQuery.fabricState);
            state.objects = state.objects.filter(obj => !isRuler(obj));
            fabricQuery.fabricState = JSON.stringify(state);

            return fabricQuery;
          }),
        ),
        this.store.select(selectPrintingMode),
      ]),

      switchMap(([, { userConfiguration, ...query }, printingMode]) => {
        return (
          printingMode === PrintingMode.WEBUSB
            ? this.printingService.printDataWithWebUSB({ ...query, printer: userConfiguration.printer })
            : this.printingService.printDataWithDaemon({ ...query, printer: userConfiguration.printer })
        ).pipe(
          map(() => PrintingActions.printHpglCompleted()),
          catchError((error: PrintFailQuery) => of(PrintingActions.printError({ error }))),
        );
      }),
    );
  });

  testPrinting$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PrintingActions.testPrinting),
      concatLatestFrom(() => [this.store.select(selectCurrentPrinter), this.store.select(selectPrintingMode), this.store.select(selectDaemonHost)]),
      switchMap(([action, currentPrinter, printingMode, daemonHost]) => {
        return (
          printingMode === PrintingMode.WEBUSB
            ? this.printingService.testPrintingWithWebUsb(action.hpgl, currentPrinter)
            : this.printingService.testPrintingWithDaemon(action.hpgl, daemonHost, currentPrinter)
        ).pipe(
          map(() => PrintingActions.printHpglCompleted()),
          catchError((error: PrintFailQuery) => of(PrintingActions.printError({ error }))),
        );
      }),
    );
  });

  addDevicePermission$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PrintingActions.AddDevicePermission),
      switchMap(() => this.printingService.addDevicePermission()),
      map((printers: PrinterDetails[]) => PrintingActions.loadPrinters({ printers })),
      catchError(() => of(PrintingActions.loadPrinters({ printers: [] }))),
    );
  });

  printFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PrintingActions.printError),
        switchMap(errorAction =>
          this.printingService.postPrintFail(errorAction.error).pipe(
            finalize(() => {
              this.notificationService.notify(NotificationType.PRINT_FAILED, 5000);
            }),
          ),
        ),
      );
    },
    { dispatch: false },
  );

  printHpglCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PrintingActions.printHpglCompleted),
      tap(() => {
        this.notificationService.notify(NotificationType.PRINT_COMPLETED, 5000);
      }),
      map(() => refreshUserData()),
    );
  });

  initPrintingMode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setCredentialsCompleted, loginCompleted),
      concatLatestFrom(() => this.store.select(selectPrintingMode)),
      switchMap(([, printingMode]) => this.loadPrintersBasedOnMode(printingMode)),
    );
  });

  updatePrintinMode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PrintingActions.updatePrintingMode),
      switchMap(printingModeAction => this.loadPrintersBasedOnMode(printingModeAction.printingMode)),
    );
  });

  checkCurrentPrinter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PrintingActions.loadPrinters),
      concatLatestFrom(() => [this.store.select(selectCurrentPrinter), this.store.select(selectPrintingMode)]),
      switchMap(([printersAction, currentPrinter, printingMode]) =>
        from(this.printingService.checkPrinterClaimStatus(currentPrinter)).pipe(
          map(isPrinterClaimed => {
            const isPrinterConnected = !isEmpty(currentPrinter) && !printersAction.printers.map(printer => printer.name).includes(currentPrinter);

            return { isPrinterConnected, printingMode, isPrinterClaimed };
          }),
        ),
      ),
      filter(({ isPrinterConnected, printingMode, isPrinterClaimed }) =>
        printingMode === PrintingMode.WEBUSB ? isPrinterConnected || !isPrinterClaimed : isPrinterConnected,
      ),
      map(() => updateUserConfiguration({ partialUserConfiguration: { printer: undefined } })),
    );
  });

  private loadPrintersBasedOnMode(printingMode: PrintingMode) {
    if (printingMode === PrintingMode.WEBUSB) {
      this.printingService.onDisconnectDeviceWebUSB();

      return from(this.printingService?.getConnectedDevices()).pipe(
        map((printers: PrinterDetails[]) => PrintingActions.loadPrinters({ printers })),
        catchError(() => of(PrintingActions.loadPrinters({ printers: [] }))),
      );
    }

    if (printingMode === PrintingMode.DAEMON) {
      return this.store.select(selectIsDaemonUp).pipe(
        switchMap(isUp => {
          if (!isUp) {
            return of(PrintingActions.loadPrinters({ printers: [] }));
          }

          return this.printingService.getAvailablePrintersWithDaemon().pipe(
            map(printers => PrintingActions.loadPrinters({ printers })),
            catchError(() => of(PrintingActions.loadPrinters({ printers: [] }))),
          );
        }),
      );
    }

    return of(PrintingActions.loadPrinters({ printers: [] }));
  }

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly printingService: PrintingService,
    private readonly notificationService: NotificationService,
  ) {}
}
