<form [formGroup]="resetPasswordForm">
  <mat-form-field>
    <mat-label>Hash</mat-label>
    <input matInput type="text" formControlName="hash" updateOnBlur />
    @if (resetPasswordForm.controls['hash'].hasError('required')) {
      <mat-error>
        {{ 'required' | translate }}
      </mat-error>
    }
  </mat-form-field>

  <mat-form-field>
    <mat-label translate="new-password"></mat-label>
    <input matInput type="password" formControlName="newPassword" updateOnBlur />
    @if (resetPasswordForm.controls['newPassword'].hasError('required')) {
      <mat-error>
        {{ 'required' | translate }}
      </mat-error>
    }
  </mat-form-field>

  <mat-form-field>
    <mat-label translate="confirm-password"></mat-label>
    <input matInput type="password" formControlName="confirmPassword" updateOnBlur />
    @if (resetPasswordForm.controls['confirmPassword'].hasError('required')) {
      <mat-error>
        {{ 'required' | translate }}
      </mat-error>
    }
  </mat-form-field>
  @if (!resetPasswordForm.controls['confirmPassword'].hasError('required') && resetPasswordForm.hasError('mismatch')) {
    <mat-error class="mismatch-error">
      {{ 'mismatch' | translate }}
    </mat-error>
  }
</form>
