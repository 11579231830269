import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AvailableLanguages } from 'app/constants/available-languages.const';
import { Address, User, UserInfo } from '../models/user.model';
import { phoneValidator } from '../validators/phone.validator';

@Component({
  selector: 'profil-tab',
  templateUrl: './profil-tab.component.html',
  styleUrls: ['./profil-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilTabComponent implements OnChanges, OnInit {
  @Input() user: User;
  @Input() loading: boolean;
  @Output() readonly formSubmit = new EventEmitter<UserInfo>();

  userForm: FormGroup;

  availableLanguages = AvailableLanguages;

  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.user && !changes.user.isFirstChange()) {
      this.userForm.reset(this.user);
    }
  }

  ngOnInit() {
    this.buildForm();
  }

  private buildForm() {
    this.userForm = this.formBuilder.group({
      fullName: [{ value: this.user.fullName, disabled: true }],
      address: [this.user.address, Validators.required],
      country: [this.user.country, Validators.required],
      phone: [this.user.phone, [Validators.required, phoneValidator]],
      email: [{ value: this.user.email, disabled: true }],
      language: [this.user.language, Validators.required],
      allowedPPF: [{ value: this.user.allowedPPF, disabled: true }],
      allowedWindows: [{ value: this.user.allowedWindows, disabled: true }],
    });
  }

  submitForm() {
    this.formSubmit.emit(this.userForm.value);
  }

  updateAddressValue(value: Address) {
    this.userForm.patchValue(value);
    this.userForm.markAsDirty();
  }
}
