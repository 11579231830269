import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { selectInitialPieces, selectLoadedPieces } from 'app/store/selector/entity.selectors';
import { Observable } from 'rxjs';
import { Piece } from '../models/printable-data.model';
@Component({
  selector: 'printable-data-selection-dialog',
  templateUrl: './printable-data-selection-dialog.component.html',
  styleUrls: ['./printable-data-selection-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintableDataSelectionDialogComponent implements OnInit {
  initialPieces: Piece[];
  selectedPieces: Piece[] = [];
  loadedPieces$: Observable<Piece[]>;
  initialPieces$: Observable<Piece[]>;

  constructor(
    protected dialogRef: MatDialogRef<PrintableDataSelectionDialogComponent>,

    private readonly store: Store,
  ) {}

  ngOnInit() {
    this.loadedPieces$ = this.store.select(selectLoadedPieces);
    this.initialPieces$ = this.store.select(selectInitialPieces);
  }

  sync(selectedPieces: Piece[]) {
    this.selectedPieces = selectedPieces;
  }

  syncInitialPieces(pieces: Piece[]) {
    this.initialPieces = pieces;
  }

  loadAll() {
    this.dialogRef.close(this.initialPieces);
  }

  loadPieces() {
    this.dialogRef.close(this.selectedPieces);
  }

  leaveDialog() {
    this.dialogRef.close(null);
  }
}
