import { ControlEvent, ISelection, RulerConfig } from './fabric.model';

export type Piece = {
  filepath: string;
  height: number;
  label: string;
  width: number;
  orgWidth?: number;
  orgHeight?: number;
  x: number;
  y: number;
  rotation?: number;
  redimenssion?: number; // a verifier
  transformedX?: number;
  transformedY?: number;
  path: string;
  scale?: number;
  zoom?: number;
  color: string;
};

export type PrintableData = {
  id?: number;
  modelId: number;
  preview?: string;
  pieces?: Piece[];
  isActive: boolean;
  type: PieceType;
  name: string;
};

export type PieceInfo = {
  posX: number;
  posY: number;
  rotation: number;
  redimenssion: number;
  width: number;
  height: number;
};

export enum PieceType {
  PPF = 'PPF',
  WINDOW = 'WINDOW',
  ALL = 'ALL',
}

export interface PrintableDataSession {
  readonly selectedPrintableDataId?: number;
  readonly initialPieces: Piece[];
  readonly loadedPieces: Piece[];
  readonly rulerConfig?: RulerConfig;
  readonly controlEvent?: ControlEvent;
  readonly currentCanvasState: CurrentCanvasState;
}

export interface CurrentCanvasState {
  readonly stateAsString?: string;
  readonly zoom: number;
  readonly currentSelection?: ISelection;
  readonly currentSvg?: string;
}

export enum PrinterType {
  'HPGL',
  'PLT',
}
