import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import { Serie } from '../models/serie.model';

@Component({
  selector: 'serie-selection',
  templateUrl: './serie-selection.component.html',
  styleUrls: ['./serie-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SerieSelectionComponent implements OnInit {
  @Input() serieList: Serie[];
  @Input() loadingModels: boolean;

  @Output() readonly serieSubmit = new EventEmitter<number>();
  selectionType = SelectionType;
  offset = 0;
  serieName = '';
  selected = [];
  messages = {
    emptyMessage: '',
    totalMessage: '',
  };

  constructor(protected translate: TranslateService) {}

  ngOnInit() {
    this.translate.get('no-data').subscribe(res => (this.messages['emptyMessage'] = res));
    this.translate.get('total').subscribe(res => (this.messages['totalMessage'] = res));
  }

  handlePages(event) {
    this.offset = event.offset;
  }

  filterSeries(serieName: string) {
    this.serieName = serieName;
  }

  selectRow(event: { selected: Serie[] }) {
    if (!this.loadingModels) {
      this.serieSubmit.emit(event.selected[0].id);
      this.selected.length = 0;
    }
  }
}
