import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

import { NotificationType } from '../../app/models/notification-types.const';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly translate: TranslateService,
  ) {}

  notify(notificationType: NotificationType, duration = 7000, params = '') {
    this.translate.get(notificationType, { params }).subscribe((translatedMessage: string) => {
      this.snackBar.open(translatedMessage, 'OK', { duration });
    });
  }
}
