import { HttpClient } from '@angular/common/http';
import { LoggingList, LoggingQuery } from 'app/models/logging.model';
import { Observable, map } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class LoggingService {
  route = {
    ids: 'ids',
    adminIds: 'admin-ids', // needed
    startDate: 'start-date', // needed
    endDate: 'end-date', // needed
    offset: 'offset',
    limit: 'limit', // needed
    exclude: 'exclude',
    next: 'next',
  };

  constructor(private _http: HttpClient) {}

  getLoggingList(query: LoggingQuery = {}): Observable<LoggingList> {
    const questionMark = Object.keys(query).length > 0 ? '?' : '';
    const url =
      '/api/user-logs' +
      questionMark +
      Object.keys(query)
        .map(k => (Array.isArray(query[k]) ? query[k].map((value, index) => `${this.route[k]}[${index}]=${value}`).join('&') : `${this.route[k]}=${query[k]}`))
        .join('&');

    return this._http.get<{ data: LoggingList; statusCode: number }>(url).pipe(map(response => response.data));
  }
}
