<mat-dialog-content>
  <mat-tab-group>
    <mat-tab [label]="'Plotter' | translate">
      <plotter-tab
        [filmWidth]="filmWidth | async"
        [loadedPieces]="loadedPieces$ | async"
        [currentPlotter]="currentPlotter$ | async"
        [currentPrinter]="currentPrinter$ | async"
        [plotterList]="plottersList"
        [printerList]="printerList$ | async"
        [isDaemonUp]="isDaemonUp$ | async"
        [currentPrintingMode]="currentPrintingMode$ | async"
        [currentDaemon]="daemonHostList$ | async"
        (selectDaemonHost)="updateDaemonHost($event)"
        (testPrint)="onTestPrint($event)"
        (selectPlotter)="updatePlotter($event)"
        (changeFilmWidh)="updateFilmWidth($event)"
        (selectPrinter)="onSelectPrinter($event)"
        (selectPrintingMode)="updatePrintingMode($event)"
        (addPermission)="addDevicePermission()"
      ></plotter-tab>
    </mat-tab>
    <mat-tab [label]="'profile' | translate">
      <profil-tab [user]="user$ | async" [loading]="updateUserLoading$ | async" (formSubmit)="updateUser($event)"></profil-tab>
    </mat-tab>
    <mat-tab [label]="'cutting-log' | translate">
      <printing-log></printing-log>
    </mat-tab>
    <mat-tab [label]="'credit-transfer-history' | translate">
      <credit-log></credit-log>
    </mat-tab>
    <mat-tab [label]="'change-password' | translate">
      <change-password [loading]="updateUserLoading$ | async" (formSubmit)="changePassword($event)"></change-password>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="accent" (click)="leaveDialog()">{{ 'leave' | translate }}</button>
</mat-dialog-actions>
