import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Brand } from 'app/models/brand.model';
import { EntitiesModel } from 'app/models/entities.model';
import { BrandApiActions } from '../actions/brand.actions';

export const brandFeatureKey = 'brand';

export interface State extends EntityState<Brand> {
  groupedBrands?: EntitiesModel['groupedBrands'];
}

export const adapter: EntityAdapter<Brand> = createEntityAdapter<Brand>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(BrandApiActions.loadBrandsCompleted, (state, action): State => adapter.setAll(action.brands, state)),
  on(BrandApiActions.loadGroupedBrandsCompleted, (state, action): State => ({ ...state, groupedBrands: action.groupedBrands })),
  on(BrandApiActions.clearBrands, (state): State => adapter.removeAll(state)),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
