import { Routes } from '@angular/router';
import { AntiGuard, IsLoggedGuard } from './guards';

import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';

export const appRoutes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [AntiGuard] },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [IsLoggedGuard],
  },
];
