import { Injectable } from '@angular/core';

/** WindowRefService */
@Injectable({
  providedIn: 'root',
})
export class WindowRefService {
  /** Get native window */
  get nativeWindow(): Window {
    return window;
  }

  /** Get location */
  get location(): Location {
    return this.nativeWindow.location;
  }

  /** Get navigator */
  get navigator(): Navigator {
    return this.nativeWindow.navigator;
  }

  openUrl(url: string) {
    this.nativeWindow.open(url, '_blank');
  }
}
