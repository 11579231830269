import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Brand } from 'app/models/brand.model';
import { PieceTypeWithId } from 'app/models/entities.model';
import { FilterFormFields } from 'app/models/filter-form';
import { Model } from 'app/models/model.model';
import { CurrentCanvasState, Piece, PieceType, PrintableData } from 'app/models/printable-data.model';
import { Serie } from 'app/models/serie.model';
import { Dictionary, differenceBy } from 'lodash';
import * as fromBrand from '../reducers/brand.reducer';
import * as fromModel from '../reducers/model.reducer';
import * as fromPrintableData from '../reducers/printable-data.reducer';
import * as fromSerie from '../reducers/serie.reducer';
import { SessionState } from '../reducers/session.reducer';
import { selectSessionState } from './session.selectors';

export const selectBrandState = createFeatureSelector<fromBrand.State>(fromBrand.brandFeatureKey);
export const selectSerieState = createFeatureSelector<fromSerie.State>(fromSerie.serieFeatureKey);
export const selectModelState = createFeatureSelector<fromModel.State>(fromModel.modelFeatureKey);
export const selectPrintableDataState = createFeatureSelector<fromPrintableData.State>(fromPrintableData.printableDataFeatureKey);

export const selectBrandEntities = createSelector(selectBrandState, fromBrand.selectEntities);

export const selectSerieEntities = createSelector(selectSerieState, fromSerie.selectEntities);

export const selectModelEntities = createSelector(selectModelState, fromModel.selectEntities);

export const selectPrintableDataEntities = createSelector(selectPrintableDataState, fromPrintableData.selectEntities);

export const selectAllBrands = createSelector(selectBrandState, fromBrand.selectAll);

export const selectGroupedBrand = (props: { type: PieceType }) =>
  createSelector(selectBrandState, (state: fromBrand.State) => state.groupedBrands[props.type].map(id => state.entities[id]));

export const selectSeriesByBrandId = (props: PieceTypeWithId) =>
  createSelector(selectSerieState, (state: fromSerie.State) => state.groupedSeries?.[props.type][props.id].map(serieId => state.entities[serieId]) || []);

export const selectModelsBySerieId = (props: PieceTypeWithId) =>
  createSelector(selectModelState, (state: fromModel.State) => state.groupedModels?.[props.type][props.id].map(modelId => state.entities[modelId]) || []);

export const selectPrintableDataByModelIdAndType = (props: PieceTypeWithId) =>
  createSelector(selectPrintableDataState, (state: fromPrintableData.State) =>
    state.groupedPrintableData?.[props.type][props.id]
      .map(printableDataId => state.entities[printableDataId])
      .find(printableData => printableData.type === props.type),
  );

export const selectPrintableDataByModelId = (props: { modelId: number }) =>
  createSelector(
    selectPrintableDataState,
    (state: fromPrintableData.State) =>
      state.groupedPrintableData?.[PieceType.ALL][props.modelId].map(printableDataId => state.entities[printableDataId]) || [],
  );

export const selectModelWithPrintableData = (props: { modelId: number }) =>
  createSelector(selectModelState, selectPrintableDataByModelId(props), (state: fromModel.State, printableData: PrintableData[]) => ({
    ...state.entities[props.modelId],
    printableData,
  }));

export const selectCurrentForm = createSelector(
  selectSessionState,
  selectPrintableDataEntities,
  selectModelEntities,
  selectSerieEntities,
  selectBrandEntities,
  (
    sessionState: SessionState,
    printableDataEntities: Dictionary<PrintableData>,
    modelEntities: Dictionary<Model>,
    serieEntities: Dictionary<Serie>,
    brandEntities: Dictionary<Brand>,
  ): FilterFormFields => {
    const selectedPrintableDataId = sessionState.printableDataSession.selectedPrintableDataId;
    if (!selectedPrintableDataId) {
      return;
    }

    const printableData = printableDataEntities[selectedPrintableDataId];
    const model = modelEntities[printableData?.modelId];
    const serie = serieEntities[model?.serieId];
    const brand = brandEntities[serie?.brandId];

    return {
      brand,
      serie,
      model,
      printableData,
    };
  },
);

export const selectInitialPieces = createSelector(selectSessionState, state => state.printableDataSession.initialPieces);

export const selectLoadedPieces = createSelector(selectSessionState, state => state.printableDataSession.loadedPieces);

export const selectStagedPieces = createSelector(selectInitialPieces, selectLoadedPieces, (initialPieces, loadedPieces) =>
  differenceBy(initialPieces, loadedPieces, (pieces: Piece) => pieces.label),
);

export const selectRulerConfig = createSelector(selectSessionState, (sessionState: SessionState) => sessionState.printableDataSession.rulerConfig);
export const selectCurrentCanvasState = createSelector(
  selectSessionState,
  (sessionState: SessionState) => sessionState.printableDataSession.currentCanvasState,
);
export const selectCurrentCanvas = createSelector(selectCurrentCanvasState, (canvasState: CurrentCanvasState) => canvasState.stateAsString);
export const selectCurrentSelection = createSelector(selectCurrentCanvasState, (canvasState: CurrentCanvasState) => canvasState.currentSelection);
export const selectCurrentZoom = createSelector(selectCurrentCanvasState, (canvasState: CurrentCanvasState) => canvasState.zoom);
export const selectCurrentControlEvent = createSelector(selectSessionState, (sessionState: SessionState) => sessionState.printableDataSession.controlEvent);
