/// <reference types="w3c-web-usb" />
export type ExtendedPrinting = {
  id?: number;
  userId: number;
  createdAt: Date;
  retry: number;
  printedPieces: number[];
  totalPieces: number;
  printableDataId: number;
  PrintableDatum?: any;
};
export interface Printing {
  id: string;
  userId: number;
  printedPieces: number[];
  printableDataId: number;
  plotterConfig: object;
  printedArea: number;
}
export interface PrintResponse {
  status: string;
}
export type printPiecesQuery = {
  fabricState: string;
  plotterConfig: { unit: number; switchAxis: boolean; mirror: boolean };
  printableDataId: number;
  checkable?: boolean;
  printer?: string;
};
export type PrintFailQuery = {
  message: string;
  tracelog?: string;
  printingId?: number;
};
export type PrintingQuery = {
  ids?: number[];
  modelIds?: number[];
  userIds?: number[];
  adminIds?: number[];
  startDate?: any;
  endDate?: any;
  offset?: number;
  limit?: number;
  exclude?: number[];
  next?: number;
};

export type PrintingList = {
  listing: ExtendedPrinting[];
  total: number;
};

export interface PrinterDetails {
  name: string;
  isDefault?: boolean;
  portName: string;
  claimed?: boolean;
  options?: { [key: string]: string };
}

export interface DaemonAddress {
  name: string;
  hostName: string;
  port: string;
}
export interface PlotterOutput {
  plotterOutput: string;
  printingId: number;
}

export enum PrintingMode {
  DAEMON = 'DAEMON',
  WEBUSB = 'WEBUSB',
}

export type PlotterBrand = {
  vendorId: string;
  productId: string;
  name: number;
};

export type PlotterBrandList = {
  listing: PlotterBrand[];
  total: number;
};
