<h2 #dialogTitle mat-dialog-title>{{ 'overview-title' | translate }}</h2>
<mat-dialog-content>
  <preview-editor
    [loadPieces]="loadedPieces$ | async"
    [initialPieces]="initialPieces$ | async"
    (syncInitialPieces)="syncInitialPieces($event)"
    (syncLoadedPieces)="sync($event)"
  >
  </preview-editor>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="accent" [disabled]="!selectedPieces.length" (click)="loadPieces()">{{ 'load' | translate }}</button>
  <button mat-raised-button color="accent" (click)="loadAll()">{{ 'load-all' | translate }}</button>
  <button mat-raised-button color="warn" (click)="leaveDialog()">{{ 'cancel' | translate }}</button>
</mat-dialog-actions>
