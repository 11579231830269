<div class="version">
  {{ version }}
</div>

<div class="logo">
  <img src="assets/icons/logo.png" />
</div>

<div class="install-button">
  @if (this.pwaService.canInstall$ | async) {
    <button color="primary" mat-raised-button (click)="installPwa()">
      <strong>{{ 'install-now' | translate }}</strong>
    </button>
  }

  @if (this.pwaService.isIOS) {
    <p>
      <strong>{{ 'install-app' | translate }}</strong>
    </p>
  }
</div>

<login-form
  [loading]="loading"
  [loginDataFromStorage]="loginDataFromStorage"
  (userDataSubmit)="submitUserData($event)"
  (RequestResetPasswordNavigation)="NavigateToRequestResetPassword()"
></login-form>
<div class="additional-informations">
  @for (contact of contactList; track contact) {
    <div>
      <p>{{ 'tel' | translate }}: {{ contact.phone }}</p>
      <p>{{ 'mail' | translate }}: {{ contact.email }}</p>
    </div>
  }
</div>
