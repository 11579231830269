import { Observable, throwError as observableThrowError } from 'rxjs';

import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, first, map, switchMap } from 'rxjs/operators';
import { appConfig } from 'environments/environment';
import { AuthService } from './auth.service';
import { Store } from '@ngrx/store';
import { selectCredentials } from 'app/store/selector/session.selectors';
import { Credentials } from 'app/models/user.model';
import { logout } from 'app/store/actions/session.actions';

@Injectable()
export class InterceptorService implements HttpInterceptor {
  localtimeOffset = 0;
  counter = 0;

  constructor(
    private authService: AuthService,
    private readonly store: Store,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('i18n') || request.url.includes('mat-icons')) {
      return next.handle(request);
    }

    if (this.authService.noAuthEndPoint.includes(request.url)) {
      request = request.clone({ url: `${appConfig.apiHost}${request.url}` });

      return this.handleRequest(next, request);
    }

    return this.store.select(selectCredentials).pipe(
      first(),
      switchMap((credentials: Credentials) => {
        const encodedUri = encodeURI(request.url).replace(/%5B/g, '[').replace(/%5D/g, ']').replace('+', '%2B');

        request = request.clone({
          url: appConfig.apiHost + encodedUri,
          setHeaders: {
            Authorization: `Bearer ${credentials.token}`,
          },
        });

        return this.handleRequest(next, request);
      }),
    );
  }

  private handleRequest(next: HttpHandler, request: HttpRequest<any>) {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event.type === HttpEventType.Sent) {
          this.counter++;
          this.changeCursor();
        }

        if (event instanceof HttpResponse) {
          this.counter--;
          this.changeCursor();
          if (this.localtimeOffset === 0 && event.body && event.body['generatedAt']) {
            this.localtimeOffset = new Date().getTime() - event.body['generatedAt'];
          }

          return event.clone({
            body: {
              data: event.body,
              statusCode: event.status,
            },
          });
        }

        return event;
      }),
      catchError(err => {
        this.counter--;
        this.changeCursor();
        if (err.status === 401) {
          this.store.dispatch(logout());
        }

        return observableThrowError(() => ({ statusCode: err.status, data: {}, message: err.message }));
      }),
    );
  }

  changeCursor() {
    const body = document.querySelector('body');
    if (this.counter === 0) {
      body.style['cursor'] = 'initial';
    } else {
      body.style['cursor'] = 'wait';
    }
  }
}
