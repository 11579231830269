import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LoginData } from 'app/models/user.model';
import { Observable, Subscription } from 'rxjs';
import packageJson from '../../../package.json';

import { login } from 'app/store/actions/session.actions';
import { SessionLoading } from 'app/store/reducers/session.reducer';
import { AuthService } from '../app-services';
import { RequestResetPasswordDialogComponent } from '../request-reset-password-dialog/request-reset-password-dialog.component';
import { ResetPasswordDialogComponent } from '../reset-password-dialog/reset-password-dialog.component';
import { selectLoading } from 'app/store/selector/session.selectors';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, OnDestroy {
  dialogRef: any;
  eventListner: Subscription[] = [];
  loading$: Observable<boolean>;
  version = packageJson.version;
  loginDataFromStorage: LoginData;

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected dialog: MatDialog,
    private readonly store: Store,
  ) {}

  ngOnInit() {
    this.loginDataFromStorage = this.authService.getLoginDataFromStorage();
    this.loading$ = this.store.select(selectLoading({ loadingType: SessionLoading.login }));
  }

  login(loginData: LoginData) {
    this.store.dispatch(login({ loginData }));
  }

  NavigateToRequestResetPassword() {
    const config: MatDialogConfig = { width: '40%', disableClose: true };
    this.dialogRef = this.dialog.open(RequestResetPasswordDialogComponent, config);

    this.dialogRef.afterClosed().subscribe(res => {
      if (res === 'ResetPassword') {
        this.dialogRef = this.dialog.open(ResetPasswordDialogComponent, config);
      }
    });
  }

  ngOnDestroy() {
    this.eventListner.forEach(e => e.unsubscribe());
  }
}
