import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FilterQuery } from 'app/models/logging.model';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'credit-log-filter',
  templateUrl: './credit-log-filter.component.html',
})
export class CreditLogFilterComponent implements OnInit, OnDestroy {
  loggingFiltreForm: FormGroup;
  @Output() readonly filterQuery = new EventEmitter<FilterQuery>();

  maxFirstDate = new Date();
  minLastDate: Date = null;
  maxLastDate = new Date();

  private readonly unsubscribeSubject: Subject<void>;

  constructor() {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnInit() {
    this.buildForm();
  }

  private buildForm() {
    this.loggingFiltreForm = new FormGroup({
      startDate: new FormControl(null),
      endDate: new FormControl(null),
    });

    this.loggingFiltreForm.controls['startDate'].valueChanges.pipe(takeUntil(this.unsubscribeSubject)).subscribe(fDate => (this.minLastDate = fDate));
    this.loggingFiltreForm.controls['endDate'].valueChanges.pipe(takeUntil(this.unsubscribeSubject)).subscribe(lDate => (this.maxFirstDate = lDate));
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }

  search() {
    const startDate = this.loggingFiltreForm.controls['startDate'].value;
    const endDate = this.loggingFiltreForm.controls['endDate'].value;
    this.filterQuery.emit({
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null,
    });
  }
}
