import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PrintingMode } from 'app/models/printing.model';
import { isDaemonUp } from 'app/store/actions/session.actions';
import { selectDaemonHost, selectPrintingMode } from 'app/store/selector/user.selectors';
import { Observable, of, timer } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, tap, filter, takeUntil } from 'rxjs/operators';

@Injectable()
export class DaemonService {
  constructor(
    private readonly httpBackend: HttpBackend,
    private readonly httpWithoutInterceptor: HttpClient,
    private readonly store: Store,
  ) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
  }

  checkIfDaemonIsUpWithTimer(): Observable<boolean> {
    /** 20 seconds */
    const PING_TIME_OFFLINE = 20 * 1000;
    /** 30 seconds */
    const PING_TIME_ONLINE = 30 * 1000;
    let pingTime = PING_TIME_ONLINE;

    return this.store.select(selectPrintingMode).pipe(
      filter(printingMode => printingMode === PrintingMode.DAEMON),
      switchMap(() => this.store.select(selectDaemonHost)),
      tap(() => {
        /** Setting false as default value  */
        this.store.dispatch(isDaemonUp({ isDaemonUp: false }));
      }),
      switchMap((daemonHost: string) => {
        return timer(0, pingTime).pipe(
          switchMap(() =>
            this.httpWithoutInterceptor.get(`http://${daemonHost}`).pipe(
              map((result: { data: any; statusCode: number }) => {
                if (result.statusCode === 0) {
                  pingTime = PING_TIME_OFFLINE;

                  return false;
                }
                pingTime = PING_TIME_ONLINE;

                return true;
              }),
              catchError(() => {
                pingTime = PING_TIME_OFFLINE;

                return of(false);
              }),
            ),
          ),
          distinctUntilChanged(),
          takeUntil(this.store.select(selectPrintingMode).pipe(filter(printingMode => printingMode !== PrintingMode.DAEMON))),
          tap(status => {
            this.store.dispatch(isDaemonUp({ isDaemonUp: status }));
          }),
        );
      }),
    );
  }
}
