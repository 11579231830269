import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HandleErrorsService {
  constructor(
    private snackbar: MatSnackBar,
    protected translate: TranslateService,
  ) {}

  handleErrors(err: { statusCode: number; data: unknown }) {
    switch (true) {
      case err.statusCode >= 500 && err.statusCode <= 511:
        this.translate.get('server-error').subscribe(m => {
          this.snackbar.open(m, 'OK', { duration: 7000 });
        });
        break;
      case err.statusCode === 404:
        this.translate.get('not-found').subscribe(m => {
          this.snackbar.open(m, 'OK', { duration: 7000 });
        });
        break;
      case err.statusCode === 401:
        this.translate.get('no-auth').subscribe(m => {
          this.snackbar.open(m, 'OK', { duration: 7000 });
        });
        break;
      case err.statusCode === 403:
        this.translate.get('no-acces').subscribe(m => {
          this.snackbar.open(m, 'OK', { duration: 7000 });
        });
        break;
      case err.statusCode === 406:
        this.translate.get('incomplete-message').subscribe(m => {
          this.snackbar.open(m, 'OK', { duration: 7000 });
        });
        break;
      default:
        this.translate.get('client-error').subscribe(m => {
          this.snackbar.open(m + ' ' + err.statusCode, 'OK', { duration: 7000 });
        });
        break;
    }
  }
}
