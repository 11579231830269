import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginData } from 'app/models/user.model';
import { isEmpty } from 'lodash';

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent implements OnInit {
  @Output() readonly userDataSubmit = new EventEmitter<LoginData>();
  @Output() readonly RequestResetPasswordNavigation = new EventEmitter<void>();
  @Input() loading: boolean;
  @Input() loginDataFromStorage: LoginData;
  showPassword = false;
  loginForm: FormGroup;

  ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, Validators.required),
      rememberMe: new FormControl(false),
    });

    if (!isEmpty(this.loginDataFromStorage)) {
      this.loginForm.setValue(this.loginDataFromStorage);
    }
  }

  submitUserData() {
    this.userDataSubmit.emit(this.loginForm.value);
  }

  navigateToRequestResetPassword() {
    this.RequestResetPasswordNavigation.emit();
  }
}
