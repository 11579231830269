import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { isEmpty } from 'lodash';
import { from, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';

import { NotificationType } from 'app/models/notification-types.const';
import { Credentials, User } from 'app/models/user.model';

import * as SessionActions from '../actions/session.actions';
import * as UserActions from '../actions/user.actions';

import { AuthService, PrintableDataService, TouchTranslateService, UserService } from 'app/app-services';
import { DaemonService } from 'app/app-services/daemon.service';
import { NotificationService } from 'app/app-services/notification.service';
import { EntitiesModel } from 'app/models/entities.model';
import { appConfig } from 'environments/environment';

@Injectable()
export class SessionEffects {
  initLanguage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SessionActions.startAppInitialization),
        switchMap(() => this.appTranslateService.initLanguage()),
      );
    },
    { dispatch: false },
  );

  setUserConfigurationOnStartInitialization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.startAppInitialization),
      map(() => UserActions.setUserConfiguration({ userConfiguration: this.userService.getUserConfigurationFromStorage() })),
    );
  });

  checkAuthenticationOnStartInitialization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.startAppInitialization),
      map(() => SessionActions.checkAuthentication()),
    );
  });

  setUserConfiguration$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActions.setUserConfiguration),
        tap(action => {
          this.userService.setUserConfigurationToStorage(action.userConfiguration);
        }),
      );
    },
    { dispatch: false },
  );

  checkUserAuthentication$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.checkAuthentication),
      map(() => this.authService.checkCredentials()),
      map((credentials: Credentials) => (isEmpty(credentials) ? SessionActions.finishedAppInitialization() : SessionActions.setCredentials({ credentials }))),
    );
  });

  setCredentials$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.setCredentials),
      switchMap(action => this.userService.getUser(action.credentials.payload.id)),
      map((user: User) => SessionActions.setCredentialsCompleted({ user })),
      catchError(error => [UserActions.getUserError(error), SessionActions.finishedAppInitialization()]),
    );
  });

  pingDaemonOnSetCredentialsCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.setCredentialsCompleted),
      switchMap(() => this.daemonService.checkIfDaemonIsUpWithTimer()),
      map(status => SessionActions.isDaemonUp({ isDaemonUp: status })),
    );
  });

  pingDaemonOnLoginCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.loginCompleted),
      switchMap(() => this.daemonService.checkIfDaemonIsUpWithTimer()),
      map(status => SessionActions.isDaemonUp({ isDaemonUp: status })),
    );
  });

  setIsUserAuthenticatedOnCredentialsCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.setCredentialsCompleted),
      map(() => SessionActions.isUserAuthenticated()),
    );
  });

  getUserAuthenticatedOnCredentialsCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.setCredentialsCompleted),
      map(action => UserActions.getUserCompleted({ user: action.user })),
    );
  });

  getBannerOnCredentialsCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.setCredentialsCompleted),
      map(() => SessionActions.getBanner()),
    );
  });

  loadEntitiesOnCredentialsCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.setCredentialsCompleted),
      map(() => SessionActions.loadEntities()),
    );
  });

  getBanner$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.getBanner),
      switchMap(() => this.userService.getBanner()),
      map(banner => SessionActions.getBannerCompleted({ banner })),
      catchError(() => [SessionActions.finishedAppInitialization()]),
    );
  });

  getBannerCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.getBannerCompleted),
      map(action =>
        SessionActions.setBanner({
          banner: {
            picture: `${appConfig.apiHost}/uploads/banner/${action.banner.picture}`,
          },
        }),
      ),
    );
  });

  finishAppInitOnGetBannerCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.getBannerCompleted),
      map(() => SessionActions.finishedAppInitialization()),
    );
  });

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.login),
      exhaustMap(action =>
        this.authService.login(action.loginData).pipe(
          tap(credentials => {
            this.authService.setCredentialsToStorage(credentials, action.loginData);
          }),
          map(credentials => SessionActions.loginCompleted({ credentials })),
          catchError(error => {
            return of(SessionActions.loginError({ error }));
          }),
        ),
      ),
    );
  });

  setIsUserAuthenticatedOnLoginCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.loginCompleted),
      map(() => SessionActions.isUserAuthenticated()),
    );
  });

  getUserOnLoginCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.loginCompleted),
      map(action => UserActions.getUser({ userId: action.credentials.payload.id, getBanner: true })),
    );
  });

  loadEntitiesOnLoginCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.loginCompleted),
      map(() => SessionActions.loadEntities()),
    );
  });

  loadEntities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.loadEntities),
      switchMap(() => this.printableDataService.loadEntities().pipe(map((entities: EntitiesModel) => SessionActions.loadEntitiesCompleted({ entities })))),
    );
  });

  loginCompleted$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SessionActions.loginCompleted),
        tap(() => {
          this.notificationService.notify(NotificationType.LOGIN_SUCCES, 5000);
        }),
        switchMap(() => from(this.router.navigate(['/']))),
      );
    },
    { dispatch: false },
  );

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.logout),
      map(() => SessionActions.resetState()),
      catchError(error => {
        return of(SessionActions.logoutError({ error }));
      }),
    );
  });

  resetState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.resetState),
      map(() => {
        this.authService.clearStorage();

        return SessionActions.logoutCompleted();
      }),
    );
  });

  logoutCompleted$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SessionActions.logoutCompleted),
        tap(() => {
          this.notificationService.notify(NotificationType.LOGOUT, 5000);
        }),
        switchMap(() => from(this.router.navigate(['/login']))),
      );
    },
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly appTranslateService: TouchTranslateService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
    private readonly printableDataService: PrintableDataService,
    private readonly daemonService: DaemonService,
  ) {}
}
