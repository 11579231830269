import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EntitiesModel } from 'app/models/entities.model';
import { Serie } from 'app/models/serie.model';
import { SerieApiActions } from '../actions/serie.actions';

export const serieFeatureKey = 'serie';

export interface State extends EntityState<Serie> {
  // additional entities state properties
  groupedSeries?: EntitiesModel['groupedSeries'];
}

export const adapter: EntityAdapter<Serie> = createEntityAdapter<Serie>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(SerieApiActions.loadSeriesCompleted, (state, action): State => adapter.setAll(action.series, state)),
  on(SerieApiActions.loadGroupedSeriesCompleted, (state, action): State => ({ ...state, groupedSeries: action.groupedSeries })),
  on(SerieApiActions.clearSeries, (state): State => adapter.removeAll(state)),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
