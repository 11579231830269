import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Address } from 'app/models/user.model';
import { City, Country, ICity, ICountry, IState, State } from 'country-state-city';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'address-form',
  templateUrl: './address-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressFormComponent implements OnInit, OnDestroy {
  @Input() address: Address;
  @Output() readonly addressValue = new EventEmitter<Address>();
  countries = Country.getAllCountries();
  states: IState[];
  cities: ICity[];

  addressForm = new FormGroup({
    address: new FormControl('', Validators.required),
    country: new FormControl(null, Validators.required),
    state: new FormControl(null, Validators.required),
    city: new FormControl(null, Validators.required),
  });

  private unsubscribeSubject = new Subject<void>();

  ngOnInit() {
    if (this.address?.address) {
      this.initAdress();
    }
    this.addressForm.valueChanges.pipe(takeUntil(this.unsubscribeSubject)).subscribe({
      next: value => {
        this.addressValue.emit({
          address: `${value.address}, ${value.city?.name}, ${value.state?.name}`,
          country: value.country?.isoCode,
        });
      },
    });
  }

  private initAdress() {
    const splittedAddress = this.address.address.split(', ');
    const [city, state] = splittedAddress.slice(-2);
    const address = splittedAddress.slice(0, -2).join(', ');

    this.addressForm.get('address').setValue(address);
    const countryForm = this.addressForm.get('country');
    const stateForm = this.addressForm.get('state');

    countryForm.setValue(this.countries.find(country => country.isoCode === this.address?.country));

    this.states = State.getStatesOfCountry(countryForm.value?.isoCode);
    stateForm.setValue(this.states.find(iState => iState.name === state));

    this.cities = City.getCitiesOfState(countryForm.value?.isoCode, stateForm.value?.isoCode);
    this.addressForm.get('city').setValue(this.cities.find(iCity => iCity.name === city));
  }

  countrySelected(selectedCountry: ICountry) {
    this.states = State.getStatesOfCountry(selectedCountry.isoCode);
    this.addressForm.get('state').reset();
    this.addressForm.get('city').reset();
  }
  stateSelected(selectedState: IState) {
    this.cities = City.getCitiesOfState(this.addressForm.get('country').value.isoCode, selectedState.isoCode);
    this.addressForm.get('city').reset();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
