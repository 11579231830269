import { createAction, props } from '@ngrx/store';

import { User, UserConfiguration, UserInfo, UserPasswordChange } from '../../models/user.model';

export const setUserConfiguration = createAction('[Session init app] Set User Configuration', props<{ userConfiguration: UserConfiguration }>());
export const updateUserConfiguration = createAction('[Session] Update User Configuration', props<{ partialUserConfiguration: Partial<UserConfiguration> }>());
export const getUser = createAction('[Session] Get User', props<{ userId: number; getBanner: boolean }>());
export const getUserCompleted = createAction('[User Api] Get User Completed', props<{ user: User }>());
export const getUserCompletedWithBanner = createAction('[User Api] Get User Completed with banner', props<{ user: User }>());
export const getUserError = createAction('[User Api] Get User Error', props<{ error }>());

export const updateUser = createAction('[User dialog] Update User', props<{ userInfo: UserInfo }>());

export const updateUserCompleted = createAction('[User Api] Update User Completed', props<{ user: User }>());

export const updateUserError = createAction('[User Api] Update User Error', props<{ error }>());

export const updateUserPassword = createAction('[User dialog] Update User password', props<{ passwords: UserPasswordChange }>());

export const updateUserPasswordCompleted = createAction('[User Api] Update User password Completed', props<{ user: User }>());

export const updateUserPasswordError = createAction('[User Api] Update User password Error', props<{ error }>());
export const refreshUserData = createAction('[Session] Refresh User Data');
