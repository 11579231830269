import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SwUpdaterService } from './app-services/sw-updater.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private swUpdaterService: SwUpdaterService,
  ) {
    const prefix = 'mat-icons/svg-sprite-';
    [
      'action',
      'alert',
      'av',
      'communication',
      'content',
      'device',
      'editor',
      'file',
      'hardware',
      'image',
      'maps',
      'navigation',
      'notification',
      'places',
      'social',
      'toggle',
    ].map(url => iconRegistry.addSvgIconSet(sanitizer.bypassSecurityTrustResourceUrl(prefix + url + '.svg')));
    this.swUpdaterService.alertSwUpdate();
  }
}
