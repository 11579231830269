<h2 mat-dialog-title>{{ 'request-reset-password-title' | translate }}</h2>
<mat-dialog-content>
  <request-reset-password #dumb></request-reset-password>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-raised-button
    color="accent"
    type="submit"
    [disabled]="dumb.requestResetPasswordForm.invalid"
    (click)="sendRequest(dumb.requestResetPasswordForm.controls['email'].value)"
  >
    {{ 'send' | translate }}
  </button>
  <button mat-raised-button color="warn" (click)="dialogRef.close()">{{ 'cancel' | translate }}</button>
</mat-dialog-actions>
