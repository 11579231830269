import { fabricObject, fabricWithId } from '../models/fabric.model';
export function isRuler(object: fabricWithId | fabricObject) {
  return object.label === 'indicator' || (object as fabricObject).type === 'text';
}

export const victorialToLinearMeasure = (value: number, unit: 'mm' | 'cm' | 'dm' | 'm' = 'cm'): number => {
  let result = value / (72 / 25.4); // 2.54 pour cm et 25.4 pour mm

  switch (unit) {
    case 'mm':
      break;
    case 'cm':
      result /= 10;

      break;
    case 'dm':
      result /= 100;

      break;
    case 'm':
      result /= 1000;

      break;
  }

  return result;
};

export const linearMeasureToVictorial = (value: number, unit: 'mm' | 'cm' | 'dm' | 'm' = 'cm'): number => {
  let result = value / (25.4 / 72); // 2.54 pour cm et 25.4 pour mm

  switch (unit) {
    case 'mm':
      break;
    case 'cm':
      result *= 10;

      break;
    case 'dm':
      result *= 100;

      break;
    case 'm':
      result *= 1000;

      break;
  }

  return result;
};

export const rotateIcon = document.createElement('img');
rotateIcon.src = 'assets/images/rotate.svg';

export const posCursorMap = new Map<number, string>([
  [-90, '9.25 5.25'],
  [-75, '9.972 3.863'],
  [-60, '10.84 1.756'],
  [-45, '11.972 -1.716'],
  [-30, '18.83 0.17'],
  [-15, '28.49 -9.49'],
  [15, '-7.985 46.77'],
  [30, '-0.415 27.57'],
  [45, '2.32 21.713'],
  [60, '3.916 18.243'],
  [75, '4.762 16.135'],
  [90, '5.25 14.75'],
  [105, '5.84 13.617'],
  [120, '6.084 12.666'],
  [135, '6.317 12.01'],
  [150, '6.754 11.325'],
  [165, '7.06 10.653'],
  [180, '7.25 10'],
  [195, '7.597 9.43'],
  [210, '7.825 8.672'],
  [225, '7.974 7.99'],
  [240, '8.383 7.332'],
  [255, '8.83 6.441'],
]);
