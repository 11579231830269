<form (ngSubmit)="submitUserData()" [formGroup]="loginForm">
  <mat-form-field>
    <mat-label translate="email"></mat-label>
    <input matInput type="email" formControlName="email" updateOnBlur />
    @if (loginForm.controls['email'].hasError('required')) {
      <mat-error>
        {{ 'required' | translate }}
      </mat-error>
    }
  </mat-form-field>
  <div class="showPassword">
    <mat-form-field>
      <mat-label translate="password"></mat-label>
      <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="password" updateOnBlur />
      @if (loginForm.controls['password'].hasError('required')) {
        <mat-error>
          {{ 'required' | translate }}
        </mat-error>
      }
      <mat-icon
        matSuffix
        [color]="loginForm.get('password')?.hasError('*', ['touched', 'invalid']) ? 'warn' : 'primary'"
        (mousedown)="showPassword = true"
        (mouseleave)="showPassword = false"
        (mouseup)="showPassword = false"
        (touchstart)="$event.stopPropagation(); showPassword = true"
        (touchend)="$event.stopPropagation(); showPassword = false"
        [svgIcon]="showPassword ? 'ic_visibility_off_24px' : 'ic_visibility_24px'"
      ></mat-icon>
    </mat-form-field>
  </div>
  <mat-checkbox formControlName="rememberMe">{{ 'register-password' | translate }} </mat-checkbox>

  @if (!loading) {
    <button color="primary" [disabled]="loginForm.invalid" mat-raised-button type="submit">{{ 'login' | translate }}</button>
  }

  @if (loading) {
    <button color="primary" [disabled]="loginForm.invalid || loading" mat-raised-button type="submit">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </button>
  }
</form>
<a (click)="navigateToRequestResetPassword()">{{ 'forgot-password' | translate }}</a>
