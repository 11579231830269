@if (brandList?.length > 0) {
  <div>
    <search-filter [type]="'brand'" (searchText)="filterBrands($event)"></search-filter>
    <mat-list>
      @for (brand of brandList | filterBy: ['name'] : brandName; track brand) {
        <mat-list-item>
          <button class="brand-button" mat-raised-button [matTooltip]="brand.name" (click)="submitBrand(brand)">
            <img [src]="url + brand.logo" />
            {{ brand.name | slice: 0 : 12 }} {{ brand.name.length > 12 ? '...' : '' }}
          </button>
        </mat-list-item>
      }
    </mat-list>
  </div>
} @else {
  <div class="no-table-content assistance-no-content">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
}
