<div class="model-details">
  <h3>{{ model.name }}</h3>
  <div class="image-wrapper" [ngClass]="{ 'hide-spinner': !loadingModel }">
    <img [src]="url + model.picture" (load)="loading($event, 'model')" />
  </div>
  <div class="model-image" [ngClass]="{ 'hide-spinner': loadingModel }">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
  <h3>{{ 'description' | translate }}</h3>
  <p>{{ model.description }}</p>
</div>
<div class="model-types">
  @if (model.printableData?.length > 0) {
    <mat-tab-group (selectedTabChange)="changeType($event)">
      @if (isAllowedPPF && printablePPFData?.preview; as preview) {
        <mat-tab [label]="'PPF' | translate">
          <img [ngClass]="{ 'hide-spinner': !loadingPPF }" [src]="url + 'printable-data/' + preview" (load)="loading($event, 'PPF')" />
          <div class="image-spinner" [ngClass]="{ 'hide-spinner': loadingPPF }">
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
          </div>
        </mat-tab>
      }
      @if (isAllowedWindow && printableWindowData?.preview; as preview) {
        <mat-tab [label]="'window' | translate">
          <img [ngClass]="{ 'hide-spinner': !loadingWINDOW }" [src]="url + 'printable-data/' + preview" (load)="loading($event, 'WINDOW')" />
          <div class="image-spinner" [ngClass]="{ 'hide-spinner': loadingWINDOW }">
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
          </div>
        </mat-tab>
      }
    </mat-tab-group>
  }
</div>
