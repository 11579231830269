import { Injectable } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { NotificationType } from 'app/models/notification-types.const';
import { appConfig } from 'environments/environment';
import { filter } from 'rxjs/operators';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class SwUpdaterService {
  constructor(private readonly swUpdate: SwUpdate, private readonly notificationService: NotificationService) {}
  alertSwUpdate() {
    if (appConfig.production) {
      this.swUpdate.versionUpdates.pipe(filter((event: VersionEvent) => event.type === 'VERSION_READY')).subscribe(() => {
        this.notificationService.notify(NotificationType.UPDATE_AVAILABLE, 50000);
      });
    }
  }
}
