<h3>{{ 'change-password-title' | translate }}</h3>
<form (ngSubmit)="submitForm()" [formGroup]="passwordForm">
  <mat-form-field>
    <mat-label translate="current-password"></mat-label>
    <input matInput type="password" formControlName="currentPassword" updateOnBlur />
    @if (passwordForm.controls['currentPassword'].hasError('required')) {
      <mat-error>
        {{ 'required' | translate }}
      </mat-error>
    }
  </mat-form-field>

  <mat-form-field>
    <mat-label translate="new-password"></mat-label>
    <input matInput type="password" formControlName="newPassword" updateOnBlur />
    @if (passwordForm.controls['newPassword'].hasError('required')) {
      <mat-error>
        {{ 'required' | translate }}
      </mat-error>
    }
  </mat-form-field>

  <mat-form-field>
    <mat-label translate="confirm-password"></mat-label>
    <input matInput type="password" formControlName="confirmPassword" updateOnBlur />
    @if (passwordForm.controls['confirmPassword'].hasError('required')) {
      <mat-error>
        {{ 'required' | translate }}
      </mat-error>
    }
  </mat-form-field>
  @if (!passwordForm.controls['confirmPassword'].hasError('required') && passwordForm.hasError('mismatch')) {
    <mat-error class="mismatch-error">
      {{ 'mismatch' | translate }}
    </mat-error>
  }
  <div class="btn-wrapper">
    <button color="warn" mat-raised-button type="submit" [disabled]="passwordForm.invalid || loading">{{ 'change' | translate }}</button>
    @if (loading) {
      <div class="spinner-wrapper">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      </div>
    }
  </div>
</form>
