import { createAction, props } from '@ngrx/store';
import { PrinterDetails, PrintFailQuery, PrintingMode } from 'app/models/printing.model';

export const startPrinting = createAction('[Dashboard] Start Printing The Current Selection');
export const getHpglPiecesCompleted = createAction('[API] Get Hpgl Completed', props<{ hpgl: string }>());
export const printError = createAction('[API] Print Hpgl Error', props<{ error: PrintFailQuery }>());
export const selectPlotter = createAction('[Dashboard] Select Printer');
export const selectPlotterCompleted = createAction('[Dashboard] Printer Selection Complete');
export const testPrinting = createAction('[Dashboard] Test Printing', props<{ hpgl: string }>());
export const printHpglCompleted = createAction('[Printing] Print Hpgl Data Completed');
export const updatePrintingMode = createAction('[Dashboard] Update Printer Mode', props<{ printingMode: PrintingMode }>());
export const loadPrinters = createAction('[Printing] Load Printers', props<{ printers: PrinterDetails[] }>());
export const AddDevicePermission = createAction('[Printing] Add Device Permission');
