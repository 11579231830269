<form [formGroup]="requestResetPasswordForm" novalidate>
  <mat-form-field>
    <mat-label translate="email"></mat-label>
    <input matInput type="email" formControlName="email" updateOnBlur />
    @if (requestResetPasswordForm.controls['email'].hasError('required')) {
      <mat-error>
        {{ 'required' | translate }}
      </mat-error>
    }
  </mat-form-field>
</form>
