import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';

import { TouchTranslateService, UserService } from 'app/app-services';
import { User, UserConfiguration } from 'app/models/user.model';
import * as UserActions from '../actions/user.actions';
import * as SessionActions from '../actions/session.actions';
import { selectUserConfiguration } from '../selector/session.selectors';
import { selectUser } from '../selector/user.selectors';
import { NotificationService } from 'app/app-services/notification.service';
import { NotificationType } from 'app/models/notification-types.const';
import { updatePrintingMode } from '../actions/printing.actions';

@Injectable()
export class UserEffects {
  setUserConfiguration$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActions.setUserConfiguration),
        tap(action => {
          this.userService.setUserConfigurationToStorage(action.userConfiguration);
        }),
      );
    },
    { dispatch: false },
  );

  updateUserConfiguration$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActions.updateUserConfiguration, updatePrintingMode),
        mergeMap(() => this.store.select(selectUserConfiguration)),
        tap((userConfiguration: UserConfiguration) => {
          this.userService.setUserConfigurationToStorage(userConfiguration);
        }),
      );
    },
    { dispatch: false },
  );

  getUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.getUser),
      switchMap(action =>
        this.userService.getUser(action.userId).pipe(
          map((user: User) => (action.getBanner ? UserActions.getUserCompletedWithBanner({ user }) : UserActions.getUserCompleted({ user }))),
          catchError(error => of(UserActions.getUserError({ error }))),
        ),
      ),
    );
  });

  getBannerOnUserCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.getUserCompletedWithBanner),
      map(() => SessionActions.getBanner()),
    );
  });

  getUserCompletedWithBanner$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.getUserCompletedWithBanner),
      map(action => UserActions.getUserCompleted({ user: action.user })),
    );
  });

  updateUserLanguage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActions.getUserCompleted, UserActions.updateUserCompleted),
        tap(action => {
          this.appTranslateService.updateUserLanguage(action.user.language);
        }),
      );
    },
    { dispatch: false },
  );

  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.updateUser),
      concatLatestFrom(() => this.store.select(selectUser)),
      switchMap(([action, user]) =>
        this.userService.updateUser({ ...action.userInfo, id: user.id }).pipe(
          map((user: User) => UserActions.updateUserCompleted({ user })),
          catchError(error => of(UserActions.updateUserError({ error }))),
        ),
      ),
    );
  });

  updateUserCompleted$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActions.updateUserCompleted),
        tap(() => {
          this.notificationService.notify(NotificationType.MODIFICATION_MESSAGE_SUCCES, 7000);
        }),
      );
    },
    { dispatch: false },
  );

  updateUserPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.updateUserPassword),
      switchMap(action =>
        this.userService.changePassword(action.passwords).pipe(
          map((user: User) => UserActions.updateUserPasswordCompleted({ user })),
          catchError(error => of(UserActions.updateUserError({ error }))),
        ),
      ),
    );
  });

  updateUserPasswordCompleted$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActions.updateUserPasswordCompleted),
        tap(() => {
          this.notificationService.notify(NotificationType.PASSWORD_MESSAGE_SUCCES, 7000);
        }),
      );
    },
    { dispatch: false },
  );

  refreshUserData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.refreshUserData),
      switchMap(() => this.store.select(selectUser).pipe(take(1))),
      map((user: User) => UserActions.getUser({ userId: user.id, getBanner: false })),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly notificationService: NotificationService,
    private readonly appTranslateService: TouchTranslateService,
    private readonly userService: UserService,
  ) {}
}
