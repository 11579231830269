<filter-menu-dumb
  #filterMenuDumbRef
  [pieceTypeList]="pieceTypeList$ | async"
  [brandList]="brands$ | async"
  [serieList]="series$ | async"
  [modelList]="models$ | async"
  [currentForm]="currentForm"
  [selectedPrintableData]="selectedPrintableData$ | async"
  [allowedPrintedWindows]="allowedPrintedWindows"
  [allowedPrintedPPF]="allowedPrintedPPF"
  (typeSelected)="getBrandList($event)"
  (brandSelected)="getSerieList($event)"
  (serieSelected)="getModelList($event)"
  (modelSelected)="getPrintableData($event)"
  (filterFormSubmit)="getFilterForm($event)"
  (openAssistanceDialog)="openDialogAssistance()"
  (openModelPreviewDialog)="openModelPreviewDialog($event)"
></filter-menu-dumb>
