import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as fromEntity from 'app/store/selector/entity.selectors';
import { Observable, of } from 'rxjs';
import { ModelPreviewDialogComponent } from '../model-preview-dialog/model-preview-dialog.component';

import { PieceTypeWithId } from 'app/models/entities.model';
import { FilterFormFields } from 'app/models/filter-form';
import { PieceType, PrintableData } from 'app/models/printable-data.model';
import { FilterMenuDumbComponent } from '../filter-menu-dumb/filter-menu-dumb.component';
import { Brand } from '../models/brand.model';
import { Model } from '../models/model.model';
import { Serie } from '../models/serie.model';

@Component({
  selector: 'filter-menu-smart',
  templateUrl: './filter-menu-smart.component.html',
  styleUrls: ['./filter-menu-smart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterMenuSmartComponent implements OnInit {
  @Input() allowedPrintedWindows: number;
  @Input() allowedPrintedPPF: number;
  @Input() currentForm: FilterFormFields;

  @Output() readonly filter = new EventEmitter<PrintableData>();
  @Output() readonly openAssistanceDialog = new EventEmitter<void>();

  dialogRef: any;

  @ViewChild('filterMenuDumbRef', { static: true }) filterMenuDumbRef: FilterMenuDumbComponent;

  brands$: Observable<Brand[]>;
  series$: Observable<Serie[]>;
  models$: Observable<Model[]>;
  pieceTypeList$: Observable<PieceType[]>;
  selectedPrintableData$: Observable<PrintableData>;

  constructor(
    private readonly dialog: MatDialog,
    private readonly store: Store,
  ) {}

  ngOnInit() {
    this.pieceTypeList$ = of([PieceType.PPF, PieceType.WINDOW]);
  }

  getBrandList(type: PieceType) {
    this.brands$ = this.store.select(fromEntity.selectGroupedBrand({ type }));
  }

  getSerieList(typeWithId: PieceTypeWithId) {
    this.series$ = this.store.select(fromEntity.selectSeriesByBrandId(typeWithId));
  }

  getModelList(typeWithId) {
    this.models$ = this.store.select(fromEntity.selectModelsBySerieId(typeWithId));
  }

  getPrintableData(typeWithId: PieceTypeWithId) {
    this.selectedPrintableData$ = this.store.select(fromEntity.selectPrintableDataByModelIdAndType(typeWithId));
  }

  openModelPreviewDialog(model: Model) {
    const config: MatDialogConfig = {
      width: '360px',
      height: '240px',
      data: model,
      panelClass: 'no-p',
    };
    this.dialogRef = this.dialog.open(ModelPreviewDialogComponent, config);
  }

  getFilterForm(printableData: PrintableData) {
    this.filter.emit(printableData);
  }

  openDialogAssistance() {
    this.openAssistanceDialog.emit();
  }
}
