import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UserPasswordChange } from 'app/models/user.model';
import { fieldsMatch } from '../validators/fields-match.validator';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordComponent implements OnInit {
  passwordForm: FormGroup;
  @Output() readonly formSubmit = new EventEmitter<UserPasswordChange>();
  @Input() loading: boolean;

  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnInit() {
    this.passwordForm = this.formBuilder.group(
      {
        currentPassword: ['', Validators.required],
        newPassword: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: fieldsMatch('newPassword', 'confirmPassword'),
      },
    );
  }

  submitForm() {
    this.formSubmit.emit({
      currentPassword: this.passwordForm.controls['currentPassword'].value,
      newPassword: this.passwordForm.controls['newPassword'].value,
    });
  }
}
