import { ActionReducerMap, MetaReducer } from '@ngrx/store';

import * as fromSession from './session.reducer';
import * as fromBrand from './brand.reducer';
import * as fromSerie from './serie.reducer';
import * as fromModel from './model.reducer';
import * as fromPrintableData from './printable-data.reducer';

import { appConfig } from 'environments/environment';

export interface AppState {
  readonly session: fromSession.SessionState;
  readonly brand: fromBrand.State;
  readonly serie: fromSerie.State;
  readonly model: fromModel.State;
  readonly printableData: fromPrintableData.State;
}

export const reducers: ActionReducerMap<AppState> = {
  [fromSession.sessionKey]: fromSession.reducer,
  brand: fromBrand.reducer,
  serie: fromSerie.reducer,
  model: fromModel.reducer,
  printableData: fromPrintableData.reducer,
};

export const metaReducers: MetaReducer<AppState>[] = !appConfig.production ? [] : [];
