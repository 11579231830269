/// <reference types="w3c-web-usb" />
import { HttpBackend, HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, from, Observable, of, throwError } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import {
  PlotterBrandList,
  PlotterOutput,
  PrinterDetails,
  PrintFailQuery,
  PrintingList,
  PrintingQuery,
  printPiecesQuery,
  PrintResponse,
} from '../models/printing.model';

import { Store } from '@ngrx/store';
import { selectCredentials } from 'app/store/selector/session.selectors';
import { selectDaemonHost } from 'app/store/selector/user.selectors';
import { concatLatestFrom } from '@ngrx/operators';
import { isNil } from 'lodash';
import { updateUserConfiguration } from 'app/store/actions/user.actions';
import { NotificationService } from './notification.service';
import { NotificationType } from 'app/models/notification-types.const';

@Injectable()
export class PrintingService {
  private device: USBDevice;
  private deviceConfigValue: number;
  private usbInterface: USBInterface;

  route = {
    ids: 'ids',
    modelIds: 'model-ids', // needed
    adminIds: 'admin-ids',
    startDate: 'start-date', // needed
    endDate: 'end-date', // needed
    offset: 'offset',
    limit: 'limit', // needed
    exclude: 'exclude',
    next: 'next',
  };

  constructor(
    private readonly store: Store,
    private _http: HttpClient,
    private readonly httpBackend: HttpBackend,
    private readonly httpWithoutInterceptor: HttpClient,
    private readonly notificationService: NotificationService,
  ) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
  }

  onDisconnectDeviceWebUSB() {
    navigator.usb.ondisconnect = event => {
      if (event.device === this.device && !event.device.opened) {
        this.device = undefined;
        this.store.dispatch(
          updateUserConfiguration({
            partialUserConfiguration: {
              printer: null,
            },
          }),
        );
        this.notificationService.notify(NotificationType.DISCONNECT_DEVICE, 7000, event.device.productName);
        this.getConnectedDevices();
      }
    };
  }

  getAvailablePrintersWithDaemon(): Observable<PrinterDetails[]> {
    return this.store.select(selectDaemonHost).pipe(
      first(),
      switchMap((daemonHost: string) => {
        return this.httpWithoutInterceptor.get<PrinterDetails[]>(`http://${daemonHost}/printer-list`).pipe(
          catchError(error =>
            throwError(() => ({
              message: error.message,
              tracelog: JSON.stringify(error),
            })),
          ),
        );
      }),
    );
  }

  getPrintingList(query: PrintingQuery = {}): Observable<PrintingList> {
    const url =
      '/api/printings/list?' +
      Object.keys(query)
        .map(k => (Array.isArray(query[k]) ? query[k].map((value, index) => `${this.route[k]}[${index}]=${value}`).join('&') : `${this.route[k]}=${query[k]}`))
        .join('&');

    return this._http.get<{ data: PrintingList; statusCode: number }>(url).pipe(map(response => response.data));
  }

  getVendorList(): Observable<USBDeviceFilter[]> {
    const url = '/api/plotter-brand/list';

    return this._http.get<{ data: PlotterBrandList; statusCode: number }>(url).pipe(
      map(response => response.data.listing.map(ploter => ({ ...ploter, productId: parseInt(ploter.productId, 16), vendorId: parseInt(ploter.vendorId, 16) }))),
      catchError(() => of([])),
    );
  }

  printDataWithWebUSB(query?: printPiecesQuery): Observable<boolean> {
    const { printer, ...newQuery } = query;
    let printingId: number = null;

    return this._http.post('/api/printings/print', newQuery, { observe: 'response' }).pipe(
      switchMap((res: HttpResponse<{ data: PlotterOutput; statusCode: number }>) => {
        printingId = res.body.data.printingId;

        return from(this.sendInstructionsToPlotter(res.body.data.plotterOutput, printer)).pipe(map(() => true));
      }),
      catchError(error =>
        throwError(() => ({
          printingId: printingId,
          message: error.message,
          tracelog: JSON.stringify(error),
        })),
      ),
    );
  }

  printDataWithDaemon(query?: printPiecesQuery): Observable<boolean> {
    return this.store.select(selectCredentials).pipe(
      first(),
      concatLatestFrom(() => this.store.select(selectDaemonHost)),
      switchMap(([credentials, daemonHost]) => {
        return this.httpWithoutInterceptor
          .post<PrintResponse>(`http://${daemonHost}/proxy/printings/print`, query, { headers: { Authorization: `Bearer ${credentials.token}` } })
          .pipe(
            map(() => true),
            catchError(error =>
              throwError(() => ({
                message: error.message,
                tracelog: JSON.stringify(error),
              })),
            ),
          );
      }),
    );
  }

  testPrintingWithWebUsb(data: string, printerName: string): Observable<boolean> {
    return from(this.sendInstructionsToPlotter(data, printerName)).pipe(
      map(() => true),
      catchError(error =>
        throwError(() => ({
          message: error.message,
          tracelog: JSON.stringify(error),
        })),
      ),
    );
  }

  testPrintingWithDaemon(data: string, daemonHost: string, printerName: string): Observable<boolean> {
    return this.httpWithoutInterceptor.post<PrintResponse>(`http://${daemonHost}/print`, { data, printer: printerName }).pipe(
      map(() => true),
      catchError(error =>
        throwError(() => ({
          message: error.message,
          tracelog: JSON.stringify(error),
        })),
      ),
    );
  }

  postPrintFail(query?: PrintFailQuery): Observable<string> {
    const url = '/api/printings/print-fail';

    if (query.printingId) {
      return this._http
        .post(url, query?.message ? query : this.buildPrintFailedQuery(query as unknown as Error), { observe: 'response', responseType: 'text' })
        .pipe(map((res: HttpResponse<string>) => res.body));
    } else {
      //for test print fail
      return of('');
    }
  }

  private buildPrintFailedQuery(error: Error): PrintFailQuery {
    return {
      message: error.message,
      tracelog: error.stack,
    };
  }

  async sendInstructionsToPlotter(plotterOutput: string, printerName: string): Promise<USBOutTransferResult> {
    if ('usb' in navigator) {
      try {
        if (!this.device.opened) {
          const isClaimed = await this.checkPrinterClaimStatus(printerName);

          if (!isClaimed) {
            throw new Error('printer-unclaimed');
          }
        }

        const endPoint = this.getInterfaceEndPointNumber(this.usbInterface.alternate);

        return await this.device.transferOut(endPoint, new TextEncoder().encode(plotterOutput));
      } catch (error) {
        this.device?.close();
        throw new Error(error.message);
      }
    } else {
      throw new Error('webusb-not-supported');
    }
  }

  async checkPrinterClaimStatus(printerName: string): Promise<boolean> {
    if (isNil(printerName)) {
      return false;
    }

    try {
      this.device = (await navigator.usb.getDevices()).find(device => device.productName === printerName);

      await this.device?.open();

      this.setCurrentDeviceConfiguration(this.device);

      await this.device.selectConfiguration(this.deviceConfigValue);

      await this.device.claimInterface(this.usbInterface.interfaceNumber);

      return true;
    } catch (error) {
      return false;
    }
  }

  private setCurrentDeviceConfiguration(device: USBDevice): void {
    if (!device) {
      throw new Error('DEVICE_NOT_SET_ERROR');
    }

    for (const config of device.configurations) {
      const printerConfig = config.interfaces.find(iface =>
        iface.alternates.some(
          alt =>
            alt.interfaceClass === 7 && // printer specific class code
            alt.interfaceSubclass === 1 && // direct line controle specific subclass
            alt.interfaceProtocol === 2, // printer protocol
        ),
      );

      if (printerConfig) {
        this.deviceConfigValue = config.configurationValue;
        this.usbInterface = printerConfig;

        return;
      }
    }

    throw new Error('Cannot find compatible printer interface');
  }

  async getConnectedDevices(): Promise<PrinterDetails[]> {
    try {
      const vendorFilters = await firstValueFrom(this.getVendorList());
      const devices = await navigator.usb.getDevices();

      return await Promise.all(
        devices
          .filter(device => vendorFilters.some(filter => filter.vendorId === device.vendorId && filter.productId === device.productId))
          .map(async device => ({
            name: device.productName || 'Unknown Device',
            portName: `USB${device.vendorId}_${device.productId}`,
            claimed: await this.checkPrinterClaimStatus(device.productName),
          })),
      );
    } catch (error) {
      return [];
    }
  }

  async addDevicePermission(): Promise<PrinterDetails[]> {
    try {
      const vendorFilters = await firstValueFrom(this.getVendorList());
      await navigator.usb.requestDevice({ filters: vendorFilters });

      return await this.getConnectedDevices();
    } catch {
      return await this.getConnectedDevices();
    }
  }

  private getInterfaceEndPointNumber(deviceInterface: USBAlternateInterface): number {
    const endpoint = deviceInterface.endpoints.find(ep => ep.direction === 'out' && ep.type === 'bulk');

    if (!endpoint) {
      throw new Error(`Cannot find bulk out endpoint for the provided interface`);
    }

    return endpoint.endpointNumber;
  }
}
