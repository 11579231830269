import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ControlEventAction } from 'app/models/control-event-action.enum';
import { ControlEvent, ISelection } from 'app/models/fabric.model';
import { cloneDeep, isEmpty, isEqual, isNil } from 'lodash';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { victorialToLinearMeasure, linearMeasureToVictorial } from 'app/utils/fabric.utils';

import { Piece } from '../models/printable-data.model';
import { AVAILABLE_ZOOM } from 'app/constants/available-zoom.const';

@Component({
  selector: 'pieces-menu',
  templateUrl: './pieces-menu.component.html',
  styleUrls: ['./pieces-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PiecesMenuComponent implements OnChanges {
  @Input() loadedPieces: Piece[];
  @Input() currentSelection: ISelection;
  @Input() currentZoom: number;

  @Output() readonly piecesRemove = new EventEmitter();
  @Output() readonly updateZoom = new EventEmitter<number>();
  @Output() readonly pieceOperations = new EventEmitter<ControlEvent>();

  currentControlForm: FormGroup;
  zoomForm: FormControl;
  zoomValues = AVAILABLE_ZOOM;

  ngOnChanges(changes: SimpleChanges) {
    if (!this.currentControlForm) {
      this.buildForm();
    }

    const currentSelectionChanges: SimpleChange = changes.currentSelection;
    if (!isEqual(currentSelectionChanges?.currentValue, this.currentControlForm.value)) {
      this.updateCurrentControlForm(currentSelectionChanges?.currentValue);
    }

    const loadedPiecesChange: SimpleChange = changes.loadedPieces;

    if (!isNil(loadedPiecesChange)) {
      !isEmpty(loadedPiecesChange.currentValue) ? this.currentControlForm.get('labels').enable() : this.currentControlForm.get('labels').disable();
    }

    const currentZoomChange: SimpleChange = changes.currentZoom;
    if (currentZoomChange?.currentValue) {
      this.zoomForm.setValue(currentZoomChange.currentValue);
    }
  }

  private buildForm() {
    this.zoomForm = new FormControl();
    this.currentControlForm = new FormGroup({
      labels: new FormControl({ value: null, disabled: true }),
      x: new FormControl({ value: null, disabled: true }, [Validators.min(0)]),
      y: new FormControl({ value: null, disabled: true }),
      scale: new FormControl({ value: null, disabled: true }, [Validators.min(90), Validators.max(110)]),
      rotation: new FormControl({ value: null, disabled: true }, [Validators.min(0), Validators.max(359)]),
    });
    this.registerListners();
  }

  private registerListners() {
    this.currentControlForm.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter((value: ISelection) => !isNil(value) && this.currentControlForm.valid),
        map((value: ISelection) => this.getEventToEmit(value)),
        filter((eventToEmit: ControlEvent) => !isEmpty(eventToEmit)),
      )
      .subscribe((eventToEmit: ControlEvent) => {
        this.pieceOperations.emit(eventToEmit);
      });
  }

  private getEventToEmit(value: ISelection): ControlEvent {
    if (isEmpty(this.currentSelection)) {
      return;
    }

    let controlEvent: ControlEvent;
    const convertedX = linearMeasureToVictorial(value.x);
    if (convertedX !== this.currentSelection.x) {
      controlEvent = {
        action: ControlEventAction.MoveX,
        absolute: true,
        param: convertedX,
      };
    }

    const convertedY = linearMeasureToVictorial(value.y);
    if (convertedY !== this.currentSelection.y) {
      controlEvent = {
        action: ControlEventAction.MoveY,
        absolute: true,
        param: convertedY,
      };
    }
    if (value.scale !== this.currentSelection.scale) {
      controlEvent = {
        action: ControlEventAction.Scale,
        absolute: true,
        param: value.scale,
      };
    }
    if (value.rotation !== this.currentSelection.rotation) {
      controlEvent = {
        action: ControlEventAction.Rotate,
        absolute: true,
        param: value.rotation,
      };
    }

    return controlEvent;
  }

  private updateCurrentControlForm(newPiecesSelection: ISelection) {
    if (isNil(newPiecesSelection)) {
      this.currentControlForm.reset();
      this.currentControlForm.disable();

      if (!isEmpty(this.loadedPieces)) {
        this.currentControlForm.get('labels').enable();
      }
    } else {
      const selectionWithConvertedXY = cloneDeep(newPiecesSelection);
      selectionWithConvertedXY.x = victorialToLinearMeasure(selectionWithConvertedXY.x);
      selectionWithConvertedXY.y = victorialToLinearMeasure(selectionWithConvertedXY.y);
      this.currentControlForm.patchValue(selectionWithConvertedXY);
      this.currentControlForm.enable();
    }
    this.currentControlForm.updateValueAndValidity();
  }

  removePieces() {
    this.pieceOperations.emit({
      action: ControlEventAction.Remove,
    });
  }

  selectPieces(event: MatSelectChange) {
    this.pieceOperations.emit({
      action: ControlEventAction.Select,
      absolute: true,
      param: event.value,
    });
  }

  onUpdateZoom(zoomEvent: MatSelectChange) {
    this.updateZoom.emit(zoomEvent.value);
  }

  selectOrDeselectPieces() {
    const value = isEmpty(this.currentSelection?.labels) ? this.loadedPieces.map(piece => piece.label) : [];
    this.currentControlForm.get('labels').setValue(value);

    this.pieceOperations.emit({
      action: ControlEventAction.Select,
      absolute: true,
      param: value,
    });
  }

  rotate(param: number) {
    this.pieceOperations.emit({
      action: ControlEventAction.Rotate,
      absolute: false,
      param,
    });
  }

  checkValue(control: AbstractControl) {
    if (control.errors) {
      if (control.errors['min']) {
        control.setValue(control.errors['min'].min);
      } else if (control.errors['max']) {
        control.setValue(control.errors['max'].max);
      }
    }
  }

  organizePieces() {
    this.pieceOperations.emit({
      action: ControlEventAction.Organize,
    });
  }
}
