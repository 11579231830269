import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { appConfig } from 'environments/environment';
import { Model } from '../models/model.model';

@Component({
  selector: 'model-preview-dialog',
  templateUrl: './model-preview-dialog.component.html',
  styleUrls: ['./model-preview-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelPreviewDialogComponent implements OnInit {
  url: string;
  loading = false;

  constructor(
    protected dialogRef: MatDialogRef<ModelPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public model: Model,
  ) {}

  ngOnInit() {
    this.url = `${appConfig.apiHost}/uploads/`;
  }

  loadingModel(event) {
    if (event && event.type === 'load') {
      this.loading = true;
    }
  }
}
