import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PrintingList } from '../models/printing.model';

@Component({
  selector: 'printing-log-list',
  templateUrl: './printing-log-list.component.html',
  styleUrls: ['./printing-log-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintingLogListComponent {
  @Input() printingList: PrintingList;
  offset = 0;
  handlePages(event) {
    this.offset = event.offset;
  }
}
