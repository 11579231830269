import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { startAppInitialization } from 'app/store/actions/session.actions';
import { selectIsAppInitialized } from 'app/store/selector/session.selectors';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  constructor(private readonly store: Store) {}

  /** Load */
  async load(): Promise<void> {
    return new Promise(resolve => {
      this.store.dispatch(startAppInitialization());
      this.store
        .select(selectIsAppInitialized)
        .pipe(filter(finishedInit => finishedInit))
        .subscribe(() => resolve());
    });
  }
}
