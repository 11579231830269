@if (printingList) {
  <div class="table-wrapper">
    <ngx-datatable
      class="material"
      [rows]="printingList.listing"
      [footerHeight]="30"
      [columnMode]="'force'"
      [rowHeight]="'auto'"
      (page)="handlePages($event)"
      [limit]="4"
      [count]="printingList.total"
      [offset]="offset"
    >
      <ngx-datatable-column name="Model" [sortable]="false" [draggable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.printableData.model.serie.brand.name }}/{{ row.printableData.model.serie.name }}/{{ row.printableData.model.name }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [name]="'printed-area' | translate" [maxWidth]="150" [sortable]="false" [draggable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span [matTooltip]="row.printedArea">{{ row.printedArea | floor: 2 }}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [headerClass]="'pieces-cell'" [cellClass]="'pieces-cell'" [maxWidth]="200" name="Date" [sortable]="false" [draggable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span [matTooltip]="row.createdAt | date: 'dd/MM/yyyy, HH:mm'">{{ row.createdAt | date: 'dd/MM, HH:mm' }}</span>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
}
@if (!printingList) {
  <div class="no-table-content">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
}
