import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Banner, User, UserConfiguration, UserInfo, UserPasswordChange } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private _http: HttpClient) {}

  getUser(userId: number): Observable<User> {
    return this._http.get('/api/user/' + userId, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: User; statusCode: number }>) => {
        return res.body;
      }),
      map(body => body.data),
    );
  }

  updateUser(userData: UserInfo): Observable<User> {
    return this._http.put<{ data: User; status: number }>(`/api/user`, userData).pipe(map(response => response.data));
  }

  changePassword(passwords: UserPasswordChange): Observable<User> {
    return this._http.put<{ data: User; status: number }>(`/api/user/set-password`, passwords).pipe(map(response => response.data));
  }

  getUserConfigurationFromStorage(): UserConfiguration {
    return JSON.parse(localStorage.getItem('configuration')) || {};
  }

  setUserConfigurationToStorage(config: UserConfiguration) {
    localStorage.setItem('configuration', JSON.stringify(config));
  }

  getBanner(): Observable<Banner> {
    return this._http.get('/api/banner', { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: Banner; statusCode: number }>) => {
        return res.body;
      }),
      map(body => body.data),
    );
  }
}
