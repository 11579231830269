import { Directive, DoCheck, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[ceilFormat]',
})
export class InputCeilDirective implements DoCheck {
  @Input() value: number;
  el: HTMLInputElement;

  constructor(private elementRef: ElementRef, protected renderer: Renderer2) {
    this.el = this.elementRef.nativeElement;
  }

  ngDoCheck() {
    this.renderer.setProperty(this.el, 'value', this.value);
  }
}
