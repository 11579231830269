import { createAction, props } from '@ngrx/store';
import { Dictionary } from 'lodash';

import { CurrentCanvasState, Piece, PrintableData } from 'app/models/printable-data.model';
import { ControlEvent, RulerConfig } from 'app/models/fabric.model';
import { GroupedEntity } from 'app/models/entities.model';

export const loadPrintableDataCompleted = createAction('[PrintableData/API] Load Printable Data Completed', props<{ printableData: PrintableData[] }>());

export const loadGroupedPrintableDataCompleted = createAction(
  '[PrintableData/API] Load Grouped Printable Data Completed',
  props<{ groupedPrintableData: GroupedEntity<Dictionary<number[]>> }>(),
);

export const loadPrintableDataError = createAction('[PrintableData/API] Load Printable Data Error', props<{ error: unknown }>());

export const clearPrintableData = createAction('[PrintableData/API] Clear Printable Data');

export const setSelectedPrintableData = createAction('[Dashboard] Set current printable data', props<{ selectedPrintableDataId: number }>());

export const setInitialPieces = createAction('[Dashboard] Set Initial Pieces', props<{ initialPieces: Piece[] }>());

export const loadPieces = createAction('[Dashboard] Load Pieces', props<{ piecesToLoad: Piece[] }>());
export const loadPiecesFromPreview = createAction('[Dashboard] Load Pieces From Preview', props<{ piecesToLoad: Piece[] }>());
export const syncPiecesFromCanvas = createAction('[Dashboard] Sync Pieces From Canvas', props<{ piecesToSync: string[] }>());

export const stagePieces = createAction('[Dashboard] Stage Pieces', props<{ piecesToStage: Piece[] }>());
export const updateCurrentCanvas = createAction('[Dashboard] Update Current Canvas', props<{ currentCanvas: string }>());
export const updateRulerConfig = createAction('[Dashboard] Update Ruler Config', props<{ rulerConfig: RulerConfig }>());
export const updateCurrentSelection = createAction('[Dashboard] Update Current Selection', props<{ currentSelection: Piece[] }>());
export const updateCurrentControlEvent = createAction('[Dashboard] Update Current Control Event', props<{ controlEvent: ControlEvent }>());
export const updateCurrentCanvasState = createAction('[Dashboard] Update Current Canvas State', props<{ currentCanvasState: Partial<CurrentCanvasState> }>());
