import { Component, Input } from '@angular/core';
import { LoggingList } from 'app/models/logging.model';

@Component({
  selector: 'credit-log-list',
  templateUrl: './credit-log-list.component.html',
})
export class CreditLogListComponent {
  @Input() logginList: LoggingList;
  offset = 0;
  handlePages(event) {
    this.offset = event.offset;
  }
}
