import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { EntitiesModel } from 'app/models/entities.model';
import { Model } from 'app/models/model.model';

export const ModelApiActions = createActionGroup({
  source: 'Model Api',
  events: {
    'Load Models Completed': props<{ models: Model[] }>(),
    'Load Grouped Models Completed': props<{ groupedModels: EntitiesModel['groupedModels'] }>(),
    'Clear Models': emptyProps(),
  },
});
