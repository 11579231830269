import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { fieldsMatch } from '../validators/fields-match.validator';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  @Output() readonly formSubmit = new EventEmitter<{ newPassword: string; hash: string }>();

  ngOnInit() {
    this.buildForm();

    this.resetPasswordForm.valueChanges.subscribe(form => {
      this.formSubmit.emit({ newPassword: form.newPassword, hash: form.hash });
    });
  }

  buildForm() {
    this.resetPasswordForm = new FormGroup(
      {
        newPassword: new FormControl(null, Validators.required),
        confirmPassword: new FormControl(null, Validators.required),
        hash: new FormControl(null, Validators.required),
      },
      fieldsMatch('newPassword', 'confirmPassword'),
    );
  }
}
