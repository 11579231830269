<h2 mat-dialog-title>{{ 'assistance-tile' | translate }}</h2>
<mat-dialog-content>
  <div>
    <mat-tab-group [selectedIndex]="step" (selectedTabChange)="disableTabs($event)">
      <mat-tab [disabled]="tabs[0]">
        <ng-template mat-tab-label>
          <span>{{ 'brands' | translate }}</span>
        </ng-template>
        <brand-selection [brandList]="brands$ | async" (brandSubmit)="getBrandSeries($event)"> </brand-selection>
      </mat-tab>

      <mat-tab [disabled]="tabs[1]">
        <ng-template mat-tab-label>
          <span>{{ 'series' | translate }}</span>
        </ng-template>
        <serie-selection [serieList]="series$ | async" (serieSubmit)="getSerieModels($event)"></serie-selection>
      </mat-tab>

      <mat-tab label="Models" [disabled]="tabs[2]">
        <ng-template mat-tab-label>
          <span>{{ 'models' | translate }}</span>
        </ng-template>
        <model-selection [loadingModelDetails]="loadingModelDetails | async" [modelList]="models$ | async" (modelSubmit)="getModel($event)"></model-selection>
      </mat-tab>

      <mat-tab [label]="'detail' | translate" [disabled]="tabs[3]">
        @if (selectedModel$ | async; as model) {
          <model-preview
            [model]="model"
            [isAllowedWindow]="isAllowedWindow$ | async"
            [isAllowedPPF]="isAllowedPPF$ | async"
            (typeChange)="onTypeChange($event)"
          ></model-preview>
        }
      </mat-tab>
    </mat-tab-group>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  @if (step === 3) {
    <button mat-raised-button color="accent" [disabled]="(hideFilterBtn$ | async) === false" (click)="filterPreview()">
      {{ 'filter' | translate }}
    </button>
  }
  <button mat-raised-button color="warn" (click)="leaveDialog()">{{ 'cancel' | translate }}</button>
</mat-dialog-actions>
