import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Brand } from 'app/models/brand.model';
import { EntitiesModel } from 'app/models/entities.model';

export const BrandApiActions = createActionGroup({
  source: 'Brand API',
  events: {
    'Load Brands Completed': props<{ brands: Brand[] }>(),
    'Load Grouped Brands Completed': props<{ groupedBrands: EntitiesModel['groupedBrands'] }>(),
    'Clear Brands': emptyProps(),
  },
});
