<form [formGroup]="currentControlForm" novalidate>
  <mat-form-field class="selected-pieces" [subscriptSizing]="'dynamic'">
    <mat-label translate="selected-pieces"></mat-label>
    <mat-select multiple formControlName="labels" (selectionChange)="selectPieces($event)">
      @for (piece of loadedPieces; track piece) {
        <mat-option [value]="piece.label">
          {{ piece.label }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <button mat-icon-button type="button" (click)="selectOrDeselectPieces()" [disabled]="loadedPieces?.length <= 0">
    @if (!currentSelection || currentSelection?.labels?.length === 0) {
      <mat-icon [matTooltip]="'select-all' | translate" svgIcon="ic_select_all_24px"></mat-icon>
    }
    @if (currentSelection?.labels?.length > 0) {
      <mat-icon [matTooltip]="'deselect-all' | translate" svgIcon="ic_clear_24px"></mat-icon>
    }
  </button>

  <button mat-icon-button type="button" color="primary" (click)="removePieces()" [disabled]="currentSelection?.labels?.length <= 0">
    <mat-icon svgIcon="ic_delete_24px"></mat-icon>
  </button>

  <mat-form-field class="position" [subscriptSizing]="'dynamic'">
    <mat-label>X</mat-label>
    <input matInput type="number" formControlName="x" [value]="currentControlForm.controls['x'].value | ceil: 2" min="0" ceilFormat />
  </mat-form-field>

  <mat-form-field class="position" [subscriptSizing]="'dynamic'">
    <mat-label>Y</mat-label>
    <input matInput type="number" formControlName="y" [value]="currentControlForm.controls['y'].value | ceil: 2" ceilFormat />
  </mat-form-field>

  <mat-form-field class="zoom" [subscriptSizing]="'dynamic'">
    <mat-label>{{ 'scale' | translate }}%</mat-label>
    <input
      matInput
      type="number"
      min="90"
      max="110"
      formControlName="scale"
      [value]="currentControlForm.controls['scale'].value | ceil: 2"
      (blur)="checkValue(currentControlForm.controls['scale'])"
    />
  </mat-form-field>

  <button mat-icon-button type="button" (click)="rotate(-90)" [disabled]="currentSelection?.labels?.length <= 0">
    <mat-icon svgIcon="ic_rotate_left_24px"></mat-icon>
  </button>

  <mat-form-field class="zoom" [subscriptSizing]="'dynamic'">
    <mat-label translate="rotation"></mat-label>
    <input
      matInput
      type="number"
      min="0"
      max="359"
      formControlName="rotation"
      [value]="currentControlForm.controls['rotation'].value | ceil: 2"
      (blur)="checkValue(currentControlForm.controls['rotation'])"
    />
  </mat-form-field>

  <button mat-icon-button type="button" (click)="rotate(90)" [disabled]="currentSelection?.labels?.length <= 0">
    <mat-icon svgIcon="ic_rotate_right_24px"></mat-icon>
  </button>

  <mat-form-field class="zoom" [subscriptSizing]="'dynamic'">
    <mat-label translate="zoom"></mat-label>
    <mat-select (selectionChange)="onUpdateZoom($event)" [formControl]="zoomForm">
      @for (z of zoomValues; track z) {
        <mat-option [value]="z.value">{{ z.label }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  <button mat-icon-button type="button" [disabled]="loadedPieces.length <= 0" (click)="organizePieces()">
    <mat-icon [matTooltip]="'align-pieces' | translate" svgIcon="ic_border_top_24px"></mat-icon>
  </button>
</form>
