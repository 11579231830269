import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Credentials, LoginData } from 'app/models/user.model';
import { map } from 'rxjs/operators';
import { isEmpty } from 'lodash';

@Injectable()
export class AuthService {
  noAuthEndPoint = ['/api/auth/user/login', '/api/auth/request-reset-password', '/api/auth/reset-password'];

  constructor(private _http: HttpClient) {}

  checkCredentials(): Credentials {
    const credentials: Credentials = JSON.parse(localStorage.getItem('credentials')) || JSON.parse(sessionStorage.getItem('credentials'));

    return credentials;
  }

  getLoginDataFromStorage(): LoginData {
    const loginData = localStorage.getItem('loginData');

    return !isEmpty(loginData) ? JSON.parse(loginData) : undefined;
  }

  setCredentialsToStorage(credentials: Credentials, loginData: LoginData) {
    const stringifiedCredentials = JSON.stringify(credentials);
    if (loginData.rememberMe) {
      localStorage.setItem('loginData', JSON.stringify(loginData));
    }
    localStorage.setItem('credentials', stringifiedCredentials);
  }

  clearStorage() {
    localStorage.removeItem('credentials');
  }

  login(data: LoginData): Observable<Credentials> {
    const userData = { email: data.email, password: data.password };

    return this._http.post<{ data: Credentials }>('/api/auth/user/login', userData).pipe(map(response => response.data));
  }

  logout(): Observable<void> {
    return this._http.post<void>('/api/user/logout', null);
  }

  requestPasswordReset(data: { email: string; role: string }): Observable<void> {
    return this._http.post<void>('/api/auth/request-reset-password', data);
  }

  resetPassword(data: { hash: string; newPassword: string }): Observable<void> {
    return this._http.put<void>('/api/auth/reset-password', data);
  }
}
