import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { EntitiesModel } from 'app/models/entities.model';
import { Serie } from 'app/models/serie.model';

export const SerieApiActions = createActionGroup({
  source: 'Serie API',
  events: {
    'Load Series Completed': props<{ series: Serie[] }>(),
    'Load Grouped Series Completed': props<{ groupedSeries: EntitiesModel['groupedSeries'] }>(),
    'Clear Series': emptyProps(),
  },
});
