export enum ControlEventAction {
  MoveX,
  MoveY,
  Rotate,
  Scale,
  Viewport,
  Select,
  Remove,
  Organize,
}
