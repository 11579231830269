import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'search-filter',
  templateUrl: './search-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFilterComponent implements OnInit {
  filterForm: FormGroup;
  @Input() type: 'brand' | 'serie' | 'model';
  @Output() readonly searchText = new EventEmitter<string>();

  ngOnInit() {
    this.filterForm = new FormGroup({ searchText: new FormControl(null) });

    this.filterForm.controls['searchText'].valueChanges.pipe(debounceTime(500)).subscribe(searchText => {
      this.searchText.emit(searchText);
    });
  }
}
