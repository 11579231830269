import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'request-reset-password',
  templateUrl: './request-reset-password.component.html',
  styleUrls: ['./request-reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestResetPasswordComponent implements OnInit {
  requestResetPasswordForm: FormGroup;

  ngOnInit() {
    this.requestResetPasswordForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
  }
}
