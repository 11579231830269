import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { ModelApiActions } from '../actions/model.actions';
import { Model } from 'app/models/model.model';
import { EntitiesModel } from 'app/models/entities.model';

export const modelFeatureKey = 'model';

export interface State extends EntityState<Model> {
  // additional entities state properties
  groupedModels?: EntitiesModel['groupedModels'];
}

export const adapter: EntityAdapter<Model> = createEntityAdapter<Model>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(ModelApiActions.loadModelsCompleted, (state, action): State => adapter.setAll(action.models, state)),
  on(ModelApiActions.loadGroupedModelsCompleted, (state, action): State => ({ ...state, groupedModels: action.groupedModels })),
  on(ModelApiActions.clearModels, (state): State => adapter.removeAll(state)),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
