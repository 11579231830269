<div class="header-container">
  @if (banner$ | async; as banner) {
    <div class="banner">
      <img [src]="banner.picture" />
    </div>
  }
  <mat-toolbar>
    <div class="top-menu">
      <pieces-menu
        [ngClass]="{ 'is-tablet-device': isTabletMode$ | async }"
        [loadedPieces]="loadedPieces$ | async"
        [currentZoom]="currentZoom$ | async"
        [currentSelection]="currentSelection$ | async"
        (pieceOperations)="updateCurrentPieceFabric($event)"
        (updateZoom)="onUpdateZoom($event)"
      ></pieces-menu>
      <!-- <plotter-menu
        *ngIf="(isTabletMode$ | async) === false"
        [filmWidth]="filmWidth$ | async"
        [loadedPieces]="loadedPieces$ | async"
        [currentPlotter]="currentPlotter$ | async"
        [currentPrinter]="currentPrinter$ | async"
        [plotterList]="plottersList"
        [printerList]="printerList$ | async"
        [isDaemonUp]="isDaemonUp$ | async"
        [currentDaemon]="daemonHostList$ | async"
        (selectDaemonHost)="updateDaemonHost($event)"
        (testPrint)="onTestPrint($event)"
        (selectPlotter)="updatePlotter($event)"
        (changeFilmWidh)="updateFilmWidth($event)"
        (selectPrinter)="onSelectPrinter($event)"
      ></plotter-menu> -->
    </div>
    <div class="user-menu-section">
      <div>
        <user-menu-smart></user-menu-smart>
        <button class="open-menu-button" *ngIf="isTabletMode$ | async" mat-icon-button (click)="toggleMenu(rightSidenav)">
          <mat-icon [matTooltip]="'Open the Menu' | translate" svgIcon="ic_list_24px"></mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>
</div>
<div #ngeditor class="editor-container">
  <mat-sidenav-container>
    <mat-sidenav #rightSidenav mode="over" position="end">
      <div #rightmenu class="right-menu" [ngClass]="{ 'right-menu-tablet': isTabletMode$ | async }">
        <div class="reset-button">
          @if ((currentForm$ | async) !== undefined) {
            <button mat-raised-button color="accent" (click)="reset()">{{ 'reset' | translate }}</button>
          }
        </div>

        <filter-menu-smart
          #filterMenuSmartRef
          [currentForm]="currentForm$ | async"
          [allowedPrintedWindows]="allowedPrintedWindows$ | async"
          [allowedPrintedPPF]="allowedPrintedPPF$ | async"
          (filter)="filter($event)"
          (openAssistanceDialog)="openAssistanceDialog()"
        ></filter-menu-smart>
        <max-try
          [allowedPrintedWindows]="allowedPrintedWindows$ | async"
          [allowedPrintedPPF]="allowedPrintedPPF$ | async"
          [loadedPieces]="loadedPieces$ | async"
          [currentForm]="currentForm$ | async"
          [selectedPrinter]="currentPrinter$ | async"
          [printInProgress]="printInProgress$ | async"
          (startCut)="startCutting()"
        ></max-try>
        @if (currentForm$ | async) {
          <printable-data
            [stagedPieces]="stagedPieces$ | async"
            (loadPrintableData)="load($event)"
            (openPrintableDialog)="openPrintableDataDialog()"
          ></printable-data>
        }
      </div>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="ng-editor">
        <div id="corner"></div>
        <fabric-canvas
          id="fabric-canvas"
          #canvasref
          [config]="rulerConfig$ | async"
          [controlEvent]="currentControlEvent$ | async"
          [loadPieces]="loadedPieces$ | async"
          [currentZoom]="currentZoom$ | async"
          [currentCanvas]="currentCanvas$ | async"
          [filmWidth]="filmWidth$ | async"
          (syncFilmWidth)="updateFilmWidth($event)"
          (syncLoadedPieces)="syncLoadedPiecesFromCanvas($event)"
          (updateCurrentCanvasState)="onUpdateCurrentCanvasState($event)"
          (updateViewport)="updateViewport($event)"
        >
        </fabric-canvas>
        <ruler-x id="ruler-x" [config]="rulerConfig$ | async"></ruler-x>
        <ruler-y id="ruler-y" [config]="rulerConfig$ | async"></ruler-y>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <div *ngIf="(isTabletMode$ | async) === false" #rightmenu class="right-menu">
    <div class="reset-button">
      @if ((currentForm$ | async) !== undefined) {
        <button mat-raised-button color="accent" (click)="reset()">{{ 'reset' | translate }}</button>
      }
    </div>

    <filter-menu-smart
      #filterMenuSmartRef
      [currentForm]="currentForm$ | async"
      [allowedPrintedWindows]="allowedPrintedWindows$ | async"
      [allowedPrintedPPF]="allowedPrintedPPF$ | async"
      (filter)="filter($event)"
      (openAssistanceDialog)="openAssistanceDialog()"
    ></filter-menu-smart>
    <max-try
      [allowedPrintedWindows]="allowedPrintedWindows$ | async"
      [allowedPrintedPPF]="allowedPrintedPPF$ | async"
      [loadedPieces]="loadedPieces$ | async"
      [currentForm]="currentForm$ | async"
      [selectedPrinter]="currentPrinter$ | async"
      [printInProgress]="printInProgress$ | async"
      (startCut)="startCutting()"
    ></max-try>
    @if (currentForm$ | async) {
      <printable-data
        [stagedPieces]="stagedPieces$ | async"
        (loadPrintableData)="load($event)"
        (openPrintableDialog)="openPrintableDataDialog()"
      ></printable-data>
    }

    <div class="install-button">
      @if (this.pwaService.canInstall$ | async) {
        <button color="primary" mat-raised-button (click)="installPwa()">
          <strong>{{ 'install-now' | translate }}</strong>
        </button>
      }

      @if (this.pwaService.isIOS) {
        <p>
          <strong>{{ 'install-app' | translate }}</strong>
        </p>
      }
    </div>
  </div>
</div>
