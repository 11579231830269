/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';

import { RulerConfig } from 'app/models/fabric.model';
import * as niceticks from 'nice-ticks';
@Component({
  selector: 'ruler-x',
  templateUrl: './ruler-x.component.html',
  styleUrls: ['./ruler-x.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RulerXComponent {
  @ViewChild('ruler_x', { static: true })
  ruler_x: ElementRef;
  @Input() set config(config: RulerConfig) {
    this.drawRulerX(this.ruler_x.nativeElement, config);
  }
  @Input() mirror = false;
  constructor(private el: ElementRef) {}
  drawRulerX(ruler_x: HTMLCanvasElement, config: RulerConfig) {
    ruler_x.width = (<HTMLElement>this.el.nativeElement).offsetWidth;
    ruler_x.height = (<HTMLElement>this.el.nativeElement).offsetHeight;
    // models are 72dpi. meaning 72 points = 1 inch
    // 1 inch = 2.54 CM
    // multiply by this ratio to get the CM
    const vectorToCmRatio = 2.54 / 72;
    const context = ruler_x.getContext('2d');
    context!.font = '10px Arial';
    context.lineWidth = 1;
    context!.clearRect(0, 0, ruler_x.width, ruler_x.height);
    context!.beginPath();
    let ticks = [];
    const minScalar = config.viewport.minX;
    const maxScalar = config.viewport.maxX;
    const minCm = minScalar * vectorToCmRatio;
    const maxCm = maxScalar * vectorToCmRatio;
    // we need to find the corresponding pixel positions based on the viewport
    // calculating the difference allows us to use a scaling based on proportion
    // where the full width gives the max scalar position and from there calculate any position
    const deltaCm = maxCm - minCm;
    // the new ruler tryies to find corrrect nice ticks based on the magnitude of the current range
    // they are displayed with labels
    // X width is generally bigger than Y height so we split it more on a baseline of 15
    const TickSplittingNumber = Math.ceil((config.xWidth / config.yHeight) * 15);
    ticks = niceticks.default(minCm, maxCm, TickSplittingNumber);
    const bigTicks = ticks.map(tick => {
      // minCm here is used to represent the offset
      const position = ((tick - minCm) * ruler_x.width) / deltaCm;

      return { label: tick, position };
    });
    bigTicks.map(tick => this.drawTicks(context, tick.label, tick.position, ruler_x.height));
    const smallticks = [];
    // then each interval between big ticks is split into 10 little ticks for better readability
    // this allows a nice ruler instead of looking for price intervals that are round.
    // zooming more should proc the big ticks again to find new values
    for (let i = 0; i < ticks.length - 1; i++) {
      smallticks.push(...niceticks.default(ticks[i], ticks[i + 1], 10));
    }
    smallticks.map(tick => this.drawTicks(context, null, ((tick - minCm) * ruler_x.width) / deltaCm, ruler_x.height));
  }

  drawTicks(context: CanvasRenderingContext2D, label: string = null, positionpixel, height) {
    const isLabel = label !== null;
    context!.beginPath();
    if (this.mirror) {
      context!.moveTo(positionpixel, 0);
      context!.lineTo(positionpixel, isLabel ? height : height / 4);
      if (isLabel) {
        context!.fillText(label, positionpixel + 1.5, 20);
      }
      context!.stroke();
    } else {
      context!.moveTo(positionpixel, height);
      context!.lineTo(positionpixel, isLabel ? 0 : height - height / 4);
      if (isLabel) {
        context!.fillText(label, positionpixel + 1.5, 10);
      }
      context!.stroke();
    }
  }
}
