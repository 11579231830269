import { Store } from '@ngrx/store';
import { ReplaySubject, Observable, tap } from 'rxjs';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { Brand } from '../models/brand.model';
import { Model } from '../models/model.model';
import { PieceType, PrintableData } from '../models/printable-data.model';
import { Serie } from '../models/serie.model';
import { selectIsAllowedPrintedPPF, selectIsAllowedPrintedWindows } from 'app/store/selector/user.selectors';

import * as fromEntity from 'app/store/selector/entity.selectors';

@Component({
  selector: 'assistance-dialog',
  templateUrl: './assistance-dialog.component.html',
  styleUrls: ['./assistance-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssistanceDialogComponent implements OnInit {
  step = 0;
  tabs = [false, true, true, true];
  type: PieceType;
  loadingSeries = new ReplaySubject<boolean>(1);
  loadingModels = new ReplaySubject<boolean>(1);
  loadingModelDetails = new ReplaySubject<boolean>(1);
  brandName: string;
  serieName: string;
  modelName: string;
  loadingImages = true;
  pieceType = PieceType;

  brands$: Observable<Brand[]>;
  series$: Observable<Serie[]>;
  models$: Observable<Model[]>;
  selectedModel$: Observable<ExtendedModel>;
  selectedModel: ExtendedModel;
  selectedPrintableData: PrintableData;

  hideFilterBtn$: Observable<boolean>;
  isAllowedWindow$: Observable<boolean>;
  isAllowedPPF$: Observable<boolean>;

  constructor(
    private readonly dialogRef: MatDialogRef<AssistanceDialogComponent>,
    private readonly store: Store,
  ) {}

  ngOnInit() {
    this.brands$ = this.store.select(fromEntity.selectAllBrands);
    this.isAllowedWindow$ = this.store.select(selectIsAllowedPrintedWindows);
    this.isAllowedPPF$ = this.store.select(selectIsAllowedPrintedPPF);
  }

  disableTabs(event: MatTabChangeEvent) {
    this.step = event.index;
    this.tabs.forEach((m, i) => {
      if (i > event.index) {
        this.tabs[i] = true;
      }
    });
  }

  getBrandSeries(brandId: number) {
    this.series$ = this.store.select(fromEntity.selectSeriesByBrandId({ id: brandId, type: PieceType.ALL })).pipe(
      tap(() => {
        this.step = 1;
        this.tabs[1] = false;
      }),
    );
  }

  getSerieModels(serieId: number) {
    this.models$ = this.store.select(fromEntity.selectModelsBySerieId({ id: serieId, type: PieceType.ALL })).pipe(
      tap(() => {
        this.step = 2;
        this.tabs[2] = false;
      }),
    );
  }

  getModel(model: Model) {
    this.selectedModel$ = this.store.select(fromEntity.selectModelWithPrintableData({ modelId: model.id })).pipe(
      tap(model => {
        this.selectedModel = model;
        this.step = 3;
        this.tabs[3] = false;
      }),
    );
  }

  leaveDialog() {
    this.dialogRef.close(null);
  }

  onTypeChange(printableData: PrintableData) {
    this.selectedPrintableData = printableData;
    this.hideFilterBtn$ = this.selectedPrintableData.type === PieceType.WINDOW ? this.isAllowedWindow$ : this.isAllowedPPF$;
  }

  filterPreview() {
    this.dialogRef.close(this.selectedPrintableData);
  }
}

export type ExtendedModel = {
  picture: string;
  description: string;
  name: string;
  printableData: PrintableData[];
};
