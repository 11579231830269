import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { appConfig } from 'environments/environment';
import { cloneDeep, isEmpty, isEqual, isNil, partition, remove } from 'lodash';
import { Subject, forkJoin, from, fromEvent, throttleTime } from 'rxjs';
import { distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';

import { AVAILABLE_ZOOM } from 'app/constants/available-zoom.const';
import { ControlEventAction } from 'app/models/control-event-action.enum';
import { ControlEvent, ISelection, IViewPort, RulerConfig, fabricLine, fabricWithId } from 'app/models/fabric.model';
import { CurrentCanvasState, Piece } from 'app/models/printable-data.model';
import { fabricTriangle } from '../models/fabric.model';
import { isRuler, linearMeasureToVictorial, posCursorMap, rotateIcon, victorialToLinearMeasure } from '../utils/fabric.utils';
import * as fabric from 'fabric';

@Component({
  selector: 'fabric-canvas',
  templateUrl: './fabric-canvas.component.html',
  styleUrls: ['./fabric-canvas.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FabricCanvasComponent implements OnChanges, OnDestroy {
  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.registerScrollKeysBinding(event);
  }

  @ViewChild('canvas') canvasElement: ElementRef;
  @Output() readonly updateCurrentCanvasState = new EventEmitter<Partial<CurrentCanvasState>>();

  @Output() readonly updateViewport = new EventEmitter<IViewPort>();
  @Output() readonly syncLoadedPieces = new EventEmitter<string[]>();
  @Output() readonly syncFilmWidth = new EventEmitter<number>();

  @Input() config: RulerConfig;
  @Input() controlEvent: ControlEvent;
  @Input() loadPieces: Piece[];
  @Input() currentCanvas: string;
  @Input() currentZoom: number;
  @Input() filmWidth: number;

  private canvassizeDetected = false;
  private canvas?: fabric.Canvas;
  private readonly unsubscribeSbject: Subject<void>;
  private readonly availableZoom = AVAILABLE_ZOOM.map(zoom => zoom.value);

  isInsideCanvas: boolean;
  state = {
    lastAngleRotation: null,
  };
  constructor(
    private readonly ngZone: NgZone,
    private el: ElementRef,
  ) {
    this.unsubscribeSbject = new Subject<void>();
    // Changing rotation control properties
  }

  ngOnChanges(changes: SimpleChanges) {
    if (isNil(this.canvas)) {
      this.initCanvasAndRegisterEvents();
    }

    this.canvas.on('object:rotating', e => {
      const angle = this.treatAngle(e.target.angle);
      if (this.state.lastAngleRotation !== angle) {
        this.canvas.setCursor(this.mouseRotateIcon(angle));
        this.state.lastAngleRotation = angle;
      }
    });

    const config: RulerConfig = changes.config?.currentValue;
    if (config && !this.canvassizeDetected) {
      this.canvas.requestRenderAll();
      this.canvassizeDetected = true;
    }

    const currentCanvas: string = changes.currentCanvas?.currentValue;
    if (!isEmpty(currentCanvas) && JSON.stringify(this.canvas.toDatalessJSON(['id', 'label'])) != currentCanvas) {
      this.canvas?.loadFromJSON(currentCanvas, null);
    }

    const currentZoom: number = changes.currentZoom?.currentValue;
    if (!isNil(currentZoom)) {
      this.canvas.setZoom(currentZoom);
      this.fixIndicatorOnZoom();
    }

    const currentFilmWidth: number = changes.filmWidth?.currentValue;
    if (!isNil(currentFilmWidth)) {
      this.moveIndicator();
    }

    const loadedPiecesChange: SimpleChange = changes.loadPieces;

    if (loadedPiecesChange) {
      if (!isEmpty(loadedPiecesChange.currentValue)) {
        const newLoadedPieces: Piece[] = loadedPiecesChange.currentValue;
        const objectToUnload = this.getCanvasObject().filter(object => !newLoadedPieces.map(piece => piece.label).includes(object.label));

        const peicesToAddToCanvas = newLoadedPieces.filter(
          piece =>
            !this.getCanvasObject()
              .map(object => object.label)
              .includes(piece.label),
        );
        // remove the indicator before any logic
        remove(objectToUnload, object => isRuler(object));
        objectToUnload.forEach((object: fabric.Object) => {
          this.canvas.remove(object);
        });

        const url = `${appConfig.apiHost}/uploads/printable-data/`;

        forkJoin(
          peicesToAddToCanvas.map(piece => {
            return from(fabric.FabricImage.fromURL(url + piece.filepath)).pipe(
              map(image => {
                const fabricImg: fabricWithId = image.set({ left: piece.x, top: piece.y });
                fabricImg.scaleToWidth(piece.width);
                fabricImg.scaleToHeight(piece.height);
                fabricImg.label = piece.label;
                fabricImg.pieceWidth = piece.width;
                fabricImg.pieceHeight = piece.height;
                this.disableExtraControls(fabricImg);
                this.rotateObject(fabricImg);

                return fabricImg;
              }),
            );
          }),
        ).subscribe(fabricImages => {
          this.canvas.add(...fabricImages);
          this.fitCanvasToObjects();
          this.emitUpdatedCanvasState();
        });
      } else {
        this.canvas.discardActiveObject();
        this.canvas.clear();
        this.initializeIndicator();

        this.canvas.renderAndReset();
      }
    }

    const controlEvent: ControlEvent = changes.controlEvent?.currentValue;
    if (!isNil(controlEvent)) {
      switch (controlEvent.action) {
        case ControlEventAction.MoveX:
          this.moveX(controlEvent.param, controlEvent.absolute);
          break;
        case ControlEventAction.MoveY:
          this.moveY(controlEvent.param, controlEvent.absolute);
          break;
        case ControlEventAction.Rotate:
          this.rotate(controlEvent.param, controlEvent.absolute);
          break;
        case ControlEventAction.Scale:
          this.scale(controlEvent.param);
          break;
        case ControlEventAction.Viewport:
          this.setViewport(controlEvent.param);
          break;
        case ControlEventAction.Select:
          this.select(controlEvent.param);
          break;
        case ControlEventAction.Remove:
          this.remove();
          break;
        case ControlEventAction.Organize:
          this.organize();
          break;
        default:
          break;
      }
    }
  }

  private fitCanvasToObjects() {
    const objects = cloneDeep(this.getCanvasObject());
    if (objects.length === 0) return;

    const group = new fabric.Group(objects);
    const groupWidth = group.width + group.left;
    const groupHeight = group.height + group.top;
    const canvasWidth = this.canvas.getWidth();
    const canvasHeight = this.canvas.getHeight();

    const scaleX = canvasWidth / groupWidth;
    const scaleY = canvasHeight / groupHeight;
    const scale = parseFloat(Math.min(scaleX, scaleY).toFixed(3));
    const closestZoomIndex = this.availableZoom.reduce(
      (prevIndex, curr, index, array) => (Math.abs(curr - scale) < Math.abs(array[prevIndex] - scale) ? index : prevIndex),
      0,
    );

    const selectedZoom = this.availableZoom[closestZoomIndex];
    const closestZoom = selectedZoom < scale ? selectedZoom : this.availableZoom[closestZoomIndex - 1];

    this.canvas.setZoom(closestZoom);
    this.canvas.setViewportTransform([closestZoom, 0, 0, closestZoom, 0, 0]);
    this.fixIndicatorOnZoom();
    this.canvas.renderAll();
  }

  private rotateObject(object: fabric.Object) {
    object.controls.mtr = new fabric.Control({
      x: 0,
      y: -0.5,
      offsetX: 0,
      offsetY: -40,
      cursorStyleHandler: this.rotationStyleHandler,
      actionHandler: fabric.controlsUtils.rotationWithSnapping,
      actionName: 'rotate',
      render: this.renderIcon,
      withConnection: true,
    });
  }

  private disableExtraControls(object: fabric.Object) {
    object.setControlsVisibility({ bl: false, br: false, tl: false, tr: false, mb: false, mr: false, ml: false, mt: false });
  }

  private initCanvasAndRegisterEvents(): void {
    this.canvas = new fabric.Canvas('fabric-surface', {
      backgroundColor: '#ebebef',
      selection: true,
      preserveObjectStacking: true,
      renderOnAddRemove: true,
      width: (<HTMLElement>this.el.nativeElement).offsetWidth,
      height: (<HTMLElement>this.el.nativeElement).offsetHeight,
    });

    // registering event emitters
    this.registerUpdateViewportEvent('after:render');
    this.registerEventEmitForState('object:modified');
    this.registerEventEmitForSelection('selection:created');
    this.registerEventEmitForSelection('selection:updated');
    this.registerEventEmitForSelection('selection:cleared');
    this.registerObjectMoving('object:moving');
    this.registerZoomBinding('mouse:wheel');
    this.initializeIndicator();
  }

  initializeIndicator() {
    if (!this.getCanvasObject().some(object => isRuler(object))) {
      const line: fabricLine = new fabric.Line([0, 0, 100000, 0], {
        top: -10,
        selectable: true,
        backgroundColor: 'red',
        strokeWidth: 30,
        lockMovementX: true,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
        excludeFromExport: true,
      });
      line.label = 'indicator';

      const triangle: fabricTriangle = new fabric.Triangle({
        selectable: true,
        fill: 'red',
        lockMovementX: true,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
        excludeFromExport: true,
        originX: 'center',
        originY: 'center',
        angle: -90,
        width: 300,
        height: 200,
      });
      triangle.label = 'indicator';
      const indicator: any = new fabric.Group([line, triangle], {
        selectable: true,

        lockMovementX: true,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
        excludeFromExport: true,
      });
      indicator.label = 'indicator';
      indicator.left = 0;
      this.disableExtraControls(indicator);
      this.canvas.add(indicator);
      this.moveIndicator();
    }
  }

  private moveIndicator() {
    const indicatorObjects = this.getRulerOrObjects(this.canvas.getObjects()).ruler;
    indicatorObjects.forEach(indicatorObject => {
      indicatorObject.top = linearMeasureToVictorial(this.filmWidth);
      if (indicatorObject.isType('group')) {
        this.adjustLabel(indicatorObject);
      }
      indicatorObject.setCoords();
    });

    this.canvas.renderAll();
  }

  private select(labels: string[]) {
    this.canvas.discardActiveObject();

    if (isEmpty(labels)) {
      this.emitUpdatedCanvasState(undefined, true);

      return;
    }

    const objects = this.getCanvasObject().filter(object => labels.includes(object.label));
    // Important always add the canvas option so to not end up with undefined reference on canvas causing
    // the canvas to stop working and hard to find the reason why(eg; Cannot read property 'getRetinaScaling' of undefined ).
    const selection = new fabric.ActiveSelection(objects, { canvas: this.canvas });
    this.canvas.setActiveObject(selection);
    this.canvas.renderAll();
    this.emitUpdatedCanvasState(undefined, true);
  }
  updateInsideCanvas(isInsideCanvas: boolean) {
    this.isInsideCanvas = isInsideCanvas;
  }

  private registerScrollKeysBinding(event: KeyboardEvent) {
    if (!this.isInsideCanvas) {
      return;
    }

    const canvasViewPortTransform = this.canvas.viewportTransform;
    const { maxX, maxY, minX, minY } = this.getViewport();
    const width = maxX - minX;
    const height = maxY - minY;
    if (event.altKey) {
      switch (event.which) {
        case 37: // left
          if (canvasViewPortTransform[4] === 0) {
            break;
          }
          canvasViewPortTransform[4] = canvasViewPortTransform[4] + Math.floor((width * this.currentZoom) / 10);
          break;

        case 38: // up
          if (canvasViewPortTransform[5] === 0) {
            break;
          }
          canvasViewPortTransform[5] = canvasViewPortTransform[5] + Math.floor((height * this.currentZoom) / 10);
          break;

        case 39: // right
          // make sure it stays positive

          canvasViewPortTransform[4] = canvasViewPortTransform[4] - Math.floor((width * this.currentZoom) / 10);
          break;

        case 40: // down
          if (canvasViewPortTransform[5] <= -600) {
            break;
          }
          canvasViewPortTransform[5] = canvasViewPortTransform[5] - Math.floor((height * this.currentZoom) / 10);

          break;

        default:
          return; // exit this handler for other keys
      }

      this.canvas.setViewportTransform(canvasViewPortTransform);
      event.preventDefault();
    } else {
      const selectedObject = this.getSelection();

      if (!selectedObject || isRuler(selectedObject)) {
        return;
      }
      switch (event.which) {
        case 37: // left
          this.moveX(-100, false);
          break;

        case 38: // up
          this.moveY(-100, false);
          break;

        case 39: // right
          // make sure it stays positive
          this.moveX(100, false);

          break;

        case 40: // down
          this.moveY(100, false);
          break;

        case 46: // Delete
          this.remove();

          break;
        default:
          return; // exit this handler for other keys
      }
      event.preventDefault();
    }
  }

  private setViewport(param: IViewPort) {
    const p = new fabric.Point(param.minX || 0, param.minY || 0);
    this.canvas?.absolutePan(p);
    this.canvas?.requestRenderAll();
  }

  private registerObjectMoving(eventName: 'object:moving') {
    fromEvent(this.canvas, eventName).subscribe((event: any) => {
      this.forceToWorkingArea(event.target);
      event.target.setCoords();
      if (isRuler(event.target)) {
        this.adjustLabel(event.target);
        this.syncFilmWidth.emit(Math.floor(victorialToLinearMeasure(event.target.top)));
      }
    });
  }

  adjustLabel(target: fabricWithId) {
    const y = target?.top;
    const unzoomRatio = 1 / Math.floor(this.canvas.getZoom() / 0.025);

    const label = new fabric.Text(Math.floor(victorialToLinearMeasure(y)).toString(), {
      top: y - 800 * unzoomRatio,
      fontSize: 800 * unzoomRatio,
      fill: 'red',
      excludeFromExport: true,
      selectable: false,
    });
    this.canvas.remove(this.canvas.getObjects().find(object => object.type == 'text'));
    this.canvas.add(label);
  }

  private forceToWorkingArea(obj: fabricWithId) {
    const { top, left } = obj.getBoundingRect();
    if (top < 0) {
      obj.top += -top;
      obj.setCoords();
    }
    if (left < 0) {
      obj.left += -left;
      obj.setCoords();
    }
  }

  private registerZoomBinding(event: string) {
    fromEvent(this.canvas, event)
      .pipe(
        map((wheelEvent: fabric.TEvent<WheelEvent>) => {
          const currentZoomIndex = this.availableZoom.indexOf(this.currentZoom);
          let zoom = this.currentZoom;

          if (wheelEvent.e.deltaY < 0 && currentZoomIndex + 1 !== this.availableZoom.length) {
            zoom = this.availableZoom[currentZoomIndex + 1];
          }

          if (wheelEvent.e.deltaY > 0 && currentZoomIndex !== 0) {
            zoom = this.availableZoom[currentZoomIndex - 1];
          }

          const x = wheelEvent.e.offsetX;
          const y = wheelEvent.e.offsetY;

          return { zoom, x, y };
        }),
        filter(event => !isEqual(event.zoom, this.canvas.getZoom())),
      )
      .subscribe(event => {
        const point = new fabric.Point();
        point.x = event.x;
        point.y = event.y;
        this.canvas.zoomToPoint(point, event.zoom);
        this.fixIndicatorOnZoom();

        this.emitUpdatedCanvasState({
          zoom: this.canvas.getZoom(),
        });
      });
  }

  private registerEventEmitForState(event: string) {
    fromEvent(this.canvas, event).subscribe((e: any) => {
      this.forceToWorkingArea(e.target);
      this.emitUpdatedCanvasState();
    });
  }

  private registerEventEmitForSelection(event: string) {
    fromEvent(this.canvas, event)
      .pipe(
        takeUntil(this.unsubscribeSbject),
        throttleTime(1000),
        filter((canvasEvent: fabric.TPointerEventInfo) => !isEmpty(canvasEvent.e)),
      )
      .subscribe({
        next: () => {
          this.fixActiveObjectsOrRuler();
          // update the canvas state when a piece is selected
          this.emitUpdatedCanvasState();
        },
      });
  }
  fixActiveObjectsOrRuler() {
    // fixes the selection to take either ruler or objects
    const { ruler, obj } = this.getRulerOrObjects(this.getActiveCanvasObject());
    if (obj.length && ruler.length) {
      return this.createNewSelection(obj);
    }
  }
  createNewSelection(objects: fabricWithId[]) {
    this.canvas.discardActiveObject();
    const selection = new fabric.ActiveSelection(objects, {
      canvas: this.canvas,
    });
    this.canvas.setActiveObject(selection);
    this.disableExtraControls(selection);
    this.canvas.requestRenderAll();
  }

  private registerUpdateViewportEvent(event: string) {
    fromEvent(this.canvas, event)
      .pipe(
        map(() => this.getFixedViewport(this.getViewport())),
        distinctUntilChanged((oldViewport, newViewport) => isEqual(oldViewport, newViewport)),
      )
      .subscribe(() => {
        this.updateViewport.emit(this.getViewport());
      });
  }

  private moveX(left: number, absolute = true) {
    const selection = this.getSelection();

    selection.left = absolute ? left : selection.left + left;
    selection.setCoords();
    this.emitUpdatedCanvasState();
  }

  private moveY(top: number, absolute = true) {
    const selection = this.getSelection();
    selection.top = absolute ? top : selection.top + top;
    selection.setCoords();
    this.emitUpdatedCanvasState();
  }

  private rotate(angle: number, absolute = true) {
    const selection = this.getSelection();
    selection.rotate(absolute ? angle % 360 : (selection.angle + angle) % 360);
    selection.setCoords();
    this.forceToWorkingArea(selection);
    this.emitUpdatedCanvasState(undefined, true);
  }

  private scale(scale: number) {
    const selection = this.getSelection();
    scale = scale / 100;
    if (selection.scaleX !== scale) {
      selection.scaleX = scale;
      selection.scaleY = scale;
      selection.setCoords();
      this.emitUpdatedCanvasState();
    }
  }

  private remove() {
    this.canvas?.getActiveObjects().forEach(selection => {
      this.canvas.remove(selection);
    });
    this.canvas.discardActiveObject();

    this.syncLoadedPieces.emit(this.getCanvasObject().map(object => object.label));
    this.emitUpdatedCanvasState(undefined, true);
  }

  private organize() {
    this.canvas.discardActiveObject();
    const objects = this.getCanvasObject();
    let left = 0;
    objects.forEach(object => {
      object.top = 0;
      object.angle = 0;
      object.left = left;
      // 1 was too close pieces would intersect 5 is ok for now
      // to optimize you could use the new detect intersections method and adjust
      //this.forceToWorkingArea(object);
      left += Number((object as any).pieceWidth) + 5;

      object.setCoords();
    });
    this.emitUpdatedCanvasState();
  }

  private emitUpdatedCanvasState(
    partialCanvasState: Partial<CurrentCanvasState> = {
      stateAsString: this.getState(),
      currentSelection: this.parseSelection(),
      currentSvg: this.getSvg(),
      zoom: this.canvas.getZoom(),
    },
    waitToBeStable = false,
  ) {
    this.canvas.requestRenderAll();
    waitToBeStable
      ? this.ngZone.onStable.pipe(take(1)).subscribe(() => {
          this.ngZone.run(() => {
            this.updateCurrentCanvasState.emit(partialCanvasState);
          });
        })
      : this.updateCurrentCanvasState.emit(partialCanvasState);
  }

  private getFixedViewport(viewport: IViewPort): IViewPort {
    if (viewport.minX < 0 || viewport.minY < 0) {
      if (viewport.minX < 0) {
        this.canvas.viewportTransform[4] = 0;
      }
      if (viewport.minY < 0) {
        this.canvas.viewportTransform[5] = 0;
      }
      this.canvas.setViewportTransform(this.canvas.viewportTransform);
      this.canvas.requestRenderAll();

      return this.getViewport();
    }

    return viewport;
  }

  private getViewport(): IViewPort {
    const vptCoords = this.canvas?.vptCoords;

    return {
      minX: vptCoords?.tl.x,
      minY: vptCoords?.tl.y,
      maxX: vptCoords?.tr.x,
      maxY: vptCoords?.bl.y,
    };
  }

  private fixIndicatorOnZoom() {
    const unzoomRatio = 1 / Math.floor(this.canvas.getZoom() / 0.025);
    const indicatorObjects = this.getRulerOrObjects(this.canvas.getObjects()).ruler;
    indicatorObjects.forEach(indicatorObject => {
      indicatorObject.scaleY = unzoomRatio;
      indicatorObject.setCoords();
      if (indicatorObject.isType('group')) {
        this.adjustLabel(indicatorObject);
      }
    });

    this.canvas.renderAll();
  }

  private parseSelection(): ISelection {
    const activeSelection = this.canvas.getActiveObject() as fabricWithId;
    if (isNil(activeSelection) || isRuler(activeSelection)) {
      return;
    }

    const { left: x, top: y, scaleX: scale, angle: rotation, width, height, label } = activeSelection;
    const parsedSelection = {
      x,
      y,
      scale: scale * 100,
      rotation,
      width,
      height,
    };

    return isEmpty(activeSelection._objects)
      ? {
          ...parsedSelection,
          labels: [label],
        }
      : {
          ...parsedSelection,
          labels: activeSelection._objects.map(object => object.label),
        };
  }

  getSvg(): string {
    return this.canvas?.toSVG() || '';
  }

  getState() {
    // *** important ***  make sure to add any extra custom property in the function call array
    // or it gets discarded
    return JSON.stringify(this.canvas?.toDatalessJSON(['id', 'label']));
  }

  getSelection(): fabricWithId {
    return this.canvas?.getActiveObject() as fabricWithId;
  }
  getCanvasObject(): fabricWithId[] {
    return (this.canvas.getObjects() as fabricWithId[]).filter(obj => !isRuler(obj));
  }
  getActiveCanvasObject(): fabricWithId[] {
    return this.canvas.getActiveObjects() as fabricWithId[];
  }
  getRulerOrObjects(objects: fabricWithId[]) {
    const [ruler, obj] = partition(objects, obj => isRuler(obj));

    return { ruler, obj };
  }

  renderIcon(ctx: CanvasRenderingContext2D, left: any, top: any, styleOverride: any, fabricObject: fabric.Object) {
    const size = 32;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle ?? 0));
    ctx.drawImage(rotateIcon, -size / 2, -size / 2, size, size);
    ctx.restore();
  }

  mouseRotateIcon(angle: number) {
    const relativeAngle = angle - 90;
    const defaultPos = '7.25 10';

    const transform = relativeAngle === 0 ? 'translate(9.5 3.5)' : `rotate(${relativeAngle} ${posCursorMap[relativeAngle] || defaultPos})`;

    const imgCursor = encodeURIComponent(`
    <svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24'>
      <defs>
        <filter id='a' width='266.7%' height='156.2%' x='-75%' y='-21.9%' filterUnits='objectBoundingBox'>
          <feOffset dy='1' in='SourceAlpha' result='shadowOffsetOuter1'/>
          <feGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='1'/>
          <feColorMatrix in='shadowBlurOuter1' result='shadowMatrixOuter1' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/>
          <feMerge>
            <feMergeNode in='shadowMatrixOuter1'/>
            <feMergeNode in='SourceGraphic'/>
          </feMerge>
        </filter>
        <path id='b' d='M1.67 12.67a7.7 7.7 0 0 0 0-9.34L0 5V0h5L3.24 1.76a9.9 9.9 0 0 1 0 12.48L5 16H0v-5l1.67 1.67z'/>
      </defs>
      <g fill='none' fill-rule='evenodd'><path d='M0 24V0h24v24z'/>
        <g fill-rule='nonzero' filter='url(#a)' transform='${transform}'>
          <use fill='#000' fill-rule='evenodd' xlink:href='#b'/>
          <path stroke='#FFF' d='M1.6 11.9a7.21 7.21 0 0 0 0-7.8L-.5 6.2V-.5h6.7L3.9 1.8a10.4 10.4 0 0 1 0 12.4l2.3 2.3H-.5V9.8l2.1 2.1z'/>
        </g>
      </g>
    </svg>`);

    return `url("data:image/svg+xml;charset=utf-8,${imgCursor}") 12 12, crosshair`;
  }

  treatAngle(angle: number) {
    return angle - (angle % 15);
  }

  rotationStyleHandler = (eventData: Event, control: fabric.Control, fabricObject: fabric.Object) => {
    if (fabricObject.lockRotation) {
      throw new Error('NOT_ALLOWED_CURSOR');
    }
    const angle = this.treatAngle(fabricObject.angle);
    this.state.lastAngleRotation = angle;

    return this.mouseRotateIcon(angle);
  };

  ngOnDestroy() {
    this.unsubscribeSbject.next();
    this.unsubscribeSbject.complete();
  }
}
