import { createReducer, on, Action } from '@ngrx/store';

import { User, UserConfiguration, Credentials, defaultUserConfiguration, SessionBanner } from '../../models/user.model';

import * as SessionActions from '../actions/session.actions';
import * as UserActions from '../actions/user.actions';
import * as PritableDataActions from '../actions/printable-datal.actions';
import * as PrintingActions from '../actions/printing.actions';
import { Piece, PrintableDataSession } from 'app/models/printable-data.model';
import { cloneDeep, differenceBy } from 'lodash';
import { AVAILABLE_ZOOM } from 'app/constants/available-zoom.const';
import { PrinterDetails } from 'app/models/printing.model';

export enum SessionLoading {
  login = 'LOGIN',
  logout = 'LOGOUT',
  updateUser = 'UPDATE_USER',
  printing = 'PRINTING',
}
export interface SessionState {
  readonly isAppInitialized: boolean;
  readonly isUserAuthenticated: boolean;
  readonly isDaemonUp: boolean;
  readonly user?: User;
  readonly userConfiguration?: UserConfiguration;
  readonly credentials?: Credentials;
  readonly loading: Array<SessionLoading>;
  readonly printableDataSession: PrintableDataSession;
  readonly banner?: SessionBanner;
  readonly isTabletMode?: boolean;
  readonly isSidenavOpen?: boolean;
  readonly printers?: PrinterDetails[];
}

export const sessionKey = 'session';

export const initialState: SessionState = {
  isAppInitialized: false,
  isUserAuthenticated: false,
  isDaemonUp: false,
  loading: [],
  userConfiguration: defaultUserConfiguration,
  printableDataSession: {
    initialPieces: [],
    loadedPieces: [],
    currentCanvasState: {
      zoom: AVAILABLE_ZOOM[2].value,
    },
  },
  banner: {
    picture: 'assets/images/banner-magic.jpg',
  },
  isTabletMode: false,
  isSidenavOpen: true,
  printers: [],
};

export const sessionReducer = createReducer(
  initialState,
  on(SessionActions.finishedAppInitialization, (state): SessionState => ({ ...state, isAppInitialized: true })),
  on(SessionActions.isUserAuthenticated, (state): SessionState => ({ ...state, isUserAuthenticated: true })),
  on(SessionActions.isDaemonUp, (state, { isDaemonUp }): SessionState => ({ ...state, isDaemonUp })),

  on(SessionActions.login, (state): SessionState => ({ ...state, loading: [...state.loading, SessionLoading.login] })),
  on(SessionActions.loginCompleted, SessionActions.loginError, state => ({
    ...state,
    loading: state.loading.filter(loading => loading !== SessionLoading.login),
  })),
  on(SessionActions.setCredentials, SessionActions.loginCompleted, (state, { credentials }): SessionState => ({ ...state, credentials })),
  on(SessionActions.setBanner, (state, { banner }): SessionState => ({ ...state, banner })),

  on(SessionActions.logout, (state): SessionState => ({ ...state, loading: [...state.loading, SessionLoading.logout] })),
  on(
    SessionActions.resetState,
    (state): SessionState => ({
      ...initialState,
      userConfiguration: state.userConfiguration,
    }),
  ),
  on(
    SessionActions.logoutError,
    SessionActions.logoutCompleted,
    (state): SessionState => ({
      ...state,
      loading: state.loading.filter(loading => loading !== SessionLoading.logout),
    }),
  ),

  on(UserActions.updateUser, UserActions.updateUserPassword, (state): SessionState => ({ ...state, loading: [...state.loading, SessionLoading.updateUser] })),
  on(UserActions.updateUserError, UserActions.updateUserCompleted, UserActions.updateUserPasswordCompleted, UserActions.updateUserPasswordError, state => ({
    ...state,
    loading: state.loading.filter(loading => loading !== SessionLoading.updateUser),
  })),
  on(
    UserActions.getUserCompleted,
    UserActions.updateUserCompleted,
    UserActions.updateUserPasswordCompleted,
    (state, { user }): SessionState => ({
      ...state,
      user,
    }),
  ),

  on(
    UserActions.setUserConfiguration,
    (state, { userConfiguration }): SessionState => ({
      ...state,
      userConfiguration: {
        ...state.userConfiguration,
        ...userConfiguration,
      },
    }),
  ),
  on(
    UserActions.updateUserConfiguration,
    (state, { partialUserConfiguration }): SessionState => ({
      ...state,
      userConfiguration: {
        ...state.userConfiguration,
        ...partialUserConfiguration,
      },
    }),
  ),
  on(
    PrintingActions.updatePrintingMode,
    (state, { printingMode }): SessionState => ({
      ...state,
      userConfiguration: {
        ...state.userConfiguration,
        printingMode,
      },
    }),
  ),
  on(
    PritableDataActions.setSelectedPrintableData,
    (state, { selectedPrintableDataId }): SessionState => ({
      ...state,
      printableDataSession: {
        rulerConfig: state.printableDataSession.rulerConfig,
        selectedPrintableDataId,
        initialPieces: [],
        loadedPieces: [],
        currentCanvasState: {
          stateAsString: '',
          zoom: state.printableDataSession.currentCanvasState.zoom,
        },
      },
    }),
  ),

  on(
    PritableDataActions.setInitialPieces,
    (state, { initialPieces }): SessionState => ({
      ...state,
      printableDataSession: {
        ...state.printableDataSession,
        initialPieces: cloneDeep(initialPieces),
        loadedPieces: [],
      },
    }),
  ),
  on(
    PritableDataActions.loadPieces,
    (state, { piecesToLoad }): SessionState => ({
      ...state,
      printableDataSession: {
        ...state.printableDataSession,
        loadedPieces: cloneDeep([...state.printableDataSession.loadedPieces, ...piecesToLoad]),
      },
    }),
  ),
  on(
    PritableDataActions.loadPiecesFromPreview,
    (state, { piecesToLoad }): SessionState => ({
      ...state,
      printableDataSession: {
        ...state.printableDataSession,
        loadedPieces: cloneDeep(piecesToLoad),
      },
    }),
  ),
  on(
    PritableDataActions.syncPiecesFromCanvas,
    (state, { piecesToSync }): SessionState => ({
      ...state,
      printableDataSession: {
        ...state.printableDataSession,
        loadedPieces: state.printableDataSession.initialPieces.filter(piece => piecesToSync.includes(piece.label)),
      },
    }),
  ),
  on(
    PritableDataActions.stagePieces,
    (state, { piecesToStage }): SessionState => ({
      ...state,
      printableDataSession: {
        ...state.printableDataSession,
        loadedPieces: differenceBy(state.printableDataSession.loadedPieces, piecesToStage, (pieces: Piece) => pieces.label),
      },
    }),
  ),

  on(
    PritableDataActions.updateRulerConfig,
    (state, { rulerConfig }): SessionState => ({
      ...state,
      printableDataSession: {
        ...state.printableDataSession,
        rulerConfig,
      },
    }),
  ),
  on(
    PritableDataActions.updateCurrentControlEvent,
    (state, { controlEvent }): SessionState => ({
      ...state,
      printableDataSession: {
        ...state.printableDataSession,
        controlEvent,
      },
    }),
  ),
  on(
    PritableDataActions.updateCurrentCanvasState,
    (state, { currentCanvasState }): SessionState => ({
      ...state,
      printableDataSession: {
        ...state.printableDataSession,
        currentCanvasState: {
          ...state.printableDataSession.currentCanvasState,
          ...currentCanvasState,
        },
      },
    }),
  ),

  on(PrintingActions.startPrinting, (state): SessionState => ({ ...state, loading: [...state.loading, SessionLoading.printing] })),
  on(PrintingActions.printHpglCompleted, PrintingActions.printError, state => ({
    ...state,
    loading: [...state.loading.filter(loading => loading !== SessionLoading.printing)],
  })),
  on(PrintingActions.loadPrinters, (state, { printers }): SessionState => ({ ...state, printers })),
  on(SessionActions.setIsTabletMode, (state, { isTabletMode }): SessionState => ({ ...state, isTabletMode })),
  on(SessionActions.setIsSidenavOpen, (state, { isSidenavOpen }): SessionState => ({ ...state, isSidenavOpen })),
);

export function reducer(state: SessionState, action: Action): SessionState {
  return sessionReducer(state, action);
}
