import { HttpClient, HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { BrandQuery, EntitiesModel, ModelQuery, PrintableDataQuery, SerieQuery, SerieResponse } from 'app/models/entities.model';
import { PieceType, PrintableData } from 'app/models/printable-data.model';
import { groupBy } from 'lodash';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Brand } from '../models/brand.model';
import { Model } from '../models/model.model';
import { Serie } from '../models/serie.model';

@Injectable()
export class PrintableDataService {
  constructor(private _http: HttpClient) {}

  loadEntities(): Observable<EntitiesModel> {
    return forkJoin({
      brands: this.loadBrands(),
      series: this.loadSeries(),
      models: this.loadModels(),
      printableData: this.loadAllPrintableData(),
    }).pipe(
      map(entities => {
        const ppfPrintableData = entities.printableData.filter(printableData => printableData.type === PieceType.PPF);
        const vitrePrintableData = entities.printableData.filter(printableData => printableData.type === PieceType.WINDOW);

        const ppfModels = entities.models.filter(model => ppfPrintableData.map(printableData => printableData.modelId).includes(model.id));
        const vitreModels = entities.models.filter(model => vitrePrintableData.map(printableData => printableData.modelId).includes(model.id));

        const ppfSeries = entities.series.filter(serie => ppfModels.map(model => model.serieId).includes(serie.id));
        const vitreSeries = entities.series.filter(serie => vitreModels.map(model => model.serieId).includes(serie.id));

        const groupedBrands = {
          [PieceType.PPF]: entities.brands.filter(brand => ppfSeries.map(serie => serie.brandId).includes(brand.id)).map(brand => brand.id),
          [PieceType.WINDOW]: entities.brands.filter(brand => vitreSeries.map(serie => serie.brandId).includes(brand.id)).map(brand => brand.id),
          [PieceType.ALL]: entities.brands.map(brand => brand.id),
        };

        return {
          ...entities,
          groupedBrands,
          groupedSeries: {
            [PieceType.PPF]: this.groupEntityBy(ppfSeries, 'brandId'),
            [PieceType.WINDOW]: this.groupEntityBy(vitreSeries, 'brandId'),
            [PieceType.ALL]: this.groupEntityBy(entities.series, 'brandId'),
          },
          groupedModels: {
            [PieceType.PPF]: this.groupEntityBy(ppfModels, 'serieId'),
            [PieceType.WINDOW]: this.groupEntityBy(vitreModels, 'serieId'),
            [PieceType.ALL]: this.groupEntityBy(entities.models, 'serieId'),
          },
          groupedPrintableData: {
            [PieceType.PPF]: this.groupEntityBy(ppfPrintableData, 'modelId'),
            [PieceType.WINDOW]: this.groupEntityBy(vitrePrintableData, 'modelId'),
            [PieceType.ALL]: this.groupEntityBy(entities.printableData, 'modelId'),
          },
        };
      }),
    );
  }

  private groupEntityBy<T extends Serie | Model | PrintableData, Y extends keyof T>(entity: T[], id: Y): Dictionary<number[]> {
    const groupedEntity = groupBy(entity, id);
    const newGroupedEntity: Dictionary<number[]> = {};

    Object.keys(groupedEntity).forEach(key => {
      newGroupedEntity[key] = groupedEntity[key].map(entity => entity.id);
    });

    return newGroupedEntity;
  }

  loadBrands(): Observable<Brand[]> {
    const url = '/api/brand/list';

    return this._http.get<{ statusCode: number; data: { listing: Brand[]; total: number } }>(url).pipe(map(response => response.data.listing));
  }

  getBrandList(query?: BrandQuery): Observable<{ statusCode: number; data: { listing: Brand[]; total: number } }> {
    const routeParams = {
      brandName: 'brand-name',
      expand: 'expand',
      offset: 'offset',
      limit: 'limit', // needed
    };
    const url =
      '/api/brand/list?' +
      Object.keys(query)
        .map(k =>
          Array.isArray(query[k]) ? query[k].map((value, index) => `${routeParams[k]}[${index}]=${value}`).join('&') : `${routeParams[k]}=${query[k]}`,
        )
        .join('&');

    return this._http.get(url, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: { listing: Brand[]; total: number }; statusCode: number }>) => {
        return res.body;
      }),
    );
  }

  loadSeries(): Observable<Serie[]> {
    const url = '/api/serie/list';

    return this._http.get<{ statusCode: number; data: { listing: Serie[]; total: number } }>(url).pipe(map(response => response.data.listing));
  }

  getSerieList(query?: SerieQuery): Observable<SerieResponse> {
    const routeParams = {
      brandName: 'brand-name', // needed
      serieName: 'serie-name',
      expand: 'expand',
      offset: 'offset',
      limit: 'limit', // needed
      showParents: 'show-parents',
      brandIds: 'brand-ids',
      exactSearch: 'exact-search',
    };
    const url =
      '/api/serie/list?' +
      Object.keys(query)
        .map(k =>
          Array.isArray(query[k]) ? query[k].map((value, index) => `${routeParams[k]}[${index}]=${value}`).join('&') : `${routeParams[k]}=${query[k]}`,
        )
        .join('&');

    return this._http.get(url, { observe: 'response' }).pipe(
      map((res: HttpResponse<SerieResponse>) => {
        return res.body;
      }),
    );
  }

  loadModels(): Observable<Model[]> {
    const url = '/api/model/list';

    return this._http.get<{ statusCode: number; data: { listing: Model[]; total: number } }>(url).pipe(map(response => response.data.listing));
  }

  getModelList(query?: ModelQuery): Observable<{ statusCode: number; data: { listing: Model[]; total: number } }> {
    const route = {
      // verify also
      brandName: 'brand-name', // needed
      serieName: 'serie-name',
      modelName: 'model-name',
      expand: 'expand',
      offset: 'offset',
      limit: 'limit', // needed
      showParents: 'show-parents',
      serieIds: 'serie-ids',
      exclude: 'exclude', // needed
      ids: 'ids',
      exactSearch: 'exact-search',
    };
    const url =
      '/api/model/list?' +
      Object.keys(query)
        .map(k => (Array.isArray(query[k]) ? query[k].map((value, index) => `${route[k]}[${index}]=${value}`).join('&') : `${route[k]}=${query[k]}`))
        .join('&');

    return this._http.get(url, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: { listing: Model[]; total: number }; statusCode: number }>) => {
        return res.body;
      }),
    );
  }

  getModel(modelId: number): Observable<{ statusCode: number; data: Model }> {
    return this._http.get('/api/model/' + modelId, { observe: 'response' }).pipe(
      map((res: HttpResponse<{ data: Model; statusCode: number }>) => {
        return res.body;
      }),
    );
  }

  loadAllPrintableData(): Observable<PrintableData[]> {
    const url = '/api/printable-data/list';

    return this._http.get<{ statusCode: number; data: { listing: PrintableData[]; total: number } }>(url).pipe(map(response => response.data.listing));
  }

  loadPrintableData(id: number): Observable<PrintableData> {
    const url = `/api/printable-data/${id}`;

    return this._http.get<{ statusCode: number; data: PrintableData }>(url).pipe(map(response => response.data));
  }

  getPrintableDataList(query?: PrintableDataQuery): Observable<HttpEvent<any>> {
    const route = {
      brandName: 'brand-name',
      serieName: 'serie-name',
      modelName: 'model-name',
      expand: 'expand',
      offset: 'offset',
      limit: 'limit',
      showParents: 'show-parents',
      modelIds: 'model-ids',
      type: 'type',
      exactSearch: 'exact-search',
    };
    const url =
      '/api/printable-data/list?' +
      Object.keys(query)
        .map(k => (Array.isArray(query[k]) ? query[k].map((value, index) => `${route[k]}[${index}]=${value}`).join('&') : `${route[k]}=${query[k]}`))
        .join('&');

    const req = new HttpRequest('GET', url, {
      reportProgress: true,
    });

    return this._http.request(req);
  }
}
