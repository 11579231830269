import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { HandleErrorsService, PrintableDataService, PrintingService } from '../app-services';
import { Model } from '../models/model.model';
import { PrintingList } from '../models/printing.model';

@Component({
  selector: 'printing-log',
  templateUrl: './printing-log.component.html',
  styleUrls: ['./printing-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintingLogComponent implements OnInit {
  printingList$: Observable<PrintingList>;
  models = new ReplaySubject<Model[]>(1);

  constructor(protected printingService: PrintingService, protected printableDataService: PrintableDataService, protected handleErrors: HandleErrorsService) {}

  ngOnInit() {
    this.printingList$ = this.printingService.getPrintingList();
  }

  fetchModelList(event: { modelName: string; exclude: number[] }) {
    const query = {};
    Object.keys(event)
      .filter(m => event[m])
      .map(k => (query[k] = event[k]));
    this.printableDataService.getModelList(query).subscribe({
      next: result => this.models.next(result.data.listing),
      error: err => this.handleErrors.handleErrors(err),
    });
  }

  filterPrintings(form: { startDate: Date; endDate: Date; modelIds: string }) {
    const query = {};
    Object.keys(form)
      .filter(f => form[f])
      .map(k => {
        query[k] = k === 'modelIds' ? form[k].split(',') : form[k];
      });

    this.printingList$ = this.printingService.getPrintingList(query);
  }
}
